import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

// import errorimg from "../../assets/icons/error.svg";
import "./error.css";

class ErrorPage extends Component {
  render() {
    return (
      <div className="error-container">
        {/* <img src={errorimg} alt="" /> */}
        <h2 className="error-message">
          {this.props.messageError
            ? this.props.messageError
            : "Ups! el recurso solicitado no existe."}
        </h2>
        <NavLink className="error-button" exact to={"/main"}>
          Ir a la pantalla inicial
        </NavLink>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    messageError: state.general.messageError,
  };
};

export default connect(mapStateToProps)(ErrorPage);
