import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setTooltips,
  setModalOpen,
} from "../../../store/actions/generalActions";
import {
  logOutUser,
  getUserName,
} from "../../../store/actions/registerActions";

import Contacto from "../../contacto/contacto";
import Modal from "../../../ui/modal/modal";

import Option from "../../../ui/option-list/option";
import Tooltips from "../../../ui/tooltips/tooltips";

import "./aside.css";
import sprite from "../../../assets/icons/iconsprite.svg";
import clubes from "../../../assets/images/clubes.png";
import partners from "../../../assets/images/partners.png";
import ligas from "../../../assets/images/ligas.png";

import usuarios from "../../../assets/images/usuarios.png";

import decisiones from "../../../assets/images/decisiones.png";
import avatars from "../../../assets/images/avatars.png";

import { getRole } from "src/services/utils";
import bigManager from "../../../assets/images/bigManager.png";

// TODO: Next sprint will use isAdmin:
// const isAdmin = "CLUBADMIN";
// const isSuperAdmin = "ADMIN";
// const userRol = getRole();
let menu = [
  {
    name: "Usuarios",
    href: "/main/users",
    icon: usuarios,
  },
  {
    name: "Clubes",
    href: "/main/clubs",
    icon: clubes,
  },
  {
    name: "Avatars",
    href: "/main/avatars",
    icon: avatars,
  },
  {
    name: "Decisiones",
    href: "/main/decisions",
    icon: decisiones,
  },
  {
    name: "Partners",
    href: "/main/partners",
    icon: `${partners}#gallery`,
  },
  {
    name: "Experiencias",
    href: "/main/experiences",
    icon: decisiones,
  },
  {
    name: "Comunidad",
    href: "/main/community",
    icon: `${partners}#gallery`,
  },
  {
    name: "Proyectos",
    href: "/main/projects",
    icon: clubes,
  },
  {
    name: "Ligas",
    href: "/main/leagues",
    icon: `${ligas}#gallery`,
  },
  {
    name: "Notificaciones",
    href: "/main/notifications",
    icon: usuarios,
  },
  {
    name: "Sorteos",
    href: "/main/raffles",
    icon: usuarios,
  },
  {
    name: "Pagos",
    href: "/main/payments",
    icon: usuarios,
  },
];

const ContentAside = (props) => {
  const history = useHistory();

  const [tTClose, setTTClose] = useState(false);
  useEffect(() => {
    if (props.responsive) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  const closeTooltips = () => {
    setTimeout(() => {
      props.setTooltips(false);
      setTTClose(false);
    }, 400);
    setTTClose(true);
  };

  let modal = props.modalOpen ? (
    <Modal closeModal={props.setModalOpen} key="modal">
      <Contacto />
    </Modal>
  ) : null;

  let closeSession = props.tooltipsOpen ? (
    <Tooltips direction={tTClose ? "closeTop" : "top"} type="tooltips-simple">
      <div className="close-sesion-container">
        <div>
          <p>¿Estás seguro que quieres cerrar sesión?</p>
        </div>
        <div className="close-sesion-actions">
          <span
            onClick={() => {
              props.logOutUser(history);
              props.setTooltips(false);
            }}
          >
            Sí
          </span>

          <span onClick={() => closeTooltips()}>No</span>
        </div>
      </div>
    </Tooltips>
  ) : null;

  return (
    <React.Fragment>
      <aside className={props.classes}>
        <div className="aside-session-container">
          <img src={bigManager} alt="Punto de venta" />
          <div className="aside-session-container-userdata">
            <h3 style={{ color: "white" }}>Big Manager</h3>
            {/* <small className="name">{props.name}</small> */}
            {/* <small className="name">admin account</small> */}
          </div>
        </div>

        <div className="options-container">
          {menu?.map((option, value) => (
            <Option
              key={value}
              one={true}
              name={option.name}
              href={option.href}
              icon={option.icon}
            />
          ))}
        </div>

        <div className="foot-aside-container">
          <label onClick={() => props.setTooltips(true)}>
            <svg className="">
              <use href={sprite + "#cerrarsesion"}></use>
            </svg>
            Cerrar sesión
          </label>
          {closeSession}
          {modal}
        </div>
      </aside>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    name: state.register.name,
    tooltipsOpen: state.general.tooltipsOpen,
    modalOpen: state.general.modalOpen,
  };
};

export default connect(mapStateToProps, {
  setTooltips,
  setModalOpen,
  logOutUser,
  getUserName,
})(ContentAside);
