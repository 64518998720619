import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import {
  createExperience,
  editExperience,
  getImages,
} from "src/services/ExperiencesService";
import ModalError from "src/ui/modalError/modalError";
import { setTitle } from "../../../store/actions/generalActions";
import "./index.css";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];

const CreateProject = (props) => {
  const experience = props?.location?.state?.experience;
  const fromEdit = !!experience;
  console.log(experience);
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState(
    fromEdit ? experience.club.league.uuid : null
  );
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState(
    fromEdit ? experience.club.uuid : null
  );
  const [title, setTitle] = useState(fromEdit ? experience.title : "");
  const [description, setDescription] = useState(
    fromEdit ? experience.description : ""
  );
  const [cost, setCost] = useState(fromEdit ? experience.cost : "");
  const [amount, setAmount] = useState(fromEdit ? experience.amount : "");
  const [startDate, setStartDate] = useState(
    fromEdit ? new Date(experience.startDate) : new Date()
  );
  const [finishDate, setFinishDate] = useState(
    fromEdit ? new Date(experience.finishDate) : new Date()
  );
  const [deliveryDate, setDeliveryDate] = useState(
    fromEdit ? new Date(experience.deliveryDate) : new Date()
  );
  const [selectedImages, setSelectedImages] = useState([]);
  const [forDeleteImages, setForDeleteImages] = useState([]);
  const [fileBanner, setFileBanner] = useState(
    fromEdit ? { file: null, imageURL: experience.image } : null
  );
  const [activeValue, setActiveValue] = useState(
    fromEdit ? experience.isActive : null
  );

  const fileInputRef = useRef();
  const alert = useAlert();
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} Experiencia`);
  }, []);

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  const getAllImages = async () => {
    if (experience && experience.uuid) {
      const images = await getImages(experience.uuid);
      const data = images.data.map((item) => ({
        file: null,
        uuid: item.uuid ? item.uuid : null,
        imageURL: item.image,
      }));
      setSelectedImages(data);
    }
  };

  useEffect(() => {
    setSubmitted(false);
    getAllImages();
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const onChangeGoal = (e) => {
    setDirty();
    if (e.target.value > 99999) return false;
    setCost(e.target.value);
  };

  const onChangeAmount = (e) => {
    setDirty();
    if (e.target.value > 99999) return false;
    setAmount(e.target.value);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (index, e) => {
    setDirty();
    e.preventDefault();

    let image = selectedImages[index];

    if (image.uuid) setForDeleteImages([...forDeleteImages, image.uuid]);

    selectedImages.splice(index, 1);

    setSelectedImages([...selectedImages]);
  };

  const fileInputClicked = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedImages([...selectedImages, image]);
    }
  };

  const onFileBannerChange = (event) => {
    if (event.target.files[0]) {
      setDirty();
      const imageURL = URL.createObjectURL(event.target.files[0]);
      const image = { file: event.target.files[0], imageURL };
      setFileBanner(image);
    }
  };

  const onDeleteImage = (index, e) => {
    setDirty();
    e.preventDefault();
    setFileBanner(null);
  };

  const onCreateProject = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("cost", cost);
      formData.append("amount", amount);
      formData.append("limited", amount !== "");
      formData.append("startDate", startDate);
      formData.append("isActive", "true");
      formData.append("finishDate", finishDate);
      formData.append("deliveryDate", deliveryDate);
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });
      fileBanner.file && formData.append("fileBanner", fileBanner.file);
      await createExperience(formData);
      alert.show("Experiencia creada con exito", { type: "success" });
      setPristine();
      props.history.push("/main/experiences");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const onEditProject = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("cost", cost);
      formData.append("amount", amount);
      formData.append("limited", amount !== "");
      formData.append("startDate", startDate);
      formData.append("finishDate", finishDate);
      formData.append("deliveryDate", deliveryDate);
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });
      fileBanner.file && formData.append("fileBanner", fileBanner.file);
      formData.append("deleteUuidImage", forDeleteImages);

      activeValue !== null && formData.append("isActive", activeValue);
      await editExperience(experience.uuid, formData);
      alert.show("Experiencia editada con éxito", { type: "success" });
      setPristine();
      props.history.push("/main/experiences");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (document.getElementById("cost").value < 0) return false;
    if (document.getElementById("topLimit").value < 0) return false;
    if (!fileBanner) return false;

    return true;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos incorrectos");
      setShowModalError(true);
    } else {
      if (fromEdit) await onEditProject();
      else await onCreateProject();
    }
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            {/* {!fromEdit && ( */}
            <>
              {" "}
              <div className="contain-league">
                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={uuidLeague}
                  onChange={({ target: { value } }) => {
                    setUuidLeague(value);
                    setDirty();
                  }}
                  required
                >
                  <option name={""} value={""}>
                    Elige una liga...
                  </option>

                  {leagues?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidLeague && (
                    <small className="p-invalid transformY-20">
                      La liga es obligatorio.
                    </small>
                  )}
                </div>

                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={uuidClub}
                  onChange={({ target: { value } }) => {
                    setUuidClub(value);
                    setDirty();
                  }}
                  required
                >
                  <option name={""} value={""}>
                    Elige un club...
                  </option>

                  {clubs?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidClub && (
                    <small className="p-invalid transformY-20">
                      El club es obligatorio.
                    </small>
                  )}
                </div>

                <select
                  // className="select-league-decision"
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={activeValue}
                  onChange={({ target: { value } }) => {
                    setActiveValue(value);
                    setDirty();
                  }}
                >
                  <option name={""} value={""}>
                    Selecciona un estado...
                  </option>

                  {optionsEdit.map((item) => (
                    <option name={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </>

            <h2 className="title-banner">Banner</h2>
            <div className="container-banner">
              <div className="container-files">
                {fileBanner && (
                  <div style={{ marginRight: 10 }} key={0}>
                    <button
                      className="delete-banner"
                      onClick={(e) => {
                        onDeleteImage(0, e);
                      }}
                    >
                      X
                    </button>
                    <img src={fileBanner.imageURL} className="image" />
                  </div>
                )}
              </div>
              <input
                //className="input-add-banner"
                className="form-control"
                type="file"
                onChange={onFileBannerChange}
              />
            </div>
            <div>
              {submitted && !fileBanner && (
                <small className="p-invalid transformY-20">
                  El banner es obligatorio.
                </small>
              )}
            </div>

            <hr />

            {/* {!fromEdit && ( */}
            <div className="image-container">
              <h2 className="title-images">Imágenes</h2>
              <div
                // onDragOver={dragOver}
                // onDragEnter={dragEnter}
                // onDragLeave={dragLeave}
                // onDrop={fileDrop}
                className={!selectedImages.length > 0 ? "drop-container" : ""}
                //onClick={fileInputClicked}
              >
                {selectedImages?.map((item, index) => (
                  <div style={{ marginRight: 10 }} key={index}>
                    <button
                      className="delete-avatar"
                      onClick={(e) => {
                        fileDrop(index, e);
                      }}
                    >
                      X
                    </button>
                    <img src={item.imageURL} className="image" />
                  </div>
                ))}
              </div>
            </div>
            {/* )} */}
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
            <div onClick={fileInputClicked}>
              <button>Agregar Imagen</button>
            </div>

            <hr />

            <div className="image-container"></div>

            <div className="div-title">
              <div className="container-input-decision">
                <input
                  onChange={onChangeTitle}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Título Experiencia"
                  value={title}
                  required
                  maxLength={255}
                />
              </div>
              <div>
                {submitted && !title && (
                  <small className="p-invalid">El título es obligatorio.</small>
                )}
              </div>
            </div>
            <div>
              <div className="container-text-area-decision">
                <textarea
                  placeholder="Descripción Experiencia"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDescription}
                  value={description}
                  required
                  maxLength={255}
                />
              </div>
              <div>
                {submitted && !description && (
                  <small className="p-invalid">
                    La descripción es obligatoria.
                  </small>
                )}
              </div>
            </div>

            <div className="goal">
              <div className="container-input-decision">
                <input
                  id="cost"
                  onChange={onChangeGoal}
                  name="title"
                  type="number"
                  className="input-title"
                  placeholder="Costo"
                  value={cost}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !cost && (
                  <small className="p-invalid">El costo es obligatorio.</small>
                )}
              </div>
              <div>
                {submitted && cost < 0 && (
                  <small className="p-invalid">
                    El costo no debe ser negativo.
                  </small>
                )}
              </div>
            </div>

            <div className="goal">
              <div className="container-input-decision">
                <input
                  onChange={onChangeAmount}
                  id="topLimit"
                  name="amount"
                  type="number"
                  className="input-title"
                  placeholder="Tope límite"
                  value={amount}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !amount && (
                  <small className="p-invalid">
                    El tope límite es obligatorio.
                  </small>
                )}
              </div>
              <div>
                {submitted && amount < 0 && (
                  <small className="p-invalid">
                    El tope límite no puede ser negativo.
                  </small>
                )}
              </div>
            </div>

            <div className="container-date-picker">
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de inicio</h4>
                <DatePicker
                  style={{ backgroundColor: "red", color: "green" }}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>

              <div>
                {submitted && !startDate && (
                  <small className="p-invalid transformY-20">
                    La fecha de inicio es obligatoria.
                  </small>
                )}
              </div>

              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de cierre</h4>
                <DatePicker
                  selected={finishDate}
                  onChange={(date) => {
                    setFinishDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                {submitted && !finishDate && (
                  <small className="p-invalid transformY-20">
                    La fecha de cierre es obligatoria.
                  </small>
                )}
              </div>

              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de entrega</h4>
                <DatePicker
                  selected={deliveryDate}
                  onChange={(date) => {
                    setDeliveryDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div>
                {submitted && !deliveryDate && (
                  <small className="p-invalid transformY-20">
                    La fecha de deliveryDate es obligatoria.
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            GUARDAR
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="EXPERIENCIA"
            text={`${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateProject);
