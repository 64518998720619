import * as actionType from "../actions/types";

const GENERAL_STATE = {
  leaguesData: [],
  allClubs: [],
  optionList: [],
};

export default (state = GENERAL_STATE, action) => {
  switch (action.type) {
    case actionType.SET_ALL_CLUBS:
      return {
        ...state,
        allClubs: action.payload,
      };
    case actionType.SET_OPTION_LIST:
      return {
        ...state,
        optionList: [...state.optionList, action.payload],
      };
    case actionType.FILTER_OPTION_LIST:
      return {
        ...state,
        optionList: action.payload,
      };
    case actionType.CLEAN_OPTIONS:
      return {
        ...state,
        optionList: [],
      };
    case actionType.SET_DATA_LEAGUES:
      return {
        ...state,
        leaguesData: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};

// optionList: {
//   ...state.optionList,
//   [action.payload.type]: action.payload.value
// }
