import { SET_ALL_CLUBS, CLEAN_ALL_CLUBS } from "./types";

export const setClubs = (allClubs) => {
  return { type: SET_ALL_CLUBS, payload: allClubs };
};

export const cleanClubs = () => {
  return {
    type: CLEAN_ALL_CLUBS,
  };
};
