import React from "react";

import "./tooltips.css";

const tooltips = (props) => {
  let classes = [props.type, props.direction];
  return <div className={classes.join(" ")}>{props.children}</div>;
};

export default tooltips;
