import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { setTitle } from "../../store/actions/generalActions";
import Select from "../../ui/select/select";
import Button from "../../ui/button/button";
import Table from "../../components/table/Table";
import {
  deleteUserAPI,
  exportUsersCSVAPI,
  getUsersAPI,
  UsersData,
} from "../../store/axios/axios";
import { setUsers } from "../../store/actions/usersActions";
import UserForm from "../../components/UserForm/userForm";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import FileDownload from "js-file-download";
import { getToken } from "src/utils/tokenService";
import Modal from "src/ui/modal/modal";
import ReactPaginate from "react-paginate";
import "./styles.css";
import ReactSelect from "react-select";
import { getClubsAdmin } from "src/services/ClubService";
import SelectUI from "src/ui/selectUI";
import { subscribePetition } from "src/services/UserServices";
import { useAlert } from "react-alert";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";
import { Prompt as Prompt2 } from "react-router-dom";

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return `${day}/${month}/${year.toString().substring(2, 4)}`;
};

export const roleOptions = [
  {
    id: "ADMIN",
    value: "Administrador",
  },
  {
    id: "USER",
    value: "Usuarios",
  },
  {
    id: "PROFESSIONAL",
    value: "Profesionales",
  },
];

export const activeOptions = [
  {
    id: true,
    value: "Suscripto",
  },
  {
    id: false,
    value: "No suscripto",
  },
];

const fieldAndHeaders = {
  CREADO: "created",
  NOMBRE: "name",
  APELLIDO: "lastName",
  DOCUMENTO: "dni",
  "E-mail": "email",
  VERIF: "isVerified",
  ADMIN: "admin",
  ROL: "role",
  ESTADO: "status",
  PENDING: "pending",
};

const tableEnableButtons = {
  edit: true,
  del: true,
  action1: true,
};

const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];

const optionsRole = [
  { value: "NOT_SUBSCRIBED", label: "NOT_SUBSCRIBED" },
  { value: "SUBSCRIBED", label: "SUBSCRIBED" },
];

const optionsPending = [
  { value: "SUBSCRIPTION_PENDING", label: "SUBSCRIPTION_PENDING" },
  { value: "UNSUBSCRIPTION_PENDING", label: "UNSUBSCRIPTION_PENDING" },
];

const Usuarios = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usersData = useSelector((state) => state.users);

  const { register, handleSubmit, reset } = useForm({
    defaultValues: { search: "" },
  });
  const [editUser, setEditUser] = useState(undefined);
  const [clubs, setClubs] = useState(null);
  const [userModal, setUserModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmPedidoSuscripcion, setConfirmPedidoSuscripcion] =
    useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterBy, setFilterBy] = useState(null);
  const [pendingFilterBy, setPendingFilterBy] = useState(null);
  const [clubFilter, setClubFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [userUuid, setUserUuid] = useState(null);
  const [searchByEmail, setSearchByEmail] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const searchRef = useRef();
  const [Prompt, setDirty, setPristine, isDirty] = useUnsavedChangesWarning();

  const handleDelete = async () => {
    await deleteUserAPI({ id: editUser.id, access_token: getToken() });
    refreshList();
    setConfirmModalOpen(false);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const handleActionClick = (id, option) => {
    // filtrar desde la base de datos para mejorar performance en front.
    // const user = usersData?.items?.items?.find((user) => user.uuid === id);
    const user = tableData.find((user) => user.id === id);
    console.log("user", user);
    setEditUser({
      id: user.id,
      name: user.name,
      lastName: user.lastName,
      email: user.email,
      dni: user.dni,
      phone: user.phone,
      role: user?.userClub[0]?.role, // Modificar cuando se agreguen mas clubes
      userClub: user.userClub,
      membershipNumber: user?.userClub[0]?.membershipNumber,
      isVerified: user.verified ? "Si" : "No",
      admin: user.admin ? "Si" : "No",
      status: user.userClub[0]?.subscriber, // Modificar cuando se agreguen mas clubes
      subscriptionpendingstatus: user.userClub[0]?.subscriptionpendingstatus,
      uuid: user.userClub[0]?.clubUuid, // Modificar cuando se agreguen mas clubes
      uuidRelation: user.userClub[0]?.uuid,
      avatar: user.avatar,
    });

    if (option === "action1") {
      setUserUuid(user.id);
      setConfirmPedidoSuscripcion(true);
      return;
    }

    if (option === "edit") {
      setUserModal(true);
    } else {
      setConfirmModalOpen(true);
    }
  };

  const handleCreate = () => {
    setEditUser(undefined);
    setUserModal(true);
  };

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  const refreshList = async () => {
    try {
      const data = await getUsersAPI({
        access_token: getToken(),
        subscriberStatus: filterBy,
        page,
        clubUuid: clubFilter,
        email: searchRef.current.value,
        pendingStatus: pendingFilterBy,
      });
      dispatch(setUsers(data.data));
      setTableData(
        data?.data?.items?.map((user) => ({
          id: user.uuid,
          name: user.name,
          lastName: user.lastName,
          email: user.email,
          phone: user.phone,
          dni: user.dni,
          role: user.userClub[0]?.role, // Modificar cuando se agreguen mas clubes
          userClub: user.userClub,
          isVerified: user.verified ? "Si" : "No",
          admin: user.admin ? "Si" : "No",
          status: user?.userClub[0]?.subscriber, // Modificar cuando se agreguen mas clubes
          pending: user?.userClub[0]?.subscriptionpendingstatus,
          created: formatDate(user.created),
          uuid: user.userClub[0]?.uuid, // Modificar cuando se agreguen mas clubes
          avatar: user.avatar,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    dispatch(setTitle("Usuarios"));
    getAllClubs();
  }, []);

  useEffect(() => {
    refreshList();
  }, [page, filterBy, clubFilter, searchByEmail, pendingFilterBy]);

  const pageCount = 5;

  const onCloseModal = () => {
    let res = false;
    if (isDirty) {
      res = window.confirm(
        "Atención, está a punto de perder sus modificaciones. Desea continuar?"
      );
      if (res) setPristine();
      setShowPrompt(res);
    } else {
      setUserModal(false);
    }
  };

  useEffect(() => {
    if (showPrompt) {
      setUserModal(false);
      setEditUser(undefined);
      setShowPrompt(false);
    }
  }, [showPrompt]);

  const onStatusChange = (e) => {
    setFilterBy(e.target.value);
  };

  const onPendingChange = (e) => {
    setPendingFilterBy(e.target.value);
  };

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  // funcion que busca y filtra dentro de todos los usuarios
  // mejorar performance para filtrar mayor cantidad de usuarios
  const handleSearch = (data) => {
    const search = data.search.toLowerCase();
    console.log("BUSQUEDA", search);
    UsersData({
      access_token: getToken(),
      clubUuid: clubFilter,
    }).then((data) => {
      console.log("USUARIOS FILTRADOS", data);
      const filtered = data.data.items.filter((item) =>
        item.email.includes(`${search}`)
      );
      setTableData(
        filtered.map((user) => ({
          id: user.uuid,
          name: user.name,
          lastName: user.lastName,
          email: user.email,
          dni: user.dni,
          phone: user.phone,
          role: user.userClub[0]?.role, // Modificar cuando se agreguen mas clubes
          userClub: user.userClub,
          isVerified: user.verified ? "Si" : "No",
          admin: user.admin ? "Si" : "No",
          status: user?.userClub[0]?.subscriber, // Modificar cuando se agreguen mas clubes
          pending: user?.userClub[0]?.subscriptionpendingstatus,
          created: formatDate(user.created),
          uuid: user.userClub[0]?.club.uuid, // Modificar cuando se agreguen mas clubes
        }))
      );
    });
  };

  const handlePedidoSuscripcion = async () => {
    try {
      const r = await subscribePetition(userUuid);
      console.log("subscribePetition", r);
      refreshList();
      setConfirmPedidoSuscripcion(false);
      alert.show(`LINK DE SUSCRIPCIÓN ENVIADO`, {
        type: "success",
      });
    } catch (e) {
      setConfirmPedidoSuscripcion(false);
      alert.show(`NO SE PUDO ENVIAR EL LINK DE SUSCRIPCIÓN`, {
        type: "error",
      });
    }
  };

  // const openComfirmPedidoSuscripcion = (item) => {
  //   setUserUuid(item.id);
  //   setConfirmPedidoSuscripcion(true);
  // };

  // const ButtonRender = (item) => {
  //   return (
  //     <Button
  //       event={<i className="fa fa-check"></i>}
  //       customStyle={
  //         item.status === "NOT_SUBSCRIBED" ? {} : { display: "none" }
  //       }
  //       secondary
  //       action={() => openComfirmPedidoSuscripcion(item)}
  //       //disabled={item.status !== "NOT_SUBSCRIBED"}
  //     />
  //   );
  // };

  const disabledAction1 = (item) => {
    return (
      item.status === "SUBSCRIBED"
      // item.status === "SUBSCRIBED" ||
      // item.userClub[0].subscriptionpendingstatus === "SUBSCRIPTION_PENDING"
    );
  };

  return (
    <>
      <div className="content">
        <div className="container-create-users">
          <div className="users-filters-buttons">
            <Button event="CREAR USUARIO" action={handleCreate} secondary />
            {/* <Button event="CARGA MASIVA" secondary disabled/> */}
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              setSearchByEmail(searchRef.current.value);
            }}
          >
            <div className="user-filters-item">
              <label>Filtrar por Email</label>
              <div className="filter-input-button">
                <input
                  id="user-filter-input"
                  placeholder="filtro por email"
                  className="user-filter-input"
                  ref={searchRef}
                />
                <div className="filters-button">
                  <Button event="buscar" secondary value="search" />
                  <button
                    className="button"
                    onClick={() => {
                      document.getElementById("user-filter-input").value = "";
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    limpiar
                  </button>
                </div>
              </div>
            </div>
          </form>

          {/* <div>
            <form onSubmit={handleSubmit(handleSearch)}>
              <input
                type="text"
                {...register("search")}
                placeholder="Filtrar por Email"
              />
              <button type="submit">BUSCAR</button>
            </form>
            <button
              onClick={() => {
                refreshList();
                reset();
              }}
            >
              Limpiar Busqueda
            </button>
          </div> */}
          <div className="users-filters">
            <div className="user-filters-item">
              <label>Filtrar Club</label>
              <SelectUI
                options={clubs}
                isLoading={!clubs}
                onChange={onClubChange}
              />
            </div>
            <div className="user-filters-item">
              <label>Filtrar Estado</label>
              <SelectUI options={optionsRole} onChange={onStatusChange} />
            </div>
            <div className="user-filters-item">
              <label>Filtrar Pendientes</label>
              <SelectUI options={optionsPending} onChange={onPendingChange} />
            </div>
          </div>
        </div>
      </div>
      <Table
        fieldAndHeaders={fieldAndHeaders}
        setPage={handlePage}
        totalItems={usersData?.items.meta?.totalItems}
        items={tableData}
        pages={usersData?.items.meta?.totalPage}
        currentPage={usersData?.items.meta?.currentPage}
        currentItems={usersData?.items.meta?.itemsPerPage}
        triggerRow={handleActionClick}
        enableButtons={tableEnableButtons}
        disabledAction1={disabledAction1}
        // ButtonColumn={ButtonRender}
        // ButtonColumnTitle={"SUSCRIBIR"}
        // ButtonColumnClass={"button-destacado"}
      />
      <div className="footer">
        <p className="total">
          Total de usuarios: {usersData?.items?.meta?.totalItems}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePage}
          pageRangeDisplayed={5}
          pageCount={usersData?.items.meta?.totalPage}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeLinkClassName="active"
        />
      </div>
      {console.log("userModal", userModal)}
      {userModal && (
        <Modal
          customOnClose={() => {
            onCloseModal();
          }}
          key="modal"
        >
          <UserForm
            user={editUser}
            setOpenModal={setUserModal}
            onSubmitForm={refreshList}
            setDirty={setDirty}
            setPristine={setPristine}
          />
        </Modal>
      )}
      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"¿Estás seguro que deseas borrar el usuario?"}
            title={"Borrar usuario"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
      {confirmPedidoSuscripcion && (
        <Modal closeModal={setConfirmPedidoSuscripcion} key="modal">
          <ConfirmModal
            content={
              "¿Estás seguro que deseas enviar link de suscripción al usuario?"
            }
            title={"Envío de link de Suscripción"}
            mainButtonText={"Enviar"}
            onCancel={() => setConfirmPedidoSuscripcion(false)}
            onAccept={handlePedidoSuscripcion}
          />
        </Modal>
      )}
      {Prompt}
    </>
  );
};

export default Usuarios;
