import React, { useState } from "react";

import sprite from "../../assets/icons/iconsprite.svg";

import "./modal.css";

const Modal = (props) => {
  const [modalClose, setmodalClose] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    props.customOnClose ? props.customOnClose() : setmodalClose(true);
    // setTimeout(() => {
    //   props.closeModal(false);
    // }, 600);
  };
  return (
    <>
      <div
        onClick={(e) => closeModal(e)}
        className={
          modalClose ? "modal-container container-out" : "modal-container"
        }
      >
        {" "}
      </div>
      <div className="modal-wrapper">
        <div className={modalClose ? "modal-content out" : "modal-content"}>
          <div className="modal-close">
            <span onClick={(e) => closeModal(e)}>
              <svg className="close">
                <use href={sprite + "#close"}></use>
              </svg>
            </span>
          </div>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default Modal;
