import { api } from "../config/api";

const PROJECTS = "projects/admin";

export const getAllExperiences = (
  limit,
  page,
  club,
  isActive,
  finalized,
  title
) =>
  api.get(`experiences/admin/?title=${title}`, {
    params: {
      limit,
      page,
      club,
      isActive,
      finalized,
    },
  });

export const getImages = (expUuid) => api.get(`/experiences/images/${expUuid}`);

export const deleteExperience = (uuid) => api.delete(`experiences/${uuid}`);

export const createExperience = (formData) =>
  api.post("/experiences", formData);

export const editExperience = (uuid, formData) =>
  api.put(`experiences/${uuid}`, formData);
