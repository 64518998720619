import { api } from "../config/api";

const PROJECTS = "projects/admin";

export const getAllProjects = (
  limit,
  page,
  club,
  isActive,
  finalized,
  title
) => {
  return api.get(`projects/admin/?title=${title}`, {
    params: {
      limit,
      page,
      club,
      isActive,
      finalized,
    },
  });
};

export const getImages = (uuid) => api.get(`/projects/images/${uuid}`);

export const createProject = (formData) => api.post("/projects", formData);

export const deleteProject = (uuid) => api.delete(`projects/${uuid}`);

export const editProject = (uuid, formData) =>
  api.put(`projects/${uuid}`, formData);
