//REGISTER
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_END = "LOGIN_END";
export const LOGOUT = "LOGOUT";
export const ISAUTH = "ISAUTH";
export const LOGIN_ROLE = "LOGIN_ROLE";
export const LOGIN_AUTH = "LOGIN_AUTH";

//GENERAL
export const LOADING = "LOADING";
export const MODAL_OPEN = "MODAL_OPEN";
export const MENU_OPEN = "MENU_OPEN";
export const CLOSE_MENU = "CLOSE_MENU";
export const TOOLTIPS_OPEN = "TOOLTIPS_OPEN";
export const TITLE = "TITLE";
export const ERROR = "ERROR";
export const USER_SET_NAME = "USER_SET_NAME";
export const ADD_CLASS_TO_TITLE = "ADD_CLASS_TO_TITLE";
export const SET_ITEMS_IN_PAGE = "SET_ITEMS_IN_PAGE";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";

//DATA
export const FETCH_TIPOS_RESIDUOS = "FETCH_TIPOS_RESIDUOS";
export const FETCH_REPORTES_RESIDUOS = "FETCH_REPORTES_RESIDUOS";
export const SET_CURRENT_DOC = "SET_CURRENT_DOC";
export const DELETE_CURRENT_DOC = "DELETE_CURRENT_DOC";

// CATEGORIES
export const SET_CATEGORIES = "SET_CATEGORIES";

// AUTH
export const LOGIN = "LOGIN";

// USERS
export const SET_USERS = "SET_USERS";

// DECISIONS
export const SET_ALL_DECISIONS = "SET_ALL_DECISIONS";
export const CLEAN_DECISIONS = "CLEAN_DECISIONS";
export const SET_UUID_DECISION = "SET_UUID_DECISION";

//OPTIONS
export const SET_OPTION_LIST = "SET_OPTION_LIST";
export const FILTER_OPTION_LIST = "FILTER_OPTION_LIST";
export const CLEAN_OPTIONS = "CLEAN_OPTIONS";
//CLUBS
export const UNAUTHORIZED_ERROR = "UNAUTHORIZED_ERROR";
export const SET_ALL_CLUBS = "SET_ALL_CLUBS";
export const CLEAN_ALL_CLUBS = "CLEAN_ALL_CLUBS";

// PROJECTS
export const SET_PROJECTS = "SET_PROJECTS";
export const CLEAN_PROJECTS = "CLEAN_PROJECTS";

export const CREATED = 201;

export const BAD_REQUEST = 400;
export const UNAUTHORIZED = 401;
export const NOT_FOUND = 404;
export const NOT_ACCEPTABLE = 406;

export const INTERNAL_ERROR = 500;

// messages hint
export const PHONE_ALREADY_EXIST = "user already exist with phone";
export const EMAIL_ALREADY_EXIST = "user already exist with email";

// messages code
export const PHONE_ALREADY_EXIST_CODE = "1";
export const EMAIL_ALREADY_EXIST_CODE = "2";

// leagues
export const SET_DATA_LEAGUES = "SET_DATA_LEAGUES";
