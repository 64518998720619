import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import GoBack from "../../components/goBack/goBack";
import {
  getLeagues,
  createNewClub,
  getClubsByLeague,
  getLeaguesAdmin,
  getClubsAdmin,
  getClubsAdminByLeague,
} from "src/services/ClubService";
import "./createClub.css";
import { HexColorPicker } from "react-colorful";
import ModalError from "src/ui/modalError/modalError";
import { useAlert } from "react-alert";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const CreateClub = (props) => {
  const [errorModalText, setErrorModalText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [colorPrimary, setColorPrimary] = useState("");
  const [colorSecondary, setColorSecondary] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [clubsNames, setClubsNames] = useState([]);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [data, setData] = useState({
    nameClub: "",
    uuidLeague: "",
    logoClub: "",
    logoNav: "",
    flagClub: "",
    backgroundClub: "",
  });
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const alert = useAlert();
  useEffect(() => {
    props.setTitle("Agregar Club");
  }, []);

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((item) => item.isActive));
  };

  const getAllClubs = async () => {
    const response = await getClubsAdminByLeague(data.uuidLeague);
    setClubsNames(response.data.items.map((club) => club.name.toLowerCase()));
  };

  // const getClub = async () => {
  //   const AFA_UUID = "1f4d8fff-f37e-457c-9808-6ce54eefb9a1";
  //   await getClubsByLeague(data.uuidLeague);
  // };

  useEffect(() => {
    getAllLeagues();
  }, []);

  useEffect(() => {
    getAllClubs();
    // getClub();
  }, [data.uuidLeague]);

  const onLogoChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, logoClub: image });
  };

  const onNavChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, logoNav: image });
  };

  const onFlagChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, flagClub: image });
  };

  const onBackgroundChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, backgroundClub: image });
  };
  const onCreateClub = async (e) => {
    //e.target.disabled = true;
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setErrorModalText("Existen campos requeridos sin dato");
      setShowModalError(true);
      return;
    }
    try {
      var formData = new FormData();
      formData.append("name", data.nameClub);
      formData.append("colorPrimary", colorPrimary);
      formData.append("colorSecondary", colorSecondary);
      formData.append("league", data.uuidLeague);
      formData.append("logoClub", data.logoClub.file);
      formData.append("logoNav", data.logoNav.file);
      formData.append("flagClub", data.flagClub.file);
      formData.append("backgroundClub", data.backgroundClub.file);
      await createNewClub(formData);
      alert.show(`Club creado con exito`, { type: "success" });
      setPristine();
      props.history.push("/main/clubs");
    } catch (error) {
      setErrorModalText(`Error al crear club`, { type: "error" });
      setShowModalError(true);
      //alert.show(`Error al crear club`, { type: "error" });
    } finally {
      setDisabledSaveButton(false);
    }
  };
  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (clubsNames.includes(data.nameClub.trim().toLowerCase())) return false;
    if (!colorPrimary || !colorSecondary) return false;
    return true;
  };

  const onChangeColorPrimary = (color) => {
    setDirty();
    setColorPrimary(color);
  };

  const onChangeColorSecondary = (color) => {
    setDirty();
    setColorSecondary(color);
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            <div className="contain-league">
              <select
                className="select-league"
                onChange={({ target: { value } }) => {
                  setDirty();
                  setData({ ...data, uuidLeague: value });
                }}
                required
              >
                <option name={""} value={""}>
                  Elige una liga
                </option>

                {leagues?.map((item) => (
                  <option name={item.uuid} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </select>
              {submitted && data.uuidLeague === "" && (
                <small className="p-invalid transformY-20">
                  Seleccionar una liga es obligatorio.
                </small>
              )}
              <input
                type="text"
                placeholder="Nombre del club"
                className="form-control-create-club"
                onChange={(e) => {
                  setDirty();
                  setData({ ...data, nameClub: e.target.value });
                }}
                name="nombre"
                required
              />
            </div>
            {submitted && data.nameClub === "" && (
              <small className="p-invalid transformY-20">
                Escriba el nombre del club.
              </small>
            )}
            {submitted &&
              clubsNames.includes(data.nameClub.trim().toLowerCase()) && (
                <small className="p-invalid transformY-20">
                  Ya existe un club con este nombre.
                </small>
              )}

            <p style={{ color: "#626262" }}>
              Las imágenes no deben pesar mas de 5MB
            </p>

            <div className="contain-image">
              <p className="title">Logo del club</p>
              {data.logoClub.imageURL && (
                <img src={data.logoClub.imageURL} className="img" />
              )}
              <input
                className="input-clubs"
                type="file"
                placeholder="logoClub"
                onChange={(e) => {
                  onLogoChange(e);
                }}
                name="logoClub"
                required
              ></input>
            </div>
            {submitted && !data.logoClub.file && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un logo para el club.
              </small>
            )}

            <div className="contain-image">
              <p className="title">Logo del club para la navegación</p>
              {data.logoNav.imageURL && (
                <img src={data.logoNav.imageURL} className="img" />
              )}

              <input
                className="input-clubs"
                type="file"
                placeholder="logoNav"
                name="logoNav"
                onChange={(e) => {
                  onNavChange(e);
                }}
                name="clubIcon"
                required
              ></input>
            </div>
            {submitted && !data.logoNav.file && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un logo para el nav.
              </small>
            )}

            <div className="contain-image">
              <p className="title">Bandera del club</p>
              {data.flagClub.imageURL && (
                <img src={data.flagClub.imageURL} className="img" />
              )}

              <input
                className="input-clubs"
                type="file"
                placeholder="flagClub"
                name="flagClub"
                onChange={(e) => {
                  onFlagChange(e);
                }}
                name="clubIcon"
                required
              ></input>
            </div>
            {submitted && !data.flagClub.file && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un bandera para el club.
              </small>
            )}

            <div className="contain-image">
              <p className="title">Imagen de fondo</p>
              {data.backgroundClub.imageURL && (
                <img src={data.backgroundClub.imageURL} className="img" />
              )}
              <input
                className="input-clubs"
                type="file"
                placeholder="backgroundClub"
                onChange={(e) => {
                  onBackgroundChange(e);
                }}
                name="backgroundClub"
                required
              />
            </div>
            {submitted && !data.backgroundClub.file && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un fondo para el club.
              </small>
            )}
          </form>
          <div style={{ marginLeft: 150 }}>
            <div>
              <p className="title hex">Elige el color primario</p>
              <HexColorPicker
                style={{ marginBottom: 20 }}
                color={colorPrimary}
                onChange={onChangeColorPrimary}
              />
            </div>
            {submitted && !colorPrimary && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un color primario.
              </small>
            )}
            <div>
              <p className="title hex">Elige el color secundario</p>
              <HexColorPicker
                style={{ marginBottom: 20 }}
                color={colorSecondary}
                onChange={onChangeColorSecondary}
              />
            </div>
            {submitted && !colorSecondary && (
              <small className="p-invalid transformY-20">
                Debe seleccionar un color secundario.
              </small>
            )}
          </div>
        </div>
        <button
          disabled={disabledSaveButton}
          className="btn-create-club"
          onClick={(e) => {
            onCreateClub(e);
          }}
        >
          GUARDAR
        </button>
        {showModalError && (
          <ModalError
            title="ERROR CREANDO CLUB"
            text={errorModalText}
            closeModal={(e) => {
              setErrorModalText("");
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateClub);
