import React from "react";
import "./styles.css";

const SelectUI = ({ options, customStyle, isLoading, ...props }) => {
  return (
    <select {...props} className="custom-select-ui" style={customStyle}>
      <option value={null} label={isLoading ? "Cargando" : "Sin filtro"} />
      {!isLoading &&
        options?.map((item, index) => (
          <option
            key={item?.key || `${item.value}-${index}`}
            label={item.label}
          >
            {item.value}
          </option>
        ))}
    </select>
  );
};

export default SelectUI;
