import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import GoBack from "../../components/goBack/goBack";

const TestPage = (props) => {
  useEffect(() => {
    props.setTitle("Test page");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <GoBack defaultGoBack={"/main"} state={1} />
      <div>Nueva página de prueba</div>
    </>
  );
};

export default connect(null, {
  setTitle,
})(TestPage);
