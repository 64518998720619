import React from "react";
import { withRouter } from "react-router";
import backArrow from "../../assets/icons/back-arrow.svg";
import "./goback.css";

const GoBack = (props) => {
  return (
    <div
      id="goback"
      onClick={() => props.history.push(`${props.defaultGoBack}`)}>
      <img src={backArrow} alt="Atrás" />
    </div>
  );
};

export default withRouter(GoBack);
