import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { useHistory } from "react-router-dom";
import ModalError from "src/ui/modalError/modalError";
import Modal from "../../ui/modal/modal";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import lupa from "../../assets/images/lupa.png";
import editar from "../../assets/images/editar.png";
import "./table.css";

import {
  getAllProjects,
  edit,
  deleteProject,
} from "src/services/ProjectService";
import { setProjects } from "src/store/actions/projectsActions";
import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import { getClubsAdmin } from "src/services/ClubService";
import Table from "src/components/table/Table";
import ReactPaginate from "react-paginate";

const fieldAndHeaders = {
  CLUB: "club",
  TITULO: "title",
  BALANCE: "balance",
  OBJETIVO: "goal",
  FINALIZADO: "finalized",
  ACTIVO: "isActive",
};

const optionsActive = [
  { value: "true", label: "Activo" },
  { value: "false", label: "No Activo" },
];
const optionsFinalized = [
  { value: "true", label: "Finalizado" },
  { value: "false", label: "No Finalizado" },
];

const tableEnableButtons = {
  edit: true,
  del: true,
  action4: true,
};

const Projects = (props) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [finalizedFilter, setFinalizedFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [page, setPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [searchByTitle, setSearchByTitle] = useState("");

  const searchRef = useRef();
  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  useEffect(() => {
    dispatch(setTitle("Proyectos"));
    getAllClubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshList = async () => {
    try {
      const data = await getAllProjects(
        20,
        page,
        clubFilter,
        activeFilter,
        finalizedFilter,
        searchByTitle
      );
      setProjects(data.data);
      setTableData(
        data?.data?.items?.map((item) => ({
          isActive: item.isActive ? "Si" : "No",
          finalized: item.finalized ? "Si" : "No",
          title: item.title,
          id: item.uuid,
          club: item.club.name,
          description: item.description,
          options: item.options,
          balance: item.balance,
          goal: item.goal,
        }))
      );
    } catch (error) {}
  };

  const handleDelete = async () => {
    try {
      await deleteProject(idToDelete);
      const result = tableData.filter((item) => item.id !== idToDelete);
      setTableData(result);
      setConfirmModalOpen(false);
    } catch (error) {
      setShowModalError(true);
    }
  };

  useEffect(() => {
    refreshList();
  }, [page, clubFilter, activeFilter, finalizedFilter, searchByTitle]);

  // const editProject = async (e, item) => {
  //   e.preventDefault();
  //   const boolean = item.isActive ? false : true;
  //   try {
  //     await edit({ isActive: boolean }, item.uuid);
  //     getProjectsData();
  //   } catch (error) {
  //     setShowModalError(true);
  //   }
  // };

  const onFinalizedChange = (e) => {
    setFinalizedFilter(e.target.value);
  };

  const onActiveChange = (e) => {
    setActiveFilter(e.target.value);
  };

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const handleActionClick = (id, option, item) => {
    if (option === "edit") {
      const project = projects.items.find((it) => it.uuid === item.id);
      props.history.push({
        pathname: "/main/projects/edit",
        state: { project },
      });
    }
    if (option === "del") {
      setIdToDelete(id);
      setConfirmModalOpen(true);
    }
    if (option === "action4") {
      props.history.push({
        pathname: "/main/projects/investment",
        state: { uuid: item.id, entity: "PROJECT", name: item.title },
      });
    }
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button
            event="CREAR PROYECTO"
            action={() => props.history.push("/main/projects/create")}
            secondary
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchByTitle(searchRef.current.value);
          }}
        >
          <div className="user-filters-item">
            <label>Filtrar por titulo</label>
            <div className="filter-input-button">
              <input
                id="user-filter-input"
                placeholder="filtro por titulo"
                className="user-filter-input"
                ref={searchRef}
              />
              <div className="buttons-filter" style={{ maxHeight: "36px" }}>
                <Button event="buscar" secondary value="search" />
                <button
                  className="button"
                  onClick={() => {
                    document.getElementById("user-filter-input").value = "";
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  limpiar
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="users-filters">
          <div className="user-filters-item">
            <label>Filtrar Club</label>
            <SelectUI
              options={clubs}
              isLoading={!clubs}
              onChange={onClubChange}
            />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Activo</label>
            <SelectUI options={optionsActive} onChange={onActiveChange} />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Finalizado</label>
            <SelectUI options={optionsFinalized} onChange={onFinalizedChange} />
          </div>
        </div>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={fieldAndHeaders}
          setPage={() => {}}
          totalItems={projects?.meta?.totalItems}
          items={tableData}
          pages={projects?.meta?.totalPage}
          currentPage={projects?.meta?.currentPage}
          currentItems={projects?.meta?.itemsPerPage}
          triggerRow={handleActionClick}
          enableButtons={tableEnableButtons}
        />
        <div className="footer">
          <p className="total">
            Total de proyectos: {projects?.meta?.totalItems}
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={projects?.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar el proyecto?"}
            title={"Borrar proyecto"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO PROYECTO"
          text={"Editar el estado del proyecto"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default Projects;
