import React, { Component } from "react";
import Button from "../../ui/button/button";

import "./table.css";

class Table extends Component {
  getNextPage = () => {
    if (this.props.currentPage < this.props.pages) {
      this.props.setPage(this.props.currentPage + 1, {
        initial: this.props.currentItems.initial + 10,
        final: this.props.currentItems.final + 10,
      });
    }
  };

  getPreviousPage = () => {
    if (this.props.currentPage > 1) {
      this.props.setPage(this.props.currentPage - 1, {
        initial: this.props.currentItems.initial - 10,
        final: this.props.currentItems.final - 10,
      });
    }
  };

  getPage = (page) => {
    if (this.props.currentPage !== page) {
      this.props.setPage(page, {
        initial: page * 10 - 10,
        final: page * 10,
      });
    }
  };

  handleButtonClick = (event, item, option) => {
    // event.stopPropagation();
    this.props.triggerRow(option.id, option.action, item);
  };

  formatField = (field, object) => {
    switch (object.type) {
      case "money":
        return new Intl.NumberFormat("es-AR", {
          style: "currency",
          currency: "ARS",
        }).format(field);
      case "date-billing":
        return `${field.slice(4)}/${field.slice(0, 4)}`;
      case "boolean":
        let status = field ? (
          <p className="green">{object.t}</p>
        ) : (
          <p className="red">{object.f}</p>
        );
        return status;
      case "categoryKeywords":
        return <p>{field.map((keyword) => keyword.name).join(", ")}</p>;
      default:
        break;
    }
  };

  render() {
    let typeTable = this.props.type === "LIST";
    let triggerRow = this.props.triggerRow !== undefined;
    const bodyData = this.props.items?.map((item, index) => {
      return (
        <tr
          key={index}
          className={`${typeTable && triggerRow ? "row-items" : null} ${
            this.props.onRowClick ? "pointer" : ""
          }`}
          onClick={() =>
            this.props.onRowClick && this.props.onRowClick(item.id)
          }
        >
          {Object.keys(this.props.fieldAndHeaders).map((header, i) => {
            if (typeof this.props.fieldAndHeaders[header] === "object") {
              return (
                <td key={header + 1}>
                  {this.formatField(
                    item[this.props.fieldAndHeaders[header].field],
                    this.props.fieldAndHeaders[header]
                  )}
                </td>
              );
            }
            return (
              <td style={{ borderWidth: 0 }} key={header + 1}>
                {item[this.props.fieldAndHeaders[header]]}
              </td>
            );
          })}

          {this.props.ButtonColumn ? (
            <td
              style={{ borderWidth: 0 }}
              className={
                this.props.ButtonColumnClass
                  ? this.props.ButtonColumnClass
                  : "button-actions"
              }
            >
              {this.props.ButtonColumn(item, this.props.handleEdit)}
            </td>
          ) : null}

          {!typeTable ? (
            <td style={{ borderWidth: 0 }} className="button-actions">
              {this.props.enableButtons && (
                <>
                  {this.props.enableButtons.edit && (
                    <Button
                      event="EDIT"
                      disabled={
                        this.props.disabledEdit && this.props.disabledEdit(item)
                      }
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "edit",
                        })
                      }
                    />
                  )}
                  <div style={{ width: 10 }}></div>
                  {this.props.enableButtons.del && (
                    <Button
                      event="DELETE"
                      variant={"error"}
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "del",
                        })
                      }
                      style={{ marginLeft: 5 }}
                    />
                  )}
                  <div style={{ width: 10 }}></div>
                  {this.props.enableButtons.action1 && (
                    <Button
                      event="MAIL"
                      disabled={
                        this.props.disabledAction1 &&
                        this.props.disabledAction1(item)
                      }
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "action1",
                        })
                      }
                      style={{ marginLeft: 5 }}
                    />
                  )}
                  <div style={{ width: 10 }}></div>
                  {this.props.enableButtons.action2 && (
                    <Button
                      event="WIN"
                      disabled={
                        this.props.disabledAction2 &&
                        this.props.disabledAction2(item)
                      }
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "action2",
                        })
                      }
                      style={{ marginLeft: 5 }}
                    />
                  )}
                  <div style={{ width: 10 }}></div>
                  {this.props.enableButtons.action3 && (
                    <Button
                      event="SET"
                      disabled={
                        this.props.disabledAction3 &&
                        this.props.disabledAction3(item)
                      }
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "action3",
                        })
                      }
                      style={{ marginLeft: 5 }}
                    />
                  )}
                  {this.props.enableButtons.action4 && (
                    <Button
                      event="MON"
                      disabled={
                        this.props.disabledAction4 &&
                        this.props.disabledAction4(item)
                      }
                      action={(e) =>
                        this.handleButtonClick(e, item, {
                          id: item.id,
                          action: "action4",
                        })
                      }
                      style={{ marginLeft: 5 }}
                    />
                  )}
                </>
              )}
            </td>
          ) : null}
        </tr>
      );
    });

    console.log(this.props.totalItems);

    return (
      <table className="table">
        <thead>
          <tr>
            {Object.keys(this.props.fieldAndHeaders).map((header, index) => {
              console.log("head", header);
              return <td key={"header" + index}>{header}</td>;
            })}
            {this.props.ButtonColumn ? (
              <td style={{ borderWidth: 0 }} className="button-actions">
                {this.props.ButtonColumnTitle
                  ? this.props.ButtonColumnTitle
                  : "DESTACADO"}
              </td>
            ) : null}

            {this.props.enableButtons ? (
              <td>ACCIONES</td>
            ) : // <td style={{ textAlign: "center" }}>ACCIONES</td>
            null}
          </tr>
        </thead>

        <tbody>{bodyData}</tbody>
      </table>
    );
  }
}

export default Table;
