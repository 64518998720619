import { api } from "../config/api";

//const PROJECTS = "projects/admin";

export const getAllRaffles = (club, limit, page, title, finalized) =>
  api.get(`raffles/admin/`, {
    params: {
      club,
      limit,
      page,
      title,
      finalized,
    },
  });

export const getImages = (raffleUuid) =>
  api.get(`/raffles/images/${raffleUuid}`);

export const deleteRaffle = (uuid) => api.delete(`raffles/${uuid}`);

export const createRaffle = (formData) => api.post("/raffles", formData);

export const editRaffle = (uuid, formData) =>
  api.put(`raffles/${uuid}`, formData);

export const getWinners = (raffleUuid) =>
  api.get(`/raffles/winners/${raffleUuid}`);
