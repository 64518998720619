import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import Button from "../../ui/button/button";
import Table from "../../components/table/Table";
import { setUsers } from "../../store/actions/usersActions";
import ReactPaginate from "react-paginate";
import "./styles.css"
import { getClubsAdmin, getPartners } from "src/services/ClubService";
import SelectUI from "src/ui/selectUI";

const fieldAndHeaders = {
  "NUMERO DE SOCIO": "membershipNumber",
  DNI: "dni",
  USADO: "used",
};

const optionsUsed = [{ value: "true", label: "USADO"}, { value: "false", label: "NO USADO" }];

const Partners = (props) => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const usersData = useSelector((state) => state.users);

  const [editUser, setEditUser] = useState(undefined);
  const [clubs, setClubs] = useState(null)
  const [userModal, setUserModal] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filterBy, setFilterBy] = useState(null);
  const [clubFilter, setClubFilter] = useState(null)
  const [page, setPage] = useState(1);


  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items.filter(item => item.isActive).map(item => ({ label: item.name, value: item.uuid }))
    setClubs(filtered);
  };
  

  const refreshList = async () => {
    try {
      const data = await getPartners({
        page,
        clubUuid: clubFilter,
        isUsed: filterBy
      })
      if(clubFilter) {
        dispatch(setUsers(data.data));
        setTableData(
          data?.data?.items?.map((user) => ({
            uuid: user.uuid,
            membershipNumber: user.membershipNumber,
            dni: user.dni,
            used: user.used ? "SI" : "NO"
          }))
        );
      }else {
        dispatch(setUsers({
          items: [],
          meta: {
            currentPage: 0,
            itemCount: 0,
            itemsPerPage: 0,
            totalItems: 0,
            totalPages: 0,
          },
        }));
        setTableData([]);
      }
    } catch (error) { 
      dispatch(setUsers({
        items: [],
        meta: {
          currentPage: 0,
          itemCount: 0,
          itemsPerPage: 0,
          totalItems: 0,
          totalPages: 0,
        },
      }));
      setTableData([]);
    }
  };

  useEffect(() => {
    dispatch(setTitle("Partners"));
    getAllClubs()
  }, []);

  useEffect(() => {
    refreshList();
  }, [page, filterBy, clubFilter]);

  const onStatusChange = (e) => {
    setFilterBy(e.target.value)
  }

  const onClubChange = (e) => {
    setClubFilter(e.target.value)
  }

  return (
    <>
      <div className="content">
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button event="SUBIR PARTNERS" action={() => props.history.push("/main/partnersCreate")} secondary/>
        </div>
        <div className="users-filters"> 
            <div className="user-filters-item">
                <label>Filtrar Club</label>
                <SelectUI options={clubs} isLoading={!clubs} onChange={onClubChange} />
            </div>
            <div className="user-filters-item">
                <label>Filtrar Uso</label>
                <SelectUI options={optionsUsed} onChange={onStatusChange} />
            </div>
        </div>
      </div>
        <Table
          fieldAndHeaders={fieldAndHeaders}
          setPage={handlePage}
          totalItems={usersData?.items.meta?.totalItems}
          items={tableData}
          pages={usersData?.items.meta?.totalPage}
          currentPage={usersData?.items.meta?.currentPage}
          currentItems={usersData?.items.meta?.itemsPerPage}
        />
      {!clubFilter && <p className="total">Debes elegir un club en "Filtrar club" </p>}
        <div className="footer">
          <p className="total">Total de partners: {usersData?.items?.meta?.totalItems}</p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={usersData?.items.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
    </>
  );
};

export default Partners;
