import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducers/reducer";
import thunk from "redux-thunk";

const persistConfig = {
  key: "auth",
  storage,
  whitelist: ["auth"],
};

const composeEnhancers = compose || window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const persistedReducer = persistReducer(persistConfig, reducer);

const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };
