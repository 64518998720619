import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import {
  getImages,
  createRaffle,
  editRaffle,
} from "src/services/RaffleService";
import ModalError from "src/ui/modalError/modalError";
import { setTitle } from "../../../store/actions/generalActions";
import "./index.css";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];

const CreateProject = (props) => {
  const raffle = props?.location?.state?.raffle;
  const fromEdit = !!raffle;
  console.log(raffle);
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState(
    fromEdit ? raffle.club.league.uuid : null
  );
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState(fromEdit ? raffle.club.uuid : null);
  const [title, setTitle] = useState(fromEdit ? raffle.title : "");
  const [description, setDescription] = useState(
    fromEdit ? raffle.description : ""
  );
  const [cost, setCost] = useState(0); // useState(fromEdit ? raffle.cost : "");
  const [count, setCount] = useState(fromEdit ? raffle.count : "");
  const [maxParticipant, setMaxParticipant] = useState(
    fromEdit ? raffle.maxParticipant : ""
  );
  const [raffleDate, setRaffleDate] = useState(
    fromEdit ? new Date(raffle.raffleDate) : new Date()
  );
  const [finalizedSubscriptionDate, setFinalizedSubscriptionDate] = useState(
    fromEdit ? new Date(raffle.finalizedSubscriptionDate) : new Date()
  );
  const [secondsDelay, setSecondsDelay] = useState(
    fromEdit ? raffle.secondsDelay : ""
  );
  const [selectedImages, setSelectedImages] = useState([]);
  const [forDeleteImages, setForDeleteImages] = useState([]);
  const [fileBanner, setFileBanner] = useState(
    fromEdit ? { file: null, imageURL: raffle.image } : null
  );

  const fileInputRef = useRef();
  const alert = useAlert();
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} Sorteo`);
  }, []);

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  const getAllImages = async () => {
    if (raffle && raffle.uuid) {
      const images = await getImages(raffle.uuid);
      const data = images.data.map((item) => ({
        file: null,
        uuid: item.uuid ? item.uuid : null,
        imageURL: item.image,
      }));
      setSelectedImages(data);
    }
  };

  useEffect(() => {
    setSubmitted(false);
    getAllImages();
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const onChangeTitle = (e) => {
    setTitle(e.target.value);
    setDirty();
  };

  const onChangeDescription = (e) => {
    setDescription(e.target.value);
    setDirty();
  };

  const onChangeGoal = (e) => {
    if (e.target.value > 99999) return false;
    setCost(e.target.value);
    setDirty();
  };

  const onChangeCount = (e) => {
    if (e.target.value > 99999) return false;
    setCount(e.target.value);
    setDirty();
  };

  const onChangeMaxParticipant = (e) => {
    if (e.target.value > 99999) return false;
    setMaxParticipant(e.target.value);
    setDirty();
  };

  const onChangeSecondsDelay = (e) => {
    if (e.target.value > 99999) return false;
    setSecondsDelay(e.target.value);
    setDirty();
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (index, e) => {
    e.preventDefault();

    let image = selectedImages[index];

    if (image.uuid) setForDeleteImages([...forDeleteImages, image.uuid]);

    selectedImages.splice(index, 1);

    setSelectedImages([...selectedImages]);
  };

  const fileInputClicked = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    if (files) {
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedImages([...selectedImages, image]);
    }
  };

  const onFileBannerChange = (event) => {
    if (event.target.files[0]) {
      const imageURL = URL.createObjectURL(event.target.files[0]);
      const image = { file: event.target.files[0], imageURL };
      setFileBanner(image);
      setDirty();
    }
  };

  const onDeleteImage = (index, e) => {
    e.preventDefault();
    setFileBanner(null);
    document.getElementById("fileId").value = "";
    setDirty();
  };

  const onCreateProject = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("cost", cost);
      formData.append("count", count);
      formData.append("maxParticipant", maxParticipant);
      formData.append("raffleDate", raffleDate);
      formData.append("secondsDelay", secondsDelay);
      formData.append("finalizedSubscriptionDate", finalizedSubscriptionDate);
      formData.append("gmt", getTz());
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });
      fileBanner.file && formData.append("fileBanner", fileBanner.file);
      await createRaffle(formData);
      alert.show("Sorteo creado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/raffles");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const getTz = () => {
    let hora = (new Date().getTimezoneOffset() / 60) * -1;
    let signo = "+";
    if (hora < 0) {
      signo = "-";
    }
    // Añadimos ceros a la izquierda hasta alcanzar una longitud de 3
    hora = Math.abs(hora).toString().padStart(2, "0");
    // Devolvemos la hora en el formato "HH:MM"
    return signo + hora + ":00";
  };

  const onEditProject = async () => {
    setError("");
    try {
      const tz = getTz();
      var formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("cost", cost);
      formData.append("count", count);
      formData.append("maxParticipant", maxParticipant);
      formData.append("raffleDate", raffleDate);
      formData.append("secondsDelay", secondsDelay);
      formData.append("finalizedSubscriptionDate", finalizedSubscriptionDate);
      formData.append("gmt", tz);
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });
      fileBanner.file && formData.append("fileBanner", fileBanner.file);
      formData.append("deleteUuidImage", forDeleteImages);

      await editRaffle(raffle.uuid, formData);
      setPristine();
      alert.show("Sorteo editado con éxito", { type: "success" });
      props.history.push("/main/raffles");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (count <= 0 || secondsDelay <= 0 || maxParticipant <= 0) return false;

    if (!fileBanner) return false;

    return true;
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos sin valor");
      setShowModalError(true);
    } else {
      if (fromEdit) await onEditProject();
      else await onCreateProject();
    }
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            {/* {!fromEdit && ( */}
            <>
              {" "}
              <div className="contain-league">
                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={uuidLeague}
                  onChange={({ target: { value } }) => {
                    setUuidLeague(value);
                    setDirty();
                  }}
                  required
                >
                  <option name={""} value={""}>
                    Elige una liga...
                  </option>

                  {leagues?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidLeague && (
                    <small className="p-invalid transformY-20">
                      La liga es obligatoria.
                    </small>
                  )}
                </div>

                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={uuidClub}
                  onChange={({ target: { value } }) => {
                    setUuidClub(value);
                    setDirty();
                  }}
                  required
                >
                  <option name={""} value={""}>
                    Elige un club...
                  </option>

                  {clubs?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidClub && (
                    <small className="p-invalid transformY-20">
                      El club es obligatorio.
                    </small>
                  )}
                </div>
              </div>{" "}
            </>

            <h2 className="title-banner">Banner</h2>
            <div className="container-banner">
              <div className="container-files">
                {fileBanner && (
                  <div style={{ marginRight: 10 }} key={0}>
                    <button
                      className="delete-banner"
                      onClick={(e) => {
                        onDeleteImage(0, e);
                      }}
                    >
                      X
                    </button>
                    <img src={fileBanner.imageURL} height={200} width={200} />
                  </div>
                )}
              </div>
              <input
                //className="input-add-banner"
                id="fileId"
                className="form-control"
                type="file"
                onChange={onFileBannerChange}
              />
            </div>
            <div>
              {submitted && !fileBanner && (
                <small className="p-invalid transformY-20">
                  El banner es obligatorio.
                </small>
              )}
            </div>

            <hr />

            {/* <div className="image-container">
              <h2 className="title-images">Imágenes</h2>
              <div
                // onDragOver={dragOver}
                // onDragEnter={dragEnter}
                // onDragLeave={dragLeave}
                // onDrop={fileDrop}
                className={!selectedImages.length > 0 ? "drop-container" : ""}
                //onClick={fileInputClicked}
              >
                {selectedImages?.map((item, index) => (
                  <div style={{ marginRight: 10 }} key={index}>
                    <button
                      className="delete-avatar"
                      onClick={(e) => {
                        fileDrop(index, e);
                      }}
                    >
                      X
                    </button>
                    <img src={item.imageURL} height={200} width={200} />
                  </div>
                ))}
              </div>
            </div>

            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
            <div onClick={fileInputClicked}>
              <button>Agregar Imagen</button>
            </div> */}

            <hr />

            <div className="image-container"></div>

            <div className="div-title">
              <div className="container-input-decision">
                <input
                  onChange={onChangeTitle}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Título Sorteo"
                  value={title}
                  required
                  maxLength={255}
                />
              </div>
              <div>
                {submitted && !title && (
                  <small className="p-invalid">El título es obligatorio.</small>
                )}
              </div>
            </div>
            <div>
              <div className="container-text-area-decision">
                <textarea
                  placeholder="Descripción Sorteo"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDescription}
                  value={description}
                  required
                  maxLength={255}
                />
              </div>
              <div>
                {submitted && !description && (
                  <small className="p-invalid">
                    La descripción es obligatoria.
                  </small>
                )}
              </div>
            </div>

            {/* <div className="goal">
              <div className="container-input-decision">
                <input
                  id="cost"
                  onChange={onChangeGoal}
                  name="title"
                  type="number"
                  className="input-title"
                  placeholder="Costo"
                  value={cost}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !cost && (
                  <small className="p-invalid">El costo es obligatorio.</small>
                )}
              </div>
              <div>
                {submitted && cost < 0 && (
                  <small className="p-invalid">
                    El costo no debe ser negativo.
                  </small>
                )}
              </div>
            </div> */}

            <div className="goal">
              <div className="container-input-decision">
                <input
                  onChange={onChangeCount}
                  id="count"
                  name="count"
                  type="number"
                  className="input-title"
                  placeholder="Cantidad de sorteos"
                  value={count}
                  min={1}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  required
                  tooltip="Cantidad de sorteos"
                />
              </div>
              <div>
                {submitted && !count && (
                  <small className="p-invalid">
                    La cantidad de sorteos es obligatoria.
                  </small>
                )}
              </div>
              <div>
                {submitted && count < 0 && (
                  <small className="p-invalid">
                    La cantidad de sorteos debe ser mayor que cero.
                  </small>
                )}
              </div>
            </div>

            <div className="goal">
              <div className="container-input-decision">
                <input
                  onChange={onChangeSecondsDelay}
                  id="secondsDelay"
                  name="secondsDelay"
                  type="number"
                  className="input-title"
                  placeholder="Cantidad de segundos entre un sorteo y otro"
                  value={secondsDelay}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !secondsDelay && (
                  <small className="p-invalid">
                    La cantidad de segundos entre un sorteo y otro es
                    obligatoria.
                  </small>
                )}
              </div>
              <div>
                {submitted && secondsDelay < 0 && (
                  <small className="p-invalid">
                    La cantidad de segundos entre un sorteo y otro debe ser
                    mayor que cero.
                  </small>
                )}
              </div>
            </div>

            <div className="goal">
              <div className="container-input-decision">
                <input
                  onChange={onChangeMaxParticipant}
                  id="maxParticipant"
                  name="maxParticipant"
                  type="number"
                  className="input-title"
                  placeholder="Máximo de participantes"
                  value={maxParticipant}
                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !maxParticipant && (
                  <small className="p-invalid">
                    La cantidad máxima de participantes es obligatoria.
                  </small>
                )}
              </div>
              <div>
                {submitted && maxParticipant < 0 && (
                  <small className="p-invalid">
                    La cantidad máxima de participantes debe ser mayor que cero.
                  </small>
                )}
              </div>
            </div>

            <div className="container-date-picker">
              <div className="date-picker">
                <h4 className="h4-text-decision">
                  Fecha de finalización de participación
                </h4>
                <DatePicker
                  // style={{ backgroundColor: "red", color: "green" }}
                  selected={finalizedSubscriptionDate}
                  onChange={(date) => {
                    setFinalizedSubscriptionDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                />
              </div>

              <div>
                {submitted && !finalizedSubscriptionDate && (
                  <small className="p-invalid transformY-20">
                    La fecha de finalización de participación es obligatoria.
                  </small>
                )}
              </div>
            </div>

            <div className="container-date-picker">
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de sorteo</h4>
                <DatePicker
                  // style={{ backgroundColor: "red", color: "green" }}
                  selected={raffleDate}
                  onChange={(date) => {
                    setRaffleDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy HH:mm"
                  showTimeSelect
                />
              </div>

              <div>
                {submitted && !raffleDate && (
                  <small className="p-invalid transformY-20">
                    La fecha de sorteo es obligatoria.
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            GUARDAR
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="SORTEOS"
            text={`${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateProject);
