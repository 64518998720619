import { api } from "../config/api";

const PAYMENT = "payments/admin";

export const getAllPayments = (
  limit,
  page,
  club,
  email,
  status,
  fechaDesde,
  fechaHasta
) => {
  return api.get(`payments/admin/`, {
    params: {
      limit,
      page,
      club,
      email,
      status,
      fechaDesde,
      fechaHasta,
    },
  });
};

// export const getImages = (uuid) => api.get(`/payments/images/${uuid}`);

// export const createProject = (formData) => api.post("/payments", formData);

// export const deleteProject = (uuid) => api.delete(`payments/${uuid}`);

// export const editProject = (uuid, formData) =>
//   api.put(`payments/${uuid}`, formData);
