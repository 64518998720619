import * as actionTypes from "./types";

export const setUuidDecision = (value) => {
  return {
    type: actionTypes.SET_UUID_DECISION,
    payload: value,
  };
};

export const setLoading = (value) => {
  return {
    type: actionTypes.LOADING,
    payload: value,
  };
};

export const setModalOpen = (state) => {
  return {
    type: actionTypes.MODAL_OPEN,
    payload: state,
  };
};

export const setTooltips = (state) => {
  return {
    type: actionTypes.TOOLTIPS_OPEN,
    payload: state,
  };
};

export const openMenu = () => {
  return {
    type: actionTypes.MENU_OPEN,
  };
};

export const closeMenu = () => {
  return {
    type: actionTypes.CLOSE_MENU,
  };
};
export const setTitle = (state) => {
  return {
    type: actionTypes.TITLE,
    payload: state,
  };
};
export const setError = (values) => {
  return {
    type: actionTypes.ERROR,
    payload: values,
  };
};
export const addClassToTitle = (title) => {
  return {
    type: actionTypes.ADD_CLASS_TO_TITLE,
    payload: title,
  };
};

export const setItemsInPage = (currentItems) => {
  return {
    type: actionTypes.SET_ITEMS_IN_PAGE,
    payload: currentItems,
  };
};
export const setCurrentPage = (current) => {
  return {
    type: actionTypes.SET_CURRENT_PAGE,
    payload: current,
  };
};
