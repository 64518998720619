import { api } from "../config/api";

export const postNewDecision = (formData, uuid) =>
  api.post(`decisions`, formData, {
    headers: { club: uuid },
  });

export const deleteDecision = (uuid) => api.delete(`decisions/${uuid}`);

export const optionVote = (data) => api.post(`decisions/vote`, data);

export const getAllDecisions = (
  limit,
  page,
  club,
  isActive,
  finalized,
  title
) =>
  api.get(`decisions/admin/?title=${title}`, {
    params: {
      limit,
      page,
      club,
      isActive,
      finalized,
    },
  });

export const updateDecision = (uuid, data) =>
  api.put(`decisions/${uuid}`, data);

export const getOptions = (uuid) => api.get(`decisions/admin/${uuid}`);
