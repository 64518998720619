import { api } from "../config/api";

export const getAllCategories = (clubUuid, page, limit) => {
  return api.get(`clubs/categories/`, {
    params: {
      clubUuid,
      page,
      limit,
    },
  });
};

export const editCategory = (clubUuid, jsonData) => {
  return api.put(`clubs/category/upd/${clubUuid}`, jsonData);
};

export const saveCategory = (clubUuid, jsonData) => {
  return api.put(`clubs/category/save/${clubUuid}`, jsonData);
};
