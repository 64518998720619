import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import GoBack from "../../components/goBack/goBack";
import {
  getAvatars,
  editAvatar,
  deleteAvatar,
} from "src/services/AvatarService";
import { useHistory } from "react-router-dom";
import "./updateAvatar.css";
import ModalError from "src/ui/modalError/modalError";
import Button from "src/ui/button/button";
import { useAlert } from "react-alert";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import Modal from "src/ui/modal/modal";
import ReactPaginate from "react-paginate";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const Avatar = ({ item, index, getData, setDirty }) => {
  const alert = useAlert();
  const [isDeleting, setIsDeleting] = useState(false);
  const [editedImage, setEditedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await deleteAvatar(item.uuid);
      await getData();
      setLoading(false);
      alert.show("Avatar Eliminado", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Error", { type: "error" });
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!editedImage) {
      alert.show("Debes seleccionar nuevo archivo para editar el avatar", {
        type: "info",
      });
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", editedImage.file);
      await editAvatar(item.uuid, formData);
      await getData();
      alert.show("Avatar Editado", { type: "success" });
      setEditedImage(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      alert.show("Error", { type: "error" });
    }
  };

  const onDelete = (e) => {
    e.preventDefault();
    setIsDeleting(true);
  };

  const onEdit = (e) => {
    const url = URL.createObjectURL(e.target.files[0]);
    const image = { file: e.target.files[0], url };
    setDirty();
    setEditedImage(image);
  };

  return (
    <div className="avatar-container" key={index}>
      <div className="avatar-container-show">
        <img src={editedImage?.url || item.url} height={230} width={100} />
        <input type="file" onChange={onEdit} className="input-update" />
      </div>
      <div className="avatar-container-id">
        <h3>
          Avatar ID:
          <br /> {item.uuid}
        </h3>
        <div>
          <Button
            event={loading ? "LOADING" : "Editar Avatar"}
            customStyle={{
              color: "black",
              marginRight: "10px",
              minWidth: "100px",
            }}
            action={handleEdit}
          />
          <Button
            event={loading ? "LOADING" : "Eliminar Avatar"}
            isDelete
            action={onDelete}
            customStyle={{ minWidth: "100px" }}
          />
        </div>
      </div>
      {isDeleting && (
        <Modal closeModal={() => setIsDeleting(false)} key="modal">
          <ConfirmModal
            content={"¿Estás seguro que deseas borrar el avatar?"}
            title={"Borrar Avatar"}
            mainButtonText={"Borrar"}
            onCancel={() => setIsDeleting(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
    </div>
  );
};

const UpdateAvatar = (props) => {
  const history = useHistory();
  const [avatars, setAvatars] = useState([]);
  const [modifyAvatar, setModifyAvatar] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    props.setTitle("Avatar page");
  }, []);

  const getData = async () => {
    const response = await getAvatars(10, page);
    setAvatars(response.data.item);
    setMeta(response.data.meta);
  };

  useEffect(() => {
    getData();
  }, [page]);

  const uploadAvatar = (item, index, e) => {
    const imageURL = URL.createObjectURL(e.target.files[0]);
    const image = { file: e.target.files[0], imageURL, uuid: item.uuid };
    setModifyAvatar([...modifyAvatar, image]);
    avatars.map((element, i) => {
      if (i === index) {
        element.url = imageURL;
      }
    });
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const updateAvatar = (e) => {
    e.preventDefault();
    try {
      modifyAvatar.map(async (element) => {
        const uuid = element.uuid;
        const file = element.file;
        var formData = new FormData();
        formData.append("image", file);
        await editAvatar(uuid, formData);
        history.go(0);
      });
    } catch (error) {
      setShowModalError(true);
    }
  };

  return (
    <>
      <form scrolling>
        <fieldset className="fieldset-update-avatar">
          <div className="container-update-avatar">
            <div className="body-update">
              {avatars?.map((item, index) => (
                <Avatar
                  item={item}
                  index={index}
                  key={item.uuid}
                  getData={getData}
                  setDirty={setDirty}
                />
              ))}
            </div>
          </div>
        </fieldset>
      </form>
      <div className="footer">
        <p className="total">Total de avatars: {meta?.totalItems}</p>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePage}
          pageRangeDisplayed={5}
          pageCount={meta?.totalPage}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeLinkClassName="active"
        />
      </div>
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO AVATAR/S"
          text={"editar avatar/s"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
      {Prompt}
    </>
  );
};

export default connect(null, {
  setTitle,
})(UpdateAvatar);
