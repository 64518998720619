export const setToken = (token) => {
  localStorage.setItem("access-Token", token);
};
export const setNameInLS = (name) => {
  localStorage.setItem("name", name);
};

export const existToken = () => {
  return localStorage.getItem("access-Token") !== null;
};

export const getToken = () => {
  return localStorage.getItem("access-Token");
};

export const getNameFromLocalStorage = () => {
  return localStorage.getItem("name");
};

export const deleteToken = () => {
  localStorage.removeItem("access-Token");
  localStorage.removeItem("name");
};
