import { api } from "../config/api";

const ADD_AVATAR = "admin/avatar";
const AVATARS = "public/admin/avatars";

export const getAvatars = (limit, page) =>
  api.get(`${AVATARS}/?limit=${limit}&page=${page}`);
export const getAvatarsByClub = (clubUuid) =>
  api.get(`public/avatarsByClub/${clubUuid}`);
export const addAvatar = (file) => api.post(ADD_AVATAR, file);
export const editAvatar = (uuid, file) => api.put(`admin/avatar/${uuid}`, file);
export const deleteAvatar = (uuid) => api.delete(`admin/avatar/${uuid}`);
