import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {} from "src/services/ClubService";
import { editCategory } from "src/services/CategoriesService";
import ModalError from "src/ui/modalError/modalError";
import { setTitle } from "../../../store/actions/generalActions";
import "./index.css";

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];

const EditCategories = (props) => {
  const category = props?.location?.state?.category;
  const fromEdit = !!category;

  const [clubUuid, setClubUuid] = useState(fromEdit ? category.clubUuid : null);
  const [clubName, setClubName] = useState(fromEdit ? category.clubName : "");
  const [categoryId, setCategoryId] = useState(
    fromEdit ? category.categoryId : ""
  );
  const [categoryName, setCategoryName] = useState(
    fromEdit ? category.categoryName : ""
  );
  const [categoryClub, setCategoryClub] = useState(
    fromEdit ? category.categoryClub : ""
  );
  const [enabled, setEnabled] = useState(fromEdit ? category.enabled : "");

  const [free, setFree] = useState(fromEdit ? category.rol_free : "");
  const [digital, setDigital] = useState(fromEdit ? category.rol_digital : "");
  const [manager, setManager] = useState(fromEdit ? category.rol_manager : "");

  const alert = useAlert();

  const [showModalError, setShowModalError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  useEffect(() => {
    props.setTitle(`Habilitación de Categorías`);
  }, []);

  useEffect(() => {
    setSubmitted(false);
  }, []);

  const onEditCategory = async () => {
    setError("");
    try {
      const jsonData = {
        clubUuid: clubUuid,
        categoryId: categoryId,
        enabled: enabled ? true : false,
        rolFree: free ? true : false,
        rolDigital: digital ? true : false,
        rolManager: manager ? true : false,
      };
      await editCategory(clubUuid, jsonData);

      alert.show("Categoría actualizada con éxito", { type: "success" });
      props.history.push("/main/categories");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);

    await onEditCategory();
  };

  const changeEnabled = () => {
    setEnabled(!enabled);
  };

  const changeFree = () => {
    setFree(!free);
  };

  const changeDigital = () => {
    setDigital(!digital);
  };

  const changeManager = () => {
    setManager(!manager);
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            <div className="div-title" style={{ marginTop: 20 }}>
              <div className="user-info-item ">
                <label>
                  CLUB: {clubName} &nbsp;&nbsp;&nbsp; CATEGORÍA: {categoryName}
                </label>
              </div>
              <hr />
              <div className="lbl-input-check">
                <label>
                  <input
                    type="checkbox"
                    class="input-check"
                    checked={enabled}
                    onChange={changeEnabled}
                  />
                  &nbsp;Enabled
                </label>
              </div>
              <div className="lbl-input-check">
                <label>
                  <input type="checkbox" checked={free} onChange={changeFree} />
                  &nbsp;Free
                </label>
              </div>
              <div className="lbl-input-check">
                <label>
                  <input
                    type="checkbox"
                    class="input-check"
                    checked={digital}
                    onChange={changeDigital}
                  />
                  &nbsp;Digital
                </label>
              </div>
              <div className="lbl-input-check">
                <label>
                  <input
                    type="checkbox"
                    class="input-check"
                    checked={manager}
                    onChange={changeManager}
                  />
                  &nbsp;Manager
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            GUARDAR
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="ERROR ACTUALIZANDO CATEGORÍA"
            text={`Actualizar categoría: ${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(EditCategories);
