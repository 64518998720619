import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle, setUuidDecision } from "../../store/actions/generalActions";
import Modal from "../../ui/modal/modal";
import ModalError from "src/ui/modalError/modalError";
import editar from "../../assets/images/editar.png";
import { useHistory } from "react-router-dom";
import OptionsForm from "src/components/OptionsForm/optionsForm";
import Button from "src/ui/button/button";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import { deleteOption } from "src/services/OptionsService";
import { getOptions } from "src/services/DecisionServices";

const fieldAndHeaders = {
  TÍTULO: "title",
  DESCRIPCIÓN: "description",
  IMAGEN: "image",
  IMAGEN_BANNER: "imageBanner",
  VOTOS: "votes",
};

const OptionsList = (props) => {
  const [modalData, setModalData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [deleteConfirmModalOpen, setDeleteConfirmModalOpen] = useState(false);
  const [showModalError, setShowModalError] = useState(false);
  const [uuid, setUuid] = useState(null);
  const [options, setOptions] = useState([]);
  const optionList = props?.location?.state?.options;
  console.log(props.location.state.decision);
  useEffect(() => {
    props.setTitle("LISTADO DE OPCIONES");
  }, []);

  const closeModal = () => {
    setDeleteConfirmModalOpen(false);
  };

  const handleModalData = (e, itemData) => {
    e.preventDefault();

    props.history.push({
      pathname: "/main/option",
      state: {
        decision: props.location.state.decision,
        option: itemData,
      },
    });
  };

  const handleModalDelete = (option) => {
    setUuid(option.uuid);
    setDeleteConfirmModalOpen(true);
  };

  const handleDelete = async () => {
    const result = tableData.filter((item) => item.uuid !== uuid);
    setTableData(result);
    setDeleteConfirmModalOpen(false);
    await deleteOption(uuid);
  };

  useEffect(() => {
    setTableData(
      options.data?.map((decision) => ({
        decisionUuid: decision.decisionUuid,
        description: decision.description,
        image: decision.image,
        title: decision.title,
        uuid: decision.uuid,
        votes: decision.votes,
        imageBanner: decision.imageBanner,
      }))
    );
  }, [optionList?.length, options]);

  const refreshList = async () => {
    const response = await getOptions(props.location.state.decision.uuid);
    setOptions(response);
  };

  useEffect(() => {
    refreshList();
  }, []);

  return (
    <>
      <div className="content">
        <div className="container-create-users">
          <div className="users-filters-buttons">
            <Button
              event="AGREGAR OPCIONES"
              action={() =>
                props.history.push({
                  pathname: "/main/option",
                  state: { decision: props.location.state.decision },
                })
              }
              secondary
            />
            <Button
              event="EDITAR DECISION"
              secondary
              action={() =>
                props.history.push({
                  pathname: "/main/createDecisions",
                  state: { decision: props.location.state.decision },
                })
              }
            />
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              {Object.keys(fieldAndHeaders).map((header, index) => {
                return <td key={"header" + index}>{header}</td>;
              })}
            </tr>
          </thead>

          <tbody>
            {tableData?.map((item, index) => (
              <tr key={item.uuid}>
                <td style={{ borderWidth: 0 }}>{item.title}</td>
                <td style={{ borderWidth: 0 }}>{item.description}</td>
                <td style={{ borderWidth: 0 }}>
                  <img className="img-clubs" src={item.image} />
                </td>
                <td style={{ borderWidth: 0 }}>
                  <img className="img-clubs" src={item.imageBanner} />
                </td>
                <td style={{ borderWidth: 0 }}>{item.votes}</td>
                <td class="button-actions" style={{ borderWidth: 0 }}>
                  <td style={{ borderWidth: 0 }}>
                    <button
                      className="button fa fa-pencil-square-o"
                      onClick={(e) => {
                        handleModalData(e, item);
                      }}
                      style={{ height: 40 }}
                    ></button>
                  </td>
                  <td style={{ borderWidth: 0 }}>
                    <button
                      className="button error fa fa-trash-o"
                      onClick={(e) => {
                        handleModalDelete(item);
                      }}
                      style={{ height: 40 }}
                    ></button>
                  </td>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <OptionsForm closeModal={closeModal} modalData={modalData} />
        </Modal>
      )} */}
      {deleteConfirmModalOpen && (
        <Modal closeModal={setDeleteConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar la opción?"}
            title={"Borrar opción"}
            mainButtonText={"Borrar"}
            onCancel={() => setDeleteConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO OPCION"
          text="editar esta opción"
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default connect(null, {
  setTitle,
})(OptionsList);
