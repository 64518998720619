import environment from "environment"

const API_URL = environment.API_URL;
// const API_URL = "https://prod-api.mercadolaboral.com.ar"

export const GET_CATEGORIES_URL = `${API_URL}guest/categories`;
export const POST_CATEGORY_URL = `${API_URL}category`;

export const POST_LOGIN_URL = `${API_URL}auth/login`;

export const USERS_URL = `${API_URL}admin/users`;

export const USERS_URL_GET = `${API_URL}admin/users/?limit=${20}`;

export const KEYWORD_URL = `${API_URL}category/keyword`;

export const USERS_CSV_URL = `${API_URL}admin/user/csv`;

export const REFRESH_URL = `${API_URL}auth/refreshToken`;
