import * as actionType from "../actions/types";

const GENERAL_STATE = {
  title: "",
  loading: false,
  modalOpen: false,
  tooltipsOpen: false,
  error: false,
  messageError: "",
  menuOpen: false,
  titleClass: "",
  currentItemsInPage: {
    initial: 0,
    final: 10,
  },
  currentPage: 1,
  uuidDecision: "",
};

export default (state = GENERAL_STATE, action) => {
  switch (action.type) {
    case actionType.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionType.SET_UUID_DECISION:
      return {
        ...state,
        uuidDecision: action.payload,
      };
    case actionType.MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      };
    case actionType.MENU_OPEN:
      return {
        ...state,
        menuOpen: !state.menuOpen,
      };
    case actionType.CLOSE_MENU:
      return {
        ...state,
        menuOpen: false,
      };
    case actionType.TOOLTIPS_OPEN:
      return {
        ...state,
        tooltipsOpen: action.payload,
      };
    case actionType.TITLE:
      return {
        ...state,
        title: action.payload,
      };
    case actionType.ADD_CLASS_TO_TITLE:
      return {
        ...state,
        titleClass: action.payload,
      };
    case actionType.SET_ITEMS_IN_PAGE:
      return {
        ...state,
        currentItemsInPage: action.payload,
      };
    case actionType.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case actionType.ERROR:
      return {
        ...state,
        error: action.payload.error,
        messageError: action.payload.errorMessage,
      };

    default:
      return { ...state };
  }
};
