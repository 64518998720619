import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { useHistory } from "react-router-dom";
import ModalError from "src/ui/modalError/modalError";
import Modal from "../../ui/modal/modal";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import lupa from "../../assets/images/lupa.png";
import editar from "../../assets/images/editar.png";
import "./table.css";

import { getAllPayments } from "src/services/PaymentService";
import { setProjects } from "src/store/actions/projectsActions";
import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import { getClubsAdmin } from "src/services/ClubService";
import Table from "src/components/table/Table";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";

const fieldAndHeaders = {
  CLUB: "club",
  EMAIL: "email",
  NOMBRE: "firstName",
  APELLIDO: "lastName",
  //DNI: "dni",
  MONTO: "amount",
  MONEDA: "currency",
  FECHA: "date",
  STATUS: "status",
  "EMAIL (Big Manager)": "emailBM",
};

const optionsStatus = [
  { value: "SUCCEEDED", label: "Success" },
  { value: "FAILED", label: "Failed" },
];

const tableEnableButtons = {
  edit: true,
  del: true,
};

const Payment = (props) => {
  const dispatch = useDispatch();
  const [payments, setProjects] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [emailFilter, setEmailFilter] = useState(null);
  const [fechaDesdeFilter, setFechaDesdeFilter] = useState(null);
  const [fechaHastaFilter, setFechaHastaFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [page, setPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const searchRef = useRef();
  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  useEffect(() => {
    dispatch(setTitle("Pagos"));
    getAllClubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanFilters = async () => {
    setClubFilter("");
    setStatusFilter("");
    setEmailFilter("");
    setFechaDesdeFilter(null);
    setFechaHastaFilter(null);
    setPage(1);
    await refreshListClear();
  };

  const refreshList = async () => {
    try {
      const data = await getAllPayments(
        20,
        page,
        clubFilter,
        emailFilter,
        statusFilter,
        fechaDesdeFilter,
        fechaHastaFilter
      );
      setProjects(data.data);
      setTableData(
        data?.data?.items?.map((item) => {
          return {
            club: item.user ? item.user?.userClub[0].club.name : " - ",
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            //dni: item.user.dni,
            amount: item.amount,
            currency: item.currency,
            date: item.date,
            status: item.status,
            emailBM: item.user ? item.user?.email : " - ",
            //inforebill: item.firstName + " " + item.lastName,
          };
        })
      );
    } catch (error) {}
  };

  const refreshListClear = async () => {
    try {
      const data = await getAllPayments(20, 1, null, null, null, null, null);
      setProjects(data.data);
      setTableData(
        data?.data?.items?.map((item) => {
          return {
            club: item.user ? item.user?.userClub[0].club.name : " - ",
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            //dni: item.user.dni,
            amount: item.amount,
            date: item.date,
            status: item.status,
            currency: item.currency,
            emailBM: item.user ? item.user?.email : " - ",
            //inforebill: item.firstName + " " + item.lastName,
          };
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page]);

  const onEmailChange = (e) => {
    setEmailFilter(e.target.value);
  };

  const onFechaDesdeChange = (e) => {
    setFechaDesdeFilter(e.target.value);
  };

  const onFechaHastaChange = (e) => {
    setFechaHastaFilter(e.target.value);
  };

  const onStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters">
          <div className="user-filters-item">
            <label>Filtrar Club</label>
            <SelectUI
              options={clubs}
              isLoading={!clubs}
              onChange={onClubChange}
              value={clubFilter}
            />
          </div>

          <div className="user-filters-item">
            <label>Filtrar Email</label>
            <input
              id="user-filter-input"
              placeholder="filtro por email"
              className="user-filter-input"
              onChange={onEmailChange}
              value={emailFilter}
            />
          </div>

          <div className="user-filters-item">
            <label>Filtrar Estado</label>
            <SelectUI
              options={optionsStatus}
              onChange={onStatusChange}
              value={statusFilter}
            />
          </div>

          <div className="user-filters-item">
            <label>Filtrar Desde</label>
            <DatePicker
              selected={fechaDesdeFilter}
              onChange={(date) => setFechaDesdeFilter(date)}
              dateFormat="dd/MM/yyyy"
              value={fechaDesdeFilter}
              className="custom-select-ui"
            />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Hasta</label>
            <DatePicker
              selected={fechaHastaFilter}
              onChange={(date) => setFechaHastaFilter(date)}
              dateFormat="dd/MM/yyyy"
              value={fechaHastaFilter}
              className="custom-select-ui"
            />
          </div>

          <div className="user-filters-item">
            <button
              className="button"
              onClick={() => refreshList()}
              style={{ marginLeft: "10px", marginTop: "30px" }}
            >
              buscar
            </button>
          </div>
          <div className="user-filters-item" style={{ maxHeight: "36px" }}>
            <button
              className="button"
              onClick={() => cleanFilters()}
              style={{ marginLeft: "10px", marginTop: "30px" }}
            >
              limpiar
            </button>
          </div>
        </div>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={fieldAndHeaders}
          setPage={() => {}}
          totalItems={payments?.meta?.totalItems}
          items={tableData}
          pages={payments?.meta?.totalPage}
          currentPage={payments?.meta?.currentPage}
          currentItems={payments?.meta?.itemsPerPage}
          // triggerRow={handleActionClick}
          // enableButtons={tableEnableButtons}
        />
        <div className="footer">
          pago
          <p className="total">Total de pagos: {payments?.meta?.totalItems}</p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={payments?.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar el pago?"}
            title={"Borrar pago"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            //onAccept={handleDelete}
          />
        </Modal>
      )}
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO PAGO"
          text={"Editar el estado del pago"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default Payment;
