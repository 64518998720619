import React from "react";

const title = (props) => {
  return (
    <div className={`title ${props.className}`}>
      <h2>{props.title}</h2>
    </div>
  );
};

export default title;
