import React from "react";
import ContentAside from "./contentaside";

import "./aside.css";
import "./../../../ui/option-list/option.css";

const Aside = (props) => {
  return (
    <React.Fragment>
      {props.responsive ? (
        <ContentAside
          responsive={props.responsive}
          classes="aside-container mobile"
        />
      ) : (
        <ContentAside classes="aside-container" />
      )}
    </React.Fragment>
  );
};

export default Aside;
