import React from "react";
import Lottie from "react-lottie";

import successData from "../../assets/loading-lottie.json";
import "./button.css";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Button = (props) => {
  let state = "";
  let classN = props.variant;

  switch (props.event) {
    case "LOADING":
      state = (
        <Lottie
          options={{ animationData: successData, ...defaultOptions }}
          style={{
            marginTop: "0px",
            marginBottom: "0px",
            paddingTop: "0px",
            paddinBottom: "0px",
          }}
          height={25}
          width={25}
        />
      );
      break;
    case "SUCCESS":
      state = <i className="fa fa-check"></i>;
      break;
    case "ERROR":
      state = <i className="fa fa-exclamation-circle"></i>;
      break;
    case "EDIT":
      state = <i className="fa fa-pencil-square-o"></i>;
      break;
    case "DELETE":
      state = <i className="fa fa-trash-o"></i>;
      break;
    case "MAIL":
      state = <i className="fa fa-envelope-o"></i>;
      break;
    case "WIN":
      state = <i className="fa fa-smile-o"></i>;
      break;
    case "MON":
      state = <i className="fa fa-money"></i>;
      break;
    case "SET":
      state = <i className="fa fa-gears"></i>;
      break;
    default:
      state = props.event;
      break;
  }

  return (
    <button
      style={props.customStyle}
      className={`button ${classN} ${props.isBig && "big-button"} ${
        props.isDelete && "delete-button"
      } ${props.secondary && "button-secondary"}`}
      type={props.type}
      onClick={
        props.type !== "submit" ? (e) => props.action && props.action(e) : null
      }
      disabled={props.loading || props.disabled}
    >
      {state}
    </button>
  );
};

export default Button;
