import * as actionType from "./../actions/types";
import { getToken } from "../../utils/tokenService";

const INITIAL_STATE = {
  location: "",
  name: "",
  //   role: getRole(),
  loadingAuth: false,
  loginError: false,
  loginSuccess: false,
  isAuthenticate: getToken(),
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.LOGIN_START:
      return {
        ...state,
        loadingAuth: action.payload,
      };
    case actionType.LOGIN_FAIL:
      console.log("test")
      return {
        ...state,
        loginError: action.payload,
      };
    case actionType.ISAUTH:
      return {
        ...state,
        isAuthenticate: action.payload,
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: action.payload,
      };
    case actionType.LOGIN_END:
      return {
        ...state,
        isAuthenticate: action.payload,
      };
    case actionType.USER_SET_NAME:
      return {
        ...state,
        name: action.payload,
      };
    case actionType.LOGIN_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return { ...state };
  }
};
