import React from "react";
import { connect } from "react-redux";

import Error from "../../../containers/error/error";
import Loading from "./../../../ui/loading/loading";

import Title from "../../title/title";
import "./main.css";

const main = (props) => {
  return (
    <main className="main-container">
      <Title title={props.title} className={props.titleClass} />
      {props.error ? <Error /> : <> {props.children}</>}
      {props.loading ? <Loading /> : null}
    </main>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.general.error,
    title: state.general.title,
    titleClass: state.general.titleClass,
    loading: state.general.loading,
    loginerror: state.register.loginError,
  };
};

export default connect(mapStateToProps)(main);
