import { api } from "src/config/api";

export const getAllPost = (limit, page, club, important) =>
  api.get("community/admin/post", {
    params: {
      limit,
      page,
      club,
      important,
    },
  });

export const createClubPost = (clubUuid, data) => {
  console.log("createclubpost");
  api.post(`community/club/${clubUuid}/post`, data);
};

export const editClubPost = (postUuid, data) => {
  console.log("editClubPost");
  return api.put(`community/post/${postUuid}`, data);
};

export const editImportantPost = (postUuid, important) =>
  api.put(`community/post/important/${postUuid}`, { important });

export const deletePost = (postUuid) =>
  api.delete(`community/admin/post/${postUuid}`);
