import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import ModalError from "src/ui/modalError/modalError";
import "./table.css";
import { saveCategory } from "src/services/CategoriesService";
import { useAlert } from "react-alert";
import { getAllCategories } from "src/services/CategoriesService";

const TableLine = (item) => {
  const [clubUuid, setClubUuid] = useState(item.item.clubUuid);
  const [categoryId] = useState(item.item.categoryId);
  const [enabled, setEnabled] = useState(item.item.enabled);
  const [free, setFree] = useState(item.item.free);
  const [digital, setDigital] = useState(item.item.digital);
  const [manager, setManager] = useState(item.item.manager);
  const [error, setError] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const alert = useAlert();

  const changeEnabled = () => {
    setEnabled(!enabled);
    item.updateItem({
      categoryId,
      att: "enabled",
      value: !enabled,
    });
  };
  const changeFree = () => {
    setFree(!free);
    item.updateItem({
      categoryId,
      att: "free",
      value: !free,
    });
  };
  const changeDigital = () => {
    setDigital(!digital);
    item.updateItem({
      categoryId,
      att: "digital",
      value: !digital,
    });
  };
  const changeManager = () => {
    setManager(!manager);
    item.updateItem({
      categoryId,
      att: "manager",
      value: !manager,
    });
  };

  return (
    <>
      <tr>
        <td style={{ borderWidth: 1, borderColor: "rgb(98, 98, 98)" }}>
          {item.item.category}
        </td>
        <td style={{ borderWidth: 1, borderColor: "rgb(98, 98, 98)" }}>
          {item.item.enabled}
          <div className="lbl-input-check">
            <input
              type="checkbox"
              class="input-check"
              checked={enabled}
              onChange={changeEnabled}
            />
          </div>
        </td>
        <td style={{ borderWidth: 1, borderColor: "rgb(98, 98, 98)" }}>
          {item.item.free}
          <div className="lbl-input-check">
            <input
              type="checkbox"
              class="input-check"
              checked={free}
              onChange={changeFree}
            />
          </div>
        </td>
        <td style={{ borderWidth: 1, borderColor: "rgb(98, 98, 98)" }}>
          {item.item.digital}
          <div className="lbl-input-check">
            <input
              type="checkbox"
              class="input-check"
              checked={digital}
              onChange={changeDigital}
            />
          </div>
        </td>
        <td style={{ borderWidth: 1, borderColor: "rgb(98, 98, 98)" }}>
          {item.item.manager}
          <div className="lbl-input-check">
            <input
              type="checkbox"
              class="input-check"
              checked={manager}
              onChange={changeManager}
            />
          </div>
        </td>
      </tr>
      {showModalError && (
        <ModalError
          title="ERROR ACTUALIZANDO CATEGORÍA"
          text={`Actualizar categoría: ${error}`}
          closeModal={(e) => {
            setShowModalError(e);
          }}
          key="modal"
        />
      )}
    </>
  );
};

const Categories = (props) => {
  const dispatch = useDispatch();
  const [categories, setCategories] = useState(null);
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(props.location.state.clubUuid);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [error, setError] = useState(null);

  const [page, setPage] = useState(1);

  const alert = useAlert();

  const searchRef = useRef();
  const items = useRef([]);

  useEffect(() => {
    dispatch(setTitle("Categorías"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshList = async () => {
    try {
      const data = await getAllCategories(
        props.location.state.clubUuid,
        page,
        20
      );

      setClubFilter(props.location.state.clubUuid);
      const cats = data?.data?.items.map((item) => ({
        clubUuid: item.clubUuid,
        categoryId: item.categoryId,
        club: item.clubName,
        category: item.categoryName,
        enabled: item.enabled === 1 ? true : false,
        free: item.rol_free === 1 ? true : false,
        digital: item.rol_digital === 1 ? true : false,
        manager: item.rol_manager === 1 ? true : false,
      }));
      setCategories(cats);

      items.current = [];
      cats.map((item, index) => {
        items.current.push(item);
      });
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page, clubFilter]);

  let bodyData;

  const updateItem = (updated) => {
    const item = items.current.find((i) => i.categoryId === updated.categoryId);
    item[updated.att] = updated.value;
    console.log(items);
  };

  if (categories != null) {
    bodyData = categories.map((item, index) => {
      const r = <TableLine item={item} updateItem={updateItem}></TableLine>;
      return r;
    });
  }

  const onSaveCategory = async () => {
    setError("");
    try {
      await saveCategory(props.location.state.clubUuid, items.current);

      alert.show("Categoría actualizada con éxito", { type: "success" });
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    await onSaveCategory();
  };

  return (
    <>
      <div className="container-create-users"></div>
      <div className="user-info-item ">
        <label>CLUB: {props.location.state.name}</label>
      </div>

      <div className="content">
        <table className="table">
          <thead>
            <tr>
              <td>CATEGORÍA</td>
              <td>ENABLED</td>
              <td>FREE</td>
              <td>DIGITAL</td>
              <td>MANAGER</td>
            </tr>
          </thead>
          <tbody>{bodyData}</tbody>
        </table>
      </div>

      <div
        className="footer"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <button
          disabled={disabledSaveButton}
          className="btn-create-club"
          onClick={(e) => {
            handleClick(e);
          }}
        >
          GUARDAR
        </button>
      </div>

      {showModalError && (
        <ModalError
          title="ERROR EDITANDO CATEGORÍAS"
          text={"Habilitar perfiles de la categoría"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default Categories;
