import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "./App.css";
import ErrorPage from "./containers/error/error";
import Login from "./components/login/Login";
import Layout from "./containers/layout/Layout";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertTemplate from "./components/AlertTemplate";

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: "30px",
  transition: transitions.SCALE,
};

const App = (props) => {
  let redirectToMain = (props) => {
    const isLoggedIn = localStorage.getItem("access-Token");
    if (isLoggedIn) props.history.push("/main");
    else props.history.push("/login");
  };

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" render={(props) => redirectToMain(props)} />
          <Route path="/main/" component={Layout} />
          <Route path="/login" component={Login} />
          <Route component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    </AlertProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticate: state.register.isAuthenticate,
  };
};

export default connect(mapStateToProps)(App);
