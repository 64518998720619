import React from "react";

import "./input.css";


const input = React.forwardRef((props, ref) => {
  return (
    <input
      className={`input-login ${props.isForm && "input-form"}`}
      name={props.name}
      type={props.type}
      placeholder={props.placeholder}
      value={props.val}
      onChange={(e) => props.action(e)}
      disabled={props.disabled}
      id={props.name}
      {...props}
      ref={ref}
    />
  );
})

export default input;
