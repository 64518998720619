import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import ModalError from "src/ui/modalError/modalError";
import "./table.css";

import { getInvestment } from "src/services/InvestmentService";
import Button from "src/ui/button/button";
import Table from "src/components/table/Table";
import ReactPaginate from "react-paginate";
import SelectUI from "src/ui/selectUI";

const fieldAndHeaders = {
  NOMBRE: "nombre",
  EMAIL: "email",
};

const fieldAndHeadersInversion = {
  NOMBRE: "nombre",
  EMAIL: "email",
  INVERSIÓN: "monto",
};

const optionsOrderBy = [
  { value: "nombre", label: "NOMBRE" },
  { value: "monto", label: "INVERSIÓN" },
];

const Investment = (props) => {
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [page, setPage] = useState(1);
  const [searchByTitle, setSearchByTitle] = useState("");
  const [orderBy, setOrderBy] = useState("monto");
  const [aplicaInversion, setAplicaInversion] = useState(
    props.location.state.entity === "PROJECT"
  );

  const searchRef = useRef();

  useEffect(() => {
    if (aplicaInversion) dispatch(setTitle("Inversiones"));
    else dispatch(setTitle("Participaciones"));
  }, []);

  const refreshList = async () => {
    try {
      const data = await getInvestment(
        props.location.state.entity,
        props.location.state.uuid,
        searchByTitle,
        aplicaInversion ? orderBy : "nombre",
        20,
        page
      );

      setData(data);

      setTableData(
        data?.data?.items.map((item) => ({
          nombre: item.lastName + ", " + item.firstName,
          //apellido: item.lastName,
          email: item.email,
          monto: item.monto,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page, searchByTitle, orderBy]);

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const descEntity = () => {
    if (props.location.state.entity === "PROJECT") return "Proyecto: ";
    if (props.location.state.entity === "DECISION") return "Decisión: ";
    if (props.location.state.entity === "EXPERIENCE") return "Experiencia: ";
  };

  const onOrderByChange = (e) => {
    setOrderBy(e.target.value);
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <div className="user-filters-item">
            <label>
              {descEntity()}
              {props.location.state.name}
            </label>
          </div>
        </div>
      </div>
      <div className="container-create-users">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchByTitle(searchRef.current.value);
          }}
        >
          <div className="users-filters">
            <div className="user-filters-item">
              <label>Filtrar por email</label>
              <div className="filter-input-button">
                <input
                  id="user-filter-input"
                  placeholder="filtro por email"
                  className="user-filter-input"
                  ref={searchRef}
                />
                <div className="buttons-filter" style={{ maxHeight: "36px" }}>
                  <Button event="buscar" secondary value="search" />
                  <button
                    className="button"
                    onClick={() => {
                      document.getElementById("user-filter-input").value = "";
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    limpiar
                  </button>
                </div>
              </div>
            </div>
            {aplicaInversion && (
              <div className="user-filters-item">
                <label>Ordenar por</label>
                <select
                  {...props}
                  className="custom-select-ui"
                  onChange={onOrderByChange}
                  value={orderBy}
                >
                  {optionsOrderBy?.map((item, index) => (
                    <option
                      key={item?.key || `${item.value}-${index}`}
                      label={item.label}
                    >
                      {item.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </form>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={
            aplicaInversion ? fieldAndHeadersInversion : fieldAndHeaders
          }
          setPage={() => {}}
          totalItems={data?.data?.meta?.totalItems}
          items={tableData}
          pages={data?.data?.meta?.totalPage}
          currentPage={data?.data?.meta?.currentPage}
          currentItems={data?.data?.meta?.itemsPerPage}
        />
        <div className="footer">
          <p className="total">
            Total de usuarios: {data?.data?.meta?.totalItems}
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={data?.data?.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO PROYECTO"
          text={"Editar el estado del proyecto"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default Investment;
