import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import GoBack from "../../components/goBack/goBack";
import {
  editCurrentClub,
  getClubDataById,
  getClubsAdminByLeague,
} from "src/services/ClubService";
import { HexColorPicker } from "react-colorful";
import ModalError from "src/ui/modalError/modalError";
import { useAlert } from "react-alert";
import "./editClub.css";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const EditClub = (props) => {
  // const [showEdit, setShowEdit] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [clubsNames, setClubsNames] = useState([]);
  const [colorPrimary, setColorPrimary] = useState("");
  const [colorSecondary, setColorSecondary] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [data, setData] = useState({
    clubUuid: "",
    nameClub: "",
    uuidLeague: "",
    logoClub: "",
    logoNav: "",
    flagClub: "",
    backgroundClub: "",
  });
  const alert = useAlert();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  console.log("props", props.location.state.data);

  const getAllClubs = async () => {
    const response = await getClubsAdminByLeague(data.uuidLeague);
    setClubsNames(
      response.data.items.map((club) => club.name.trim().toLowerCase())
    );
  };
  useEffect(() => {
    setData({
      ...data,
      clubUuid: props.location.state.data.uuid,
      uuidLeague: props.location.state.data.uuidLeague,
    });
  }, []);

  useEffect(() => {
    props.setTitle("Editar Club");
    getAllClubs();
  }, []);

  const getClub = () => {
    const data = props.location.state.data;
    setData({
      ...data,
      nameClub: data.name,
      logoClub: data.logoClub,
      logoNav: data.logoNav,
      flagClub: data.flagClub,
      backgroundClub: data.backgroundClub,
    });
    setColorPrimary(data.colorPrimary);
    setColorSecondary(data.colorSecondary);
  };

  useEffect(() => {
    getClub();
  }, []);

  const onLogoChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, logoClub: image });
  };

  const onNavChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, logoNav: image });
  };

  const onFlagChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, flagClub: image });
  };

  const onBackgroundChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setData({ ...data, backgroundClub: image });
  };

  const onEditClub = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!validateRequired()) {
      setErrorModalText("Existen campos requeridos sin dato");
      setShowModalError(true);
      return;
    }
    try {
      var formData = new FormData();
      formData.append("name", data.nameClub);
      formData.append("colorPrimary", colorPrimary);
      formData.append("colorSecondary", colorSecondary);
      formData.append("league", data.league.uuid);
      formData.append("logoClub", data.logoClub.file);
      formData.append("logoNav", data.logoNav.file);
      formData.append("flagClub", data.flagClub.file);
      formData.append("backgroundClub", data.backgroundClub.file);
      await editCurrentClub(formData, data.uuid);
      alert.show("Club editado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/clubs");
    } catch (error) {
      alert.show("Error al editar club", { type: "error" });
    }
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (
      clubsNames.includes(data.nameClub.trim().toLowerCase()) &&
      data.nameClub !== props.location.state.data.name
    )
      return false;

    if (!colorPrimary || !colorSecondary) return false;
    return true;
  };

  const onChangeColorPrimary = (color) => {
    setDirty();
    setColorPrimary(color);
  };

  const onChangeColorSecondary = (color) => {
    setDirty();
    setColorSecondary(color);
  };

  return (
    <div style={{ overflowY: "auto" }}>
      <fieldset>
        <div className="btn-div-save">
          <button
            className="btn-save-club"
            onClick={(e) => {
              onEditClub(e);
            }}
          >
            Guardar cambios
          </button>
        </div>

        <div className="container-edit">
          <form>
            <div className="container-edit-club">
              <div>
                <div className="name-input">
                  <input
                    autoFocus
                    type="text"
                    placeholder="Nombre del club"
                    className="form-control-edit-club"
                    value={data.nameClub}
                    onChange={(e) => {
                      setDirty();
                      setData({ ...data, nameClub: e.target.value });
                    }}
                    name="nombre"
                    style={{ marginLeft: 8 }}
                    required
                  ></input>{" "}
                </div>
                {submitted &&
                  clubsNames.includes(data.nameClub.trim().toLowerCase()) &&
                  data.nameClub !== props.location.state.data.name && (
                    <small className="p-invalid transformY-20">
                      Ya existe un club con este nombre.
                    </small>
                  )}

                <div className="logo-contain">
                  <p className="title">Logo del club</p>
                  <img
                    className="image"
                    src={
                      data.logoClub.imageURL
                        ? data.logoClub.imageURL
                        : data.logoClub
                    }
                  />
                  <input
                    style={{ width: "100%" }}
                    type="file"
                    placeholder="logoClub"
                    className="form-control"
                    onChange={(e) => {
                      onLogoChange(e);
                    }}
                    name="logoClub"
                  ></input>
                </div>

                <div className="logo-contain">
                  <p className="title">Logo navegación</p>
                  <img
                    className="image"
                    src={
                      data.logoNav.imageURL
                        ? data.logoNav.imageURL
                        : data.logoNav
                    }
                  />
                  <input
                    style={{ width: "100%" }}
                    type="file"
                    placeholder="logoNav"
                    className="form-control"
                    onChange={(e) => {
                      onNavChange(e);
                    }}
                    name="logoNav"
                  ></input>
                </div>

                <div className="logo-contain">
                  <p className="title">Bandera del club</p>
                  <img
                    className="image"
                    src={
                      data.flagClub.imageURL
                        ? data.flagClub.imageURL
                        : data.flagClub
                    }
                  />
                  <input
                    style={{ width: "100%" }}
                    type="file"
                    placeholder="flagClub"
                    className="form-control"
                    onChange={(e) => {
                      onFlagChange(e);
                    }}
                    name="flagClub"
                  ></input>
                </div>

                <div className="logo-contain">
                  <p>imagen de fondo</p>
                  <img
                    className="image"
                    src={
                      data.backgroundClub.imageURL
                        ? data.backgroundClub.imageURL
                        : data.backgroundClub
                    }
                  />

                  <input
                    style={{ width: "100%" }}
                    type="file"
                    placeholder="backgroundClub"
                    className="form-control"
                    onChange={(e) => {
                      onBackgroundChange(e);
                    }}
                    name="backgroundClub"
                  ></input>
                </div>
              </div>
              <div>
                <div>
                  <p className="p-hex">Elige el color primario</p>
                  <HexColorPicker
                    style={{ marginBottom: 20 }}
                    color={colorPrimary}
                    onChange={onChangeColorPrimary}
                  />
                </div>

                <div>
                  <p className="p-hex">Elige el color secundario</p>
                  <HexColorPicker
                    style={{ marginBottom: 20 }}
                    color={colorSecondary}
                    onChange={onChangeColorSecondary}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {showModalError && (
          <ModalError
            title="ERROR EDITANDO CLUB"
            text={errorModalText}
            closeModal={setShowModalError}
            key="modal"
          />
        )}
        {Prompt}
      </fieldset>
    </div>
  );
};

export default connect(null, {
  setTitle,
})(EditClub);
