import React from "react";
import Button from "../../ui/button/button";

import "./contacto.css";

const contact = (props) => {
  return (
    <div className="contact-form-container">
      <h3>¿Cómo podemos ayudarte?</h3>
      <textarea
        name=""
        id=""
        placeholder="Escribí tu consulta acá"
        cols="60"
        rows="14"></textarea>
      <Button event={"Enviar"} type="submit"></Button>
    </div>
  );
};

export default contact;
