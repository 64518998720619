import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { useHistory } from "react-router-dom";
import ModalError from "src/ui/modalError/modalError";
import "./table.css";

import {
  editCurrentClub,
  getClubsAdmin,
  getLeaguesAdmin,
} from "src/services/ClubService";
import { setClubs } from "src/store/actions/clubsActions";
import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import ReactPaginate from "react-paginate";

const fieldAndHeaders = {
  NOMBRE: "name",
  LOGO: "logo",
  ESTADO: "Status",
  EDITAR: "Editar",
};

const statusOptions = [
  { label: "Habilitado", value: "true" },
  { label: "Deshabilitado", value: "false" },
];

const Clubs = () => {
  const dispatch = useDispatch();
  const clubs = useSelector((state) => state.clubs);
  const [showModalError, setShowModalError] = useState(false);
  const [page, setPage] = useState(1);
  const [leagueFilter, setLeagueFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState(true);
  const [leagues, setLeagues] = useState(null);

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const onLeagueChange = (e) => {
    setLeagueFilter(e.target.value);
  };

  const onStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    const filtered = data.data.items
      .filter((item) => item.isActive)
      .map((it) => ({ label: it.name, value: it.uuid }));
    setLeagues(filtered);
  };

  useEffect(() => {
    dispatch(setTitle("Clubes"));
    getAllLeagues();
  }, []);

  const refreshList = async () => {
    try {
      const data = await getClubsAdmin(20, page, leagueFilter, statusFilter);
      let storeData = data.data;
      dispatch(setClubs(storeData));
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page, statusFilter, leagueFilter]);

  const onEditClub = async (e, item) => {
    e.preventDefault();
    const boolean = item.isActive ? false : true;
    try {
      await editCurrentClub({ isActive: boolean }, item.uuid);
      refreshList();
    } catch (error) {
      setShowModalError(true);
    }
  };

  const history = useHistory();

  const dataToMap = clubs?.items?.items?.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });

  return (
    <>
      <div className="content">
        <div className="container-create-users">
          <div className="users-filters-buttons">
            <Button
              event="CREAR CLUB"
              action={() => history.push("/main/createClub")}
              secondary
            />
          </div>
          <div className="users-filters">
            <div className="user-filters-item">
              <label>Filtrar Liga</label>
              <SelectUI
                options={leagues}
                isLoading={!leagues}
                onChange={onLeagueChange}
              />
            </div>
            <div className="user-filters-item">
              <label>Filtrar Estado</label>
              <SelectUI options={statusOptions} onChange={onStatusChange} />
            </div>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              {Object.keys(fieldAndHeaders).map((header, index) => {
                return <td key={"header" + index}>{header}</td>;
              })}
            </tr>
          </thead>

          <tbody>
            {dataToMap?.map((item, index) => (
              <tr key={item.id}>
                <td style={{ borderWidth: 0 }}>{item.name}</td>
                <td style={{ borderWidth: 0 }}>
                  <img className="img-clubs" src={item.logoClub} />
                </td>
                <td style={{ borderWidth: 0 }}>
                  <button
                    className="btn-edit-club"
                    onClick={(e) => {
                      onEditClub(e, item, index);
                    }}
                  >
                    {item.isActive ? "Habilitado" : "Deshabilitado"}
                  </button>
                </td>

                <td style={{ borderWidth: 0, display: "flex", gap: 5 }}>
                  <Button
                    event="EDIT"
                    action={() => {
                      history.push({
                        pathname: "/main/editClub",
                        state: { data: item },
                      });
                    }}
                  />
                  <Button
                    event="SET"
                    action={() => {
                      history.push({
                        pathname: "/main/categories",
                        state: { clubUuid: item.uuid, name: item.name },
                      });
                    }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="footer">
          <p className="total">
            Total de clubes: {clubs?.items?.meta?.totalItems}
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={clubs?.items.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO CLUB"
          text={"Error al deshabilitar el club"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default Clubs;
