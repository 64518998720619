import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import ModalError from "src/ui/modalError/modalError";
import Modal from "../../ui/modal/modal";
import "./table.css";

import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import { getClubsAdmin } from "src/services/ClubService";
import Table from "src/components/table/Table";
import ReactPaginate from "react-paginate";
import { formatDate } from "src/utils/formatDate";
import {
  editImportantPost,
  getAllPost,
  deletePost,
} from "src/services/CommunityService";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import { useAlert } from "react-alert";

const fieldAndHeaders = {
  AUTOR: "author",
  DESCRIPCION: "description",
  FECHA: "date",
};

const optionsImportant = [
  { value: "true", label: "Destacado" },
  { value: "false", label: "No Destacado" },
];

const Projects = (props) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [important, setImportantFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const alert = useAlert();

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  const tableEnableButtons = {
    edit: true,
    del: true,
  };

  useEffect(() => {
    dispatch(setTitle("Comunidad"));
    getAllClubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshList = async () => {
    try {
      const data = await getAllPost(20, page, clubFilter, important);
      setProjects(data.data);
      const tabled = data?.data?.items?.map((item) => ({
        author: item.authorName,
        description: item.description,
        id: item.uuid,
        commentsCount: item.commentsCount,
        date: formatDate(item.date),
        likesCount: item.likesCount,
        important: item.important ? "Si" : "No",
        importantBool: item.important,
        authorType: item.authorType,
      }));
      setTableData(tabled);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    refreshList();
  }, [page, clubFilter, important]);

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  const onImportantChange = (e) => {
    setImportantFilter(e.target.value);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const handleActionClick = (id, option, item) => {
    if (option === "edit") {
      const community = projects.items.find((it) => it.uuid === item.id);
      props.history.push({
        pathname: "/main/community/edit",
        state: { community },
      });
    } else {
      if (option === "del") {
        setIdToDelete(id);
        setConfirmModalOpen(true);
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deletePost(idToDelete);
      const result = tableData.filter((item) => item.id !== idToDelete);
      setTableData(result);
      setConfirmModalOpen(false);
    } catch (error) {
      setConfirmModalOpen(false);
      setShowModalError(true);
    }
  };

  const handleEdit = async (item) => {
    try {
      setLoading(true);
      await editImportantPost(item.id, !item.importantBool);
      await refreshList();
      setLoading(false);
      alert.show("Post editado con exito", { type: "success" });
    } catch (error) {
      setLoading(false);
      alert.show("Error al editar post", { type: "error" });
    }
  };

  const ButtonRender = (item) => {
    return (
      <Button
        event={loading ? "LOADING" : item.important}
        secondary
        action={() => handleEdit(item)}
        disabled={loading}
      />
    );
  };

  const disabledAction1 = (item) => {
    return item.authorType != "CLUB";
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button
            event="CREAR COMUNIDAD"
            action={() => props.history.push("/main/community/create")}
            secondary
          />
        </div>
        <div className="users-filters">
          <div className="user-filters-item">
            <label>Filtrar Club</label>
            <SelectUI
              options={clubs}
              isLoading={!clubs}
              onChange={onClubChange}
            />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Destacado</label>
            <SelectUI options={optionsImportant} onChange={onImportantChange} />
          </div>
        </div>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={fieldAndHeaders}
          setPage={() => {}}
          totalItems={projects?.meta?.totalItems}
          items={tableData}
          pages={projects?.meta?.totalPage}
          currentPage={projects?.meta?.currentPage}
          currentItems={projects?.meta?.itemsPerPage}
          triggerRow={handleActionClick}
          ButtonColumn={ButtonRender}
          ButtonColumnClass={"button-classNull"}
          enableButtons={tableEnableButtons}
          disabledEdit={disabledAction1}
        />
        <div className="footer">
          <p className="total">
            Total de proyectos: {projects?.meta?.totalItems}
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={projects?.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO PROYECTO"
          text={"Editar el estado del proyecto"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}

      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar el posteo?"}
            title={"Borrar Posteo"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
    </>
  );
};

export default Projects;
