import { LOGIN, LOGOUT } from "./types";

export const setUser = (data) => ({
  type: LOGIN,
  payload: data,
});

export const logout = () => ({
  type: LOGOUT,
});
