import React from "react";
import { NavLink } from "react-router-dom";
import "./option.css";

const option = (props) => {
  return (
    <li className={`liStyle ${props.one ? "one" : ""}`}>
      <NavLink exact to={props.href}>
        <img
          src={props.icon}
          height={15}
          width={15}
          style={{ marginRight: 10 }}
        />
        {props.name}
      </NavLink>
    </li>
  );
};

export default option;
