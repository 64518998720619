import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import {
  createProject,
  editProject,
  getImages,
} from "src/services/ProjectService";
import ModalError from "src/ui/modalError/modalError";
import { setTitle } from "../../../store/actions/generalActions";
import "./index.css";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];

const CreateProject = (props) => {
  const project = props?.location?.state?.project;
  const fromEdit = !!project;
  console.log(project);
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState(
    fromEdit ? project.club.league.uuid : null
  );
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState(fromEdit ? project.club.uuid : null);
  const [title, setTitle] = useState(fromEdit ? project.title : "");
  const [description, setDescription] = useState(
    fromEdit ? project.description : ""
  );
  const [goal, setGoal] = useState(fromEdit ? project.goal : "");
  const [startDate, setStartDate] = useState(
    fromEdit ? new Date(project.startDate) : new Date()
  );
  const [finishDate, setFinishDate] = useState(
    fromEdit ? new Date(project.finishDate) : new Date()
  );
  const [selectedImages, setSelectedImages] = useState([]);
  const [forDeleteImages, setForDeleteImages] = useState([]);
  const [fileBanner, setFileBanner] = useState(
    fromEdit ? { file: null, imageURL: project.image } : null
  );
  const [activeValue, setActiveValue] = useState(
    fromEdit ? project.isActive : null
  );

  console.log("lindos proyecot", project);
  const fileInputRef = useRef();
  const bannerInputRef = useRef();
  const alert = useAlert();

  const [showModalError, setShowModalError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const [data, setData] = useState({
    club: "",
    title: "",
    description: "",
    goal: "",
    startDate: "",
    finishDate: "",
    file: "",
  });
  useEffect(() => {
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} Proyecto`);
  }, []);

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  const getAllImages = async () => {
    if (project && project.uuid) {
      const images = await getImages(project.uuid);
      const data = images.data.map((item) => ({
        file: null,
        uuid: item.uuid ? item.uuid : null,
        imageURL: item.image,
      }));
      setSelectedImages(data);
    }
  };

  useEffect(() => {
    setSubmitted(false);
    getAllImages();
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (document.getElementById("amount").value < 0) return false;
    if (!fileBanner) return false;

    return true;
  };

  const onChangeDescription = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const onChangeGoal = (e) => {
    setDirty();
    if (e.target.value > 9999999) return false;
    setGoal(e.target.value);
  };

  const fileDrop = (index, e) => {
    setDirty();
    e.preventDefault();
    let image = selectedImages[index];

    if (image.uuid) setForDeleteImages([...forDeleteImages, image.uuid]);

    selectedImages.splice(index, 1);

    setSelectedImages([...selectedImages]);
  };

  const fileInputClicked = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedImages([...selectedImages, image]);
    }
  };

  const onFileBannerChange = (event) => {
    if (event.target.files[0]) {
      setDirty();
      const imageURL = URL.createObjectURL(event.target.files[0]);
      const image = { file: event.target.files[0], imageURL };
      setFileBanner(image);
    }
  };

  const onDeleteImage = (index, e) => {
    e.preventDefault();
    setFileBanner(null);
    bannerInputRef.current = null;
  };

  const onCreateProject = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("isActive", "true");
      formData.append("title", title);
      formData.append("description", description);
      formData.append("goal", goal);
      formData.append("startDate", startDate);
      formData.append("finishDate", finishDate);
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });
      formData.append("fileBanner", fileBanner.file);
      await createProject(formData);
      alert.show("Proyecto creado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/projects");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const onEditProject = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("club", uuidClub);
      activeValue !== null && formData.append("isActive", activeValue);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("goal", goal);
      formData.append("startDate", startDate);
      formData.append("finishDate", finishDate);
      fileBanner && formData.append("fileBanner", fileBanner.file);
      formData.append("deleteUuidImage", forDeleteImages);
      selectedImages.map((image) => {
        return formData.append("files", image.file);
      });

      await editProject(project.uuid, formData);
      alert.show("Proyecto editado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/projects");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos incorrectos");
      setShowModalError(true);
    } else {
      if (fromEdit) await onEditProject();
      else await onCreateProject();
    }
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            <>
              <div className="contain-league">
                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  onChange={({ target: { value } }) => {
                    setUuidLeague(value);
                    setDirty();
                  }}
                  required
                  value={uuidLeague}
                >
                  <option name={""} value={""}>
                    Elige una liga...
                  </option>

                  {leagues?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidLeague && (
                    <small className="p-invalid">La liga es obligatorio.</small>
                  )}
                </div>

                <select
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  onChange={({ target: { value } }) => {
                    setUuidClub(value);
                    setDirty();
                  }}
                  required
                  value={uuidClub}
                >
                  <option name={""} value={""}>
                    Elige un club...
                  </option>

                  {clubs?.map((item) => (
                    <option name={item.uuid} value={item.uuid}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <div>
                  {submitted && !uuidClub && (
                    <small className="p-invalid">El club es obligatorio.</small>
                  )}
                </div>

                <select
                  // className="select-league-decision"
                  style={{
                    width: 300,
                    backgroundColor: "#303030",
                    borderRadius: 4,
                    color: "white",
                  }}
                  className="select"
                  value={activeValue}
                  onChange={({ target: { value } }) => {
                    setActiveValue(value);
                    setDirty();
                  }}
                >
                  <option name={""} value={""}>
                    Selecciona un estado...
                  </option>

                  {optionsEdit.map((item) => (
                    <option name={item.label} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </select>
              </div>{" "}
            </>

            <h2 className="title-banner">Banner</h2>
            <div className="container-banner">
              <div className="container-files">
                {fileBanner && (
                  <div style={{ marginRight: 10 }} key={0}>
                    <button
                      className="delete-banner"
                      onClick={(e) => {
                        setDirty();
                        onDeleteImage(0, e);
                      }}
                    >
                      X
                    </button>
                    <img src={fileBanner.imageURL} className="image" />
                  </div>
                )}
              </div>
              <input
                className="form-control"
                type="file"
                onChange={onFileBannerChange}
                ref={bannerInputRef}
              />
            </div>
            <div>
              {submitted && !fileBanner && (
                <small className="p-invalid">El banner es obligatorio.</small>
              )}
            </div>

            <hr />

            <div className="image-container">
              <h2 className="title-images">Imágenes</h2>
              <div
                className={!selectedImages.length > 0 ? "drop-container" : ""}
              >
                {selectedImages?.map((item, index) => (
                  <div style={{ marginRight: 10 }} key={index}>
                    <button
                      className="delete-avatar"
                      onClick={(e) => {
                        fileDrop(index, e);
                      }}
                    >
                      X
                    </button>
                    <img src={item.imageURL} className="image" />
                  </div>
                ))}
              </div>
            </div>

            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
            <div onClick={fileInputClicked}>
              <button>Agregar Imagen</button>
            </div>

            <hr />

            <div className="div-title" style={{ marginTop: 20 }}>
              <div className="container-input-decision">
                <input
                  onChange={onChangeTitle}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Título Proyecto"
                  value={title}
                  required
                />
              </div>
            </div>
            <div>
              {submitted && !title && (
                <small className="p-invalid">El título es obligatorio.</small>
              )}
            </div>
            <div>
              <div className="container-text-area-decision">
                <textarea
                  placeholder="Descripción Proyecto"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDescription}
                  value={description}
                  required
                />
              </div>
              <div>
                {submitted && !description && (
                  <small className="p-invalid">
                    La descripción es obligatoria.
                  </small>
                )}
              </div>
            </div>

            <div className="goal">
              <div className="container-input-decision">
                <input
                  id="amount"
                  onChange={onChangeGoal}
                  name="title"
                  type="number"
                  className="input-title"
                  placeholder="Monto objetivo"
                  value={goal}
                  min={0}
                  required
                />
              </div>
              <div>
                {submitted && !goal && (
                  <small className="p-invalid">
                    El Monto objetivo es obligatorio.
                  </small>
                )}
                {submitted && goal < 0 && (
                  <small className="p-invalid">
                    El Monto objetivo no debe ser negativo.
                  </small>
                )}
              </div>
            </div>

            <div className="container-date-picker">
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de inicio</h4>
                <DatePicker
                  style={{ backgroundColor: "red", color: "green" }}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                  required
                />
              </div>
              <div>
                {submitted && !startDate && (
                  <small className="p-invalid">
                    La fecha inicio es obligatoria.
                  </small>
                )}
              </div>
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de cierre</h4>
                <DatePicker
                  selected={finishDate}
                  onChange={(date) => {
                    setFinishDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                  required
                />
              </div>
              <div>
                {submitted && !finishDate && (
                  <small className="p-invalid">
                    La fecha cierre es obligatoria.
                  </small>
                )}
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            GUARDAR
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="ERROR CREANDO PROYECTO"
            text={`crear el Proyecto: ${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateProject);
