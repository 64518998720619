import { combineReducers } from "redux";

import registerReducer from "./registerReducer";
import generalReducer from "./generalReducer";
import dataReducer from "./dataReducer";
import usersReducer from "./usersReducer";
import clubsReducer from "./clubsReducer";
import firebaseReducer from "react-redux-firebase";
import firestoreReducer from "redux-firestore";

export default combineReducers({
  register: registerReducer,
  general: generalReducer,
  data: dataReducer,
  users: usersReducer,
  clubs: clubsReducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});
