import * as actionTypes from "./types";
import { firestore } from "../../config/firebase";
import { setLoading } from "./generalActions";
import { timeConverter } from "../../utils/timeCoverter";
import { setNameInLS } from "../../utils/tokenService";

export const cleanOptions = () => {
  return {
    type: actionTypes.CLEAN_OPTIONS,
  };
};

export const filterOption = (option) => {
  return {
    type: actionTypes.FILTER_OPTION_LIST,
    payload: option,
  };
};

export const setAllOptions = (option) => {
  return {
    type: actionTypes.SET_OPTION_LIST,
    payload: option,
  };
};

export const setAllClubs = (clubs) => {
  return {
    type: actionTypes.SET_ALL_CLUBS,
    payload: clubs,
  };
};

export const setALlLeagues = (leagues) => {
  return {
    type: actionTypes.SET_DATA_LEAGUES,
    payload: leagues,
  };
};

const setTipoResiduos = (data) => {
  let tiposResiduos = data[0].data;
  tiposResiduos = Object.keys(tiposResiduos).map((item) => {
    return {
      id: item,
      value: item,
    };
  });

  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_TIPOS_RESIDUOS,
      payload: tiposResiduos,
    });
  };
};

const setReportesResiduos = (data) => {
  let reportes = null;
  reportes = data.map((item) => {
    let nombreCompleto = `${item.data["Nombre"] ? item.data["Nombre"] : ""} ${
      item.data["Apellido"] ? item.data["Apellido"] : ""
    }`;
    let fecha = timeConverter(item.data["Fecha"].seconds);
    return {
      Categoria: item.data["Categoria"],
      NombreApellido: nombreCompleto,
      Email: item.data["Email"],
      Direccion: item.data["Direccion"],
      Fecha: fecha,
      Telefono: item.data["Telefono"],
      Descripcion: item.data["Descripcion"],
      Peso: item.data["Peso"],
      Imagenes: item.data["ImagenResiduo"],
      id: item.id,
    };
  });

  return (dispatch) => {
    dispatch({
      type: actionTypes.FETCH_REPORTES_RESIDUOS,
      payload: reportes,
    });
  };
};

const setReporteResiduoActual = (data) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CURRENT_DOC,
      payload: data,
    });
  };
};

export const deleteReporteResiduoActual = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.DELETE_CURRENT_DOC,
    });
  };
};

const searchName = (data, id) => {
  let userName = data.map((user) => {
    if (id === user.data["uid"]) {
      return user.data["nombre"] + " " + user.data["apellido"];
    } else {
      return null;
    }
  });
  return (dispatch) => {
    if (userName) {
      dispatch({
        type: actionTypes.USER_SET_NAME,
        payload: userName,
      });
      setNameInLS(userName);
    }
  };
};
export const fetchCollection =
  (collection, action, parameterToAction) => (dispatch) => {
    dispatch(setLoading(true));
    firestore
      .collection(collection)
      .get()
      .then((response) => {
        let responseData = null;
        responseData = [
          ...response.docs.map((doc) => {
            return { data: doc.data(), id: doc.id };
          }),
        ];
        dispatch(action(responseData, parameterToAction));
        dispatch(setLoading(false));
      });
    return {};
  };
export const fetchDocCollectionById =
  (collection, id, action) => (dispatch) => {
    dispatch(setLoading(true));
    firestore
      .collection(collection)
      .doc(id)
      .get()
      .then((response) => {
        let responseData = response.data();
        dispatch(action(responseData));
        dispatch(setLoading(false));
      });
    return {};
  };
export const fetchCollectionQuery =
  (collection, field, comparator, value, action) => (dispatch) => {
    dispatch(setLoading(true));
    firestore
      .collection(collection)
      .where(field, comparator, value)
      .get()
      .then((response) => {
        if (response) {
          let responseData = null;
          responseData = [
            ...response.docs.map((doc) => {
              return { data: doc.data(), id: doc.id };
            }),
          ];
          dispatch(action(responseData));
          dispatch(setLoading(false));
        }
      });
  };
export const fetchCollectionQueryDate =
  (collection, dateFrom, dateTo, action) => (dispatch) => {
    dispatch(setLoading(true));
    firestore
      .collection(collection)
      .where("Fecha", ">=", dateFrom)
      .where("Fecha", "<=", dateTo)
      .get()
      .then((response) => {
        if (response) {
          let responseData = null;
          responseData = [
            ...response.docs.map((doc) => {
              return { data: doc.data(), id: doc.id };
            }),
          ];
          dispatch(action(responseData));
          dispatch(setLoading(false));
        }
      });
  };
export const fetchTipoResiduos = () => {
  return (dispatch) => {
    dispatch(fetchCollection("TiposResiduos", setTipoResiduos));
  };
};
export const fetchReportesResiduos = () => {
  return (dispatch) => {
    dispatch(fetchCollection("ReporteResiduos", setReportesResiduos));
  };
};
export const fetchReporteResiduoById = (id) => {
  return (dispatch) => {
    dispatch(
      fetchDocCollectionById("ReporteResiduos", id, setReporteResiduoActual)
    );
  };
};
export const fetchReportesResiduosFiltered = (field, comparator, value) => {
  return (dispatch) => {
    dispatch(
      fetchCollectionQuery(
        "ReporteResiduos",
        field,
        comparator,
        value,
        setReportesResiduos
      )
    );
  };
};
export const fetchReportesResiduosFilteredByDate = (dateFrom, dateTo) => {
  return (dispatch) => {
    if (!dateFrom && !dateTo) {
      return;
    } else if (dateFrom && !dateTo) {
      dispatch(
        fetchCollectionQuery(
          "ReporteResiduos",
          "Fecha",
          ">=",
          dateFrom,
          setReportesResiduos
        )
      );
    } else if (dateTo && !dateFrom) {
      dispatch(
        fetchCollectionQuery(
          "ReporteResiduos",
          "Fecha",
          "<=",
          dateTo,
          setReportesResiduos
        )
      );
    } else if (dateFrom && dateTo) {
      dispatch(
        fetchCollectionQueryDate(
          "ReporteResiduos",
          dateFrom,
          dateTo,
          setReportesResiduos
        )
      );
    }
  };
};

export const fetchPerfilesAdmin = (id) => {
  return (dispatch) => {
    dispatch(fetchCollection("PerfilesAdmin", searchName, id));
  };
};
