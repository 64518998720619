import axios from "axios";
import { store } from "../store";
import { setTooltips } from "../actions/generalActions";
import { logout } from "../actions/authActions";
import {
  GET_CATEGORIES_URL,
  KEYWORD_URL,
  POST_CATEGORY_URL,
  POST_LOGIN_URL,
  USERS_CSV_URL,
  USERS_URL,
  REFRESH_URL,
  USERS_URL_GET,
} from "./connector";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      console.log(error);
      localStorage.removeItem("persist:auth");
      window.location.replace("/login");
      store.dispatch(logout());
      store.dispatch(setTooltips(false));
    } else {
      return Promise.reject(error);
    }
  }
);

export const postLoginAPI = async ({ username, password }) =>
  axios.post(POST_LOGIN_URL, {
    email: username,
    password,
  });

export const getCategoriesAPI = async () => axios.get(GET_CATEGORIES_URL);

export const postRefreshTokenAPI = async (refreshToken) =>
  axios.post(REFRESH_URL, null, {
    params: {
      token: refreshToken,
    },
  });

export const postCategoryAPI = async ({ params, access_token }) =>
  axios.post(POST_CATEGORY_URL, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${access_token}`,
    },
  });

export const putCategoryAPI = async ({ params, id, access_token }) =>
  axios.put(`${POST_CATEGORY_URL}/${id}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
      authorization: `bearer ${access_token}`,
    },
  });

// funcion UserData que trae todos los usuarios existente para luego ser
// filtrada por busqueda. Todo: hacer pedido a base de datos y devolver filtrados
export const UsersData = async ({ access_token, clubUuid }) =>
  axios.get(USERS_URL, {
    params: { clubUuid, limit: 0 },
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const getUsersAPI = async ({
  access_token,
  subscriberStatus,
  page,
  clubUuid,
  email,
  pendingStatus,
}) =>
  axios.get(USERS_URL_GET, {
    params: { subscriberStatus, page, clubUuid, email, pendingStatus },
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const postUserAPI = async ({ access_token, params }) =>
  axios.post(USERS_URL, params, {
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const putUserAPI = async ({ params, access_token, id }) =>
  axios.put(`${USERS_URL}/${id}`, params, {
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const deleteUserAPI = async ({ id, access_token }) =>
  axios.delete(`${USERS_URL}/${id}`, {
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const deleteKeywordAPI = async ({ id, access_token }) =>
  axios.delete(`${KEYWORD_URL}/${id}`, {
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const addKeywordAPI = async ({ name, category_id, access_token }) =>
  axios.post(
    `${KEYWORD_URL}`,
    {
      name,
      category: category_id,
    },
    {
      headers: {
        authorization: `bearer ${access_token}`,
      },
    }
  );

export const deleteCategoryAPI = async ({ id, access_token }) =>
  axios.delete(`${POST_CATEGORY_URL}/${id}`, {
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });

export const exportUsersCSVAPI = async ({ access_token }) =>
  axios.get(USERS_CSV_URL, {
    responseType: "blob",
    headers: {
      authorization: `bearer ${access_token}`,
    },
  });
