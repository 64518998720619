import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { loginUserAction, accessFail } from "./../../store/actions/registerActions";
import Input from "./../../ui/input/input";
import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import bigManagerLogin from "../../assets/images/bigManagerLogin.png";
import FormValidation from "../formValidation/formValidation";
import * as Yup from "yup";
import decoratorLogin from "../../assets/images/decoratorLogin.png";
import { login } from "../../services/AuthService";

import {
  setRefreshToken,
  setToken,
  setClub,
  setRole,
} from "../../services/utils";

import "./login.css";

const initialState = { username: "", password: "" };
const validationLogin = Yup.object().shape({
  username: Yup.string()
    .email("Tiene que ser una direccion de email correcta")
    .required("Campo obligatorio")
    .max(35, "Maximo de carateres es 35"),
  password: Yup.string().required("Campo obligatorio").max(35),
});
class Login extends Component {

  componentDidMount() {
    this.props.accessFail(false)
  }

  loginUser = async (user) => {
    try {
      this.props.accessFail(false)
      const response = await login(user);
      setRefreshToken(response.data.refreshToken);
      setToken(response.data.access_token);
      setClub(response.data.clubUuid);
      setRole(response.data.role);
      this.props.history.push("/main/users");
    } catch (error) {
      this.props.accessFail(true)
    }
  };

  submit = (event, setFieldError) => {
    const user = {
      email: event.username,
      password: event.password,
      deviceToken: "ASHJHJEWHDQW",
    };
    this.loginUser(user);
  };

  setInput = (event) => {
    this.setState({ ...this.state, [event.target.name]: event.target.value });
  };

  componentWillMount = () => {
    document.body.className = "fondo";
  };

  render() {
    return (
      <section className="login-container">
        <img className="image-background" src={decoratorLogin} />
        <div className="container-form-login">
          <img src={bigManagerLogin} />

          <FormValidation
            submit={this.submit}
            buttonText="Iniciar sesión"
            values={initialState}
            validation={validationLogin}
            loading={this.props.loadingAuth}
            success={this.props.loginSuccess}
            error={this.props.loginError}
            render={({ values, errors, handleChange }) => {
              return (
                <>
                  <Input
                    val={values.username}
                    type="text"
                    placeholder="E-mail"
                    name="username"
                    action={handleChange}
                  />
                  {errors.username ? (
                    <div className="input-error">
                      <small>{errors.username}</small>
                    </div>
                  ) : null}
                  <Input
                    val={values.password}
                    type="password"
                    placeholder="Contraseña"
                    name="password"
                    action={handleChange}
                  />
                  {errors.password ? (
                    <div className="input-error">
                      <small>{errors.password}</small>
                    </div>
                  ) : null}
                </>
              );
            }}
          />
        </div>
        <div
          style={this.props.loginError ? alertStyleActive : alertStyleInactive}
        >
          <small>Usuario o contraseña incorrecto</small>
        </div>
      </section>
    );
  }
}

let alertStyleActive = {
  color: "red",
  margin: "0 auto",
};
let alertStyleInactive = {
  display: "none",
};

const mapStateToProps = (state) => {
  return {
    name: state.register.name,
    role: state.register.location,
    loginError: state.register.loginError,
    loadingAuth: state.register.loadingAuth,
    loginSuccess: state.register.loginSuccess,
  };
};

export default withRouter(connect(mapStateToProps, { loginUserAction, accessFail })(Login));
