import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import ModalError from "src/ui/modalError/modalError";
import "./table.css";

import {
  getAllNotifications,
  deleteNotificacion,
} from "src/services/NotificationService";
import Button from "src/ui/button/button";
import Table from "src/components/table/Table";
import { formatDate } from "src/utils/formatDate";
import { useAlert } from "react-alert";
import Modal from "../../ui/modal/modal";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import ReactPaginate from "react-paginate";

const fieldAndHeaders = {
  LEAGUE: "League",
  CLUB: "Club",
  TITULO: "title",
  DESCRIPCIÓN: "description",
  TIPO: "type",
  FECHA: "created",
};

const Notifications = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const alert = useAlert();

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  useEffect(() => {
    dispatch(setTitle("Notificaciones"));
  }, []);

  const refreshList = async () => {
    try {
      const response = await getAllNotifications(10, page);
      setNotifications(response.data);
      setMeta(response.data.meta);
      setTableData(
        response.data.item.map((item) => ({
          Club: item.club.name,
          League: item.league.name,
          uuid: item.uuid,
          title: item.title,
          type: item.type,
          description: item.description,
          created: formatDate(item.created),
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page]);

  const handleDelete = async () => {
    try {
      await deleteNotificacion(idToDelete);
      refreshList();
      setConfirmModalOpen(false);
    } catch (error) {
      setShowModalError(true);
    }
  };

  const handleActionClick = (id, option, item) => {
    if (option === "edit") {
      const notification = notifications.item.find(
        (it) => it.uuid === item.uuid
      );
      props.history.push({
        pathname: "/main/notifications/create",
        state: { notification },
      });
    } else {
      setIdToDelete(item.uuid);
      setConfirmModalOpen(true);
    }
  };

  // const handleEdit = async (item) => {
  //   try {
  //     setLoading(true);
  //     await editImportantPost(item.id, !item.importantBool);
  //     await refreshList();
  //     setLoading(false);
  //     alert.show("Post editado con exito", { type: "success" });
  //   } catch (error) {
  //     setLoading(false);
  //     alert.show("Error al editar post", { type: "error" });
  //   }
  // };

  // const ButtonRender = (item) => {
  //   return (
  //     <Button
  //       event={loading ? "LOADING" : item.important}
  //       secondary
  //       action={() => handleEdit(item)}
  //       disabled={loading}
  //     />
  //   );
  // };

  const tableEnableButtons = {
    edit: true,
    del: true,
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button
            event="NUEVA NOTIFICACIÓN"
            action={() => props.history.push("/main/notifications/create")}
            secondary
          />
        </div>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={fieldAndHeaders}
          totalItems={meta?.totalItems}
          items={tableData}
          setPage={handlePage}
          // pages={projects?.meta?.totalPage}
          // currentPage={projects?.meta?.currentPage}
          // currentItems={projects?.meta?.itemsPerPage}
          triggerRow={handleActionClick}
          enableButtons={tableEnableButtons}
        />
        <div className="footer">
          <p className="total">Total de Notificaciones: {meta?.totalItems}</p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO NOTIFICACIÓN"
          text={"Editar el estado del notificación"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar la notificación?"}
            title={"Borrar notificación"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
    </>
  );
};

export default Notifications;
