import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { connect } from "react-redux";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import {
  createNotification,
  editNotification,
} from "src/services/NotificationService";
import ModalError from "src/ui/modalError/modalError";
import { setTitle } from "../../../store/actions/generalActions";
import "./index.css";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

const optionsEdit = [
  { value: "ALL", label: "Todos" },
  { value: "DIGITAL_PARTNER", label: "Digitales" },
  { value: "MANAGING_PARTNER", label: "Managers" },
];

const CreateNotification = (props) => {
  const notification = props?.location?.state?.notification;
  const fromEdit = !!notification;
  const [clubs, setClubs] = useState([]);
  const [title, setTitle] = useState(fromEdit ? notification.title : "");
  const [description, setDescription] = useState(
    fromEdit ? notification.description : ""
  );
  const [type, setType] = useState(fromEdit ? notification.type : null);
  const [uuidLeague, setUuidLeague] = useState(
    fromEdit ? notification.uuidLeague : null
  );
  const [uuidClub, setUuidClub] = useState(
    fromEdit ? notification.uuidClub : null
  );
  const alert = useAlert();
  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [leagues, setLeagues] = useState([]);

  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  useEffect(() => {
    setSubmitted(false);
    props.setTitle(`${fromEdit ? "Editar" : "Nueva"} Notificación`);
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const onChangeDescription = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }

    return true;
  };

  const onCreateNotification = async () => {
    setError("");
    try {
      const formData = {
        title: title,
        description: description,
        type: type,
        uuidLeague: uuidLeague,
        uuidClub: uuidClub,
      };
      await createNotification(formData);
      alert.show("Notificación creada con éxito", { type: "success" });
      setPristine();
      props.history.push("/main/notifications");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  const onEditNotification = async () => {
    setError("");
    try {
      const formData = {
        title: title,
        description: description,
        type: type,
        uuidLeague: uuidLeague,
        uuidClub: uuidClub,
      };
      await editNotification(notification.uuid, formData);
      alert.show("Notificación editada con éxito", { type: "success" });
      setPristine();
      props.history.push("/main/notifications");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos sin dato");
      setShowModalError(true);
    } else {
      if (fromEdit) onEditNotification();
      else onCreateNotification();
    }
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            <select
              // className="select-league-decision"
              style={{
                width: 300,
                backgroundColor: "#303030",
                borderRadius: 4,
                color: "white",
              }}
              className="select"
              value={type}
              onChange={({ target: { value } }) => {
                setType(value);
                setDirty();
              }}
              required
            >
              <option name={""} value={""}>
                Selecciona un tipo...
              </option>

              {optionsEdit.map((item) => (
                <option name={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            <div>
              {submitted && !type && (
                <small className="p-invalid transformY-20">
                  Debe seleccionar un tipo.
                </small>
              )}
            </div>
            <select
              style={{
                width: 300,
                backgroundColor: "#303030",
                borderRadius: 4,
                color: "white",
              }}
              className="select"
              value={uuidLeague}
              onChange={({ target: { value } }) => {
                setUuidLeague(value);
                setDirty();
              }}
              required
            >
              <option name={""} value={""}>
                Selecciona un liga...
              </option>

              {leagues?.map((item) => (
                <option name={item.uuid} value={item.uuid}>
                  {item.name}
                </option>
              ))}
            </select>
            <div>
              {submitted && !uuidLeague && (
                <small className="p-invalid transformY-20">
                  Debe seleccionar una liga.
                </small>
              )}
            </div>
            <select
              style={{
                width: 300,
                backgroundColor: "#303030",
                borderRadius: 4,
                color: "white",
              }}
              className="select"
              onChange={({ target: { value } }) => {
                setUuidClub(value);
                setDirty();
              }}
              required
              value={uuidClub}
            >
              <option name={""} value={""}>
                Elige un club...
              </option>

              {clubs?.map((item) => (
                <option name={item.uuid} value={item.uuid}>
                  {item.name}
                </option>
              ))}
            </select>
            <div>
              {submitted && !uuidClub && (
                <small className="p-invalid">El club es obligatorio.</small>
              )}
            </div>
            <div className="div-title">
              <div className="container-input-decision">
                <input
                  onChange={onChangeTitle}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Título"
                  value={title}
                  required
                />
              </div>
            </div>
            <div>
              {submitted && !title && (
                <small className="p-invalid transformY-20">
                  El Título es obligatorio.
                </small>
              )}
            </div>
            <div>
              <div className="container-text-area-decision">
                <textarea
                  placeholder="Descripción"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDescription}
                  value={description}
                  required
                />
              </div>
            </div>
            <div>
              {submitted && !description && (
                <small className="p-invalid">
                  La descripción es obligatoria.
                </small>
              )}
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            {fromEdit ? "GUARDAR" : "ENVIAR"}
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="NOTIFICACIÓN"
            text={`${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateNotification);
