import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import {
  deleteDecision,
  getAllDecisions,
  getDecisions,
} from "src/services/DecisionServices";
import Table from "../../components/table/Table";
import Modal from "../../ui/modal/modal";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import ModalError from "src/ui/modalError/modalError";
import lupa from "../../assets/images/lupa.png";
import { useHistory } from "react-router-dom";
import "./decisions.css";
import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import { getClubsAdmin } from "src/services/ClubService";
import ReactPaginate from "react-paginate";

const fieldAndHeaders = {
  LIGA: "league",
  CLUB: "club",
  TIÍTULO: "title",
  DESCRIPCIÓN: "description",
};

const tableEnableButtons = {
  edit: true,
  del: true,
  action4: true,
};

const optionsActive = [
  { value: "true", label: "Activo" },
  { value: "false", label: "No Activo" },
];
const optionsFinalized = [
  { value: "true", label: "Finalizado" },
  { value: "false", label: "No Finalizado" },
];

const Decisions = (props) => {
  const [tableData, setTableData] = useState([]);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [decisions, setDecisions] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [finalizedFilter, setFinalizedFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [searchByTitle, setSearchByTitle] = useState("");
  const searchRef = useRef();

  const [page, setPage] = useState(1);
  const history = useHistory();

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  useEffect(() => {
    props.setTitle("Decisiones");
    getAllClubs();
  }, []);

  const handleActionClick = (id, option, item) => {
    if (option === "edit") {
      const decision = decisions.items.find((it) => it.uuid === item.id);
      history.push({
        pathname: "/main/optionList",
        state: { options: item.options, decision },
      });
    }
    if (option === "del") {
      setIdToDelete(id);
      setConfirmModalOpen(true);
    }
    if (option === "action4") {
      props.history.push({
        pathname: "/main/projects/investment",
        state: { uuid: item.id, entity: "DECISION", name: item.title },
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDecision(idToDelete);
      const result = tableData.filter((item) => item.id !== idToDelete);
      setTableData(result);
      setConfirmModalOpen(false);
    } catch (error) {
      setShowModalError(true);
    }
  };

  const refreshList = async () => {
    try {
      const data = await getAllDecisions(
        20,
        page,
        clubFilter,
        activeFilter,
        finalizedFilter,
        searchByTitle
      );
      setDecisions(data.data);
      setTableData(
        data?.data?.items?.map((decision) => ({
          isActive: decision.isActive,
          league: decision.club.league.name,
          title: decision.title,
          id: decision.uuid,
          club: decision.club.name,
          description: decision.description,
          options: decision.options,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page, clubFilter, activeFilter, finalizedFilter, searchByTitle]);

  const onFinalizedChange = (e) => {
    setFinalizedFilter(e.target.value);
  };

  const onActiveChange = (e) => {
    setActiveFilter(e.target.value);
  };

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button
            event="CREAR DECISION"
            action={() => history.push("/main/createDecisions")}
            secondary
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchByTitle(searchRef.current.value);
          }}
        >
          <div className="user-filters-item">
            <label>Filtrar por titulo</label>
            <div className="filter-input-button">
              <input
                id="user-filter-input"
                placeholder="filtro por titulo"
                className="user-filter-input"
                ref={searchRef}
              />
              <div className="filters-button">
                <Button event="buscar" secondary value="search" />
                <button
                  className="button"
                  onClick={() => {
                    document.getElementById("user-filter-input").value = "";
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  limpiar
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="users-filters">
          <div className="user-filters-item">
            <label>Filtrar Club</label>
            <SelectUI
              options={clubs}
              isLoading={!clubs}
              onChange={onClubChange}
            />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Activo</label>
            <SelectUI options={optionsActive} onChange={onActiveChange} />
          </div>
          <div className="user-filters-item">
            <label>Filtrar Finalizado</label>
            <SelectUI options={optionsFinalized} onChange={onFinalizedChange} />
          </div>
        </div>
      </div>
      <Table
        fieldAndHeaders={fieldAndHeaders}
        setPage={() => {}}
        totalItems={decisions?.meta?.totalItems}
        items={tableData}
        pages={decisions?.meta?.totalPage}
        currentPage={decisions?.meta?.currentPage}
        currentItems={decisions?.meta?.itemsPerPage}
        triggerRow={handleActionClick}
        enableButtons={tableEnableButtons}
      />
      <div className="footer">
        <p className="total">
          Total de decisiones: {decisions?.meta?.totalItems}
        </p>
        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePage}
          pageRangeDisplayed={5}
          pageCount={decisions?.meta?.totalPage}
          previousLabel="<"
          renderOnZeroPageCount={null}
          activeLinkClassName="active"
        />
      </div>
      {confirmModalOpen && (
        <Modal closeModal={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar la decisión?"}
            title={"Borrar decisión"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
      {showModalError && (
        <ModalError
          title="ERROR BORRANDO DECISIÓN"
          text="borrar esta desición"
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default connect(null, {
  setTitle,
})(Decisions);
