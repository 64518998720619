import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";

const Home = (props) => {
  useEffect(() => {
    props.setTitle("Home");
    // eslint-disable-next-line
  }, []);

  return <div>Pagina de prueba</div>;
};

export default connect(null, {
  setTitle,
})(Home);
