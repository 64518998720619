import * as actionTypes from "./types";
import { setError, closeMenu } from "./generalActions";
import { fetchPerfilesAdmin } from "./dataActions";
import { authRef } from "../../config/firebase";
import "firebase/auth";
import {
  setToken,
  deleteToken,
  getNameFromLocalStorage,
} from "./../../utils/tokenService";
import { clearLoggedUser } from "../../services/utils";

const loginAccess = () => {
  return {
    type: actionTypes.ISAUTH,
    payload: true,
  };
};

const loginSuccess = (value) => {
  return {
    type: actionTypes.LOGIN_SUCCESS,
    payload: value,
  };
};

export const loginEnd = () => {
  return {
    type: actionTypes.LOGIN_END,
    payload: false,
  };
};

export const accessFail = (values) => {
  console.log("testss")
  return {
    type: actionTypes.LOGIN_FAIL,
    payload: values,
  };
};

const setLoadingAuth = (value) => {
  return {
    type: actionTypes.LOGIN_START,
    payload: value,
  };
};

const loginUserSuccess = (dispatch, user) => {
  dispatch(loginSuccess(true));
  dispatch(setLoadingAuth(false));
  setTimeout(() => {
    dispatch(loginSuccess(false));
    dispatch(loginAccess());
  }, 700);
  setToken(user);
};

export const loginUserAction = (value, history) => {
  return (dispatch) => {
    dispatch(setLoadingAuth(true));
    dispatch(accessFail(false));
    return authRef
      .signInWithEmailAndPassword(value.email, value.password)
      .then((user) => {
        loginUserSuccess(dispatch, user);
        history.push("/main");
      })
      .catch((error) => {
        console.log(error);
        dispatch(setLoadingAuth(false));
        dispatch(accessFail(true));
      });
  };
};

export const logOutUser = (history) => {
  return () => clearLoggedUser(history);
};

export const getUserName = () => {
  let userFromLS = getNameFromLocalStorage();
  if (userFromLS) {
    return (dispatch) => {
      dispatch({
        type: actionTypes.USER_SET_NAME,
        payload: userFromLS,
      });
    };
  } else {
    return (dispatch) => {
      authRef.onAuthStateChanged((user) => {
        if (user) {
          dispatch(fetchPerfilesAdmin(user.uid));
        } else {
          // dispatch(logOutUser());
        }
      });
    };
  }
};
