import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { postNewLeague } from "src/services/LeagueService";
import ModalError from "src/ui/modalError/modalError";
import dragImage from "../../assets/images/dragImage.png";
import { useAlert } from "react-alert";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

import "./createLeagues.css";

const CreateLeagues = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [title, setTitle] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const fileInputRef = useRef();
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const alert = useAlert();

  useEffect(() => {
    props.setTitle("Ligas");
  }, []);

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const uploadDecision = async (e) => {
    setDisabledSaveButton(true);
    e.preventDefault();
    try {
      var formData = new FormData();
      formData.append("name", title);
      formData.append("file", selectedFile.file);
      await postNewLeague(formData);
      alert.show("Liga creada con éxito", { type: "success" });
      props.history.push("/main/leagues");
      setPristine();
      setSelectedFile(null);
      setTitle(null);
    } catch (error) {
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileInputClicked = () => {
    setDirty();
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    const imageURL = URL.createObjectURL(files);
    const image = { file: files, imageURL };
    setSelectedFile(image);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    const imageURL = URL.createObjectURL(files);
    const image = { file: files, imageURL };
    setSelectedFile(image);
  };

  return (
    <>
      <form>
        <fieldset className="fieldset-create-league">
          <div className="container-crate-decisión">
            <div className="div-title">
              <div
                className="container-input-create-league"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  width: 449,
                  height: 60,
                  borderRadius: 8,
                  backgroundColor: "#303030",
                }}
              >
                <input
                  onChange={onChangeTitle}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Agregá el nombre de la liga"
                />
              </div>
            </div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
            <div className="img-input">
              <div
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                className={!selectedFile ? "drop-container" : ""}
                onClick={fileInputClicked}
              >
                {!selectedFile ? (
                  <div
                    className="img-selected-create-league"
                    style={{
                      height: 110,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img height={65} width={65} src={dragImage} />
                    <p style={{ color: "#626262" }}>
                      Arrastrá o agregá el logo de la liga
                    </p>
                  </div>
                ) : (
                  <img src={selectedFile.imageURL} className="image" />
                )}
              </div>
              <p style={{ color: "#626262" }}>
                Las imágenes no deben pesar mas de 5MB
              </p>
            </div>
            <button
              disabled={disabledSaveButton}
              className="create-league-btn"
              onClick={(e) => uploadDecision(e)}
            >
              CREAR LIGA
            </button>
          </div>
        </fieldset>
      </form>
      {showModalError && (
        <ModalError
          title="ERROR CREANDO LIGA"
          text={"crear una liga"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
      {Prompt}
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateLeagues);
