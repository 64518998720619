import { api } from "../config/api";

export const getAllNotifications = (limit, page, club, isActive, finalized) =>
  api.get(`public/notifications/?limit=${limit}&page=${page}`);

export const createNotification = (formData) =>
  api.post("public/notifications/", formData);

export const deleteNotificacion = (uuid) =>
  api.delete(`public/notifications/${uuid}`);

export const editNotification = (uuid, formData) =>
  api.put(`public/notifications/${uuid}`, formData);
