import { api } from "../config/api";

const LEAGUES_ADMIN = `league/admin/`;
const LEAGUE = `public/league`;
const CLUBS = "clubs";
const CLUB = "public/club";
const ADMIN_CLUB = `clubs/admin/`;

export const getLeagues = () => api.get(LEAGUES_ADMIN);

export const getLeaguesAdmin = () => api.get(LEAGUES_ADMIN);

export const createNewClub = (formData) => api.post(CLUBS, formData);

export const editCurrentClub = (formData, uuid) =>
  api.put(`clubs/${uuid}`, formData);

export const editCurrentLeague = (formData, uuid) =>
  api.put(`league/${uuid}`, formData);

export const getClubsByLeague = (uuidLeague) =>
  api.get(`${LEAGUE}/${uuidLeague}/clubs`);

export const getClubDataById = (uuidClub) => api.get(`${CLUBS}/${uuidClub}`);

export const getClubDataByIdAdmin = (uuidClub) =>
  api.get(`${CLUBS}/${uuidClub}`);

export const getClubsAdminByLeague = (uuidLeague) =>
  api.get(`clubs/admin/?league=${uuidLeague}`);

export const getClubsAdmin = (limit = 99999, page, league, isActive) => api.get(ADMIN_CLUB, {
  params: {
    limit: limit,
    page,
    league,
    isActive: isActive
  }
});

export const uploadPartners = (formData, uuidClub) =>
  api.post(`clubs/${uuidClub}/partners`, formData);

export const getPartners = async ({ page, clubUuid, isUsed, limit = 20 }) =>
  api.get(`clubs/${clubUuid}/partners`, {
    params: { page, isUsed, limit },
  });
