import {
  SET_ALL_CLUBS,
  SET_ALL_DECISIONS,
  CLEAN_DECISIONS,
  CLEAN_ALL_CLUBS,
} from "../actions/types";

const INITIAL_STATE = {
  items: [],
  allDecisions: [],
  meta: {
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
    totalItems: 0,
    totalPages: 0,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ALL_CLUBS:
      return { ...state, items: action.payload };
    case SET_ALL_DECISIONS:
      return { ...state, allDecisions: action.payload };
    case CLEAN_DECISIONS:
      return { ...state, allDecisions: INITIAL_STATE.decisions };
    case CLEAN_ALL_CLUBS:
      return { ...state, items: INITIAL_STATE.items };

    default:
      return { ...state };
  }
};
