import environment from './base';
const baseApi = 'https://back.compila.app/';
const env = environment(baseApi);
export default {
  ...env,
  INTERVALS: {
    LOGOUT_TIME: 3600
  },
  IS_PRODUCTION: true,
  IS_DEVELOPMENT: false,
};
