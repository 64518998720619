import { api } from "../config/api";

export const upgradeUser = (uuid) => api.put(`admin/userClub/${uuid}/upgrade`);

export const downgradeUser = (uuid) =>
  api.put(`admin/userClub/${uuid}/downgrade`);

export const editUser = (uuid, user, clubUuid) =>
  api.put(`admin/users/${uuid}`, user, {
    headers: {
      club: clubUuid,
    },
  });

export const createUser = (user) => api.post("admin/users", user);

export const subscribePetition = async (uuid) => {
  await api.post(`public/subscribePetition/${uuid}`);
};
