import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import {
  getClubsAdminByLeague,
  getLeagues,
  uploadPartners,
} from "src/services/ClubService";
import Select from "react-select";
import "./styles.css";
import dragImage from "../../assets/images/dragImage.png";
import { useAlert } from "react-alert"

const Partners = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [APIResponse, setAPIResponse] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState("");
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState("");
  const fileInputRef = useRef();
  const alert = useAlert()

  useEffect(() => {
    props.setTitle("Partners");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const getAllLeagues = async () => {
    const data = await getLeagues();
    const leagues = data.data.items.map((league) => {
      return { value: league.uuid, label: league.name.toUpperCase() };
    });
    setLeagues(leagues);
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    const clubs = data.data.items.map((club) => {
      return { value: club.uuid, label: club.name.toUpperCase() };
    });
    setClubs(clubs);
  };

  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onFileUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile.file);
    try {
      const response = await uploadPartners(formData, uuidClub);
      if (response.status === 201) {
        props.history.push("/main/partners")
        alert.show("Partner subido con exito", { type: "success" })
      }
    } catch (error) {
      setAPIResponse(
        `Ha ocurrido un error al subir el archivo : ${error.message}`
      );
    }
  };

  const onLeagueChangeHandler = (event) => {
    setUuidLeague(event.value);
  };

  const onClubChangeHandler = (event) => {
    setUuidClub(event.value);
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    const imageURL = URL.createObjectURL(files);
    const image = { file: files, imageURL };
    setSelectedFile(image);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    const imageURL = URL.createObjectURL(files);
    const image = { file: files, imageURL };
    setSelectedFile(image);
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <fieldset className="fieldset-partners">
        <Select
          onChange={onLeagueChangeHandler}
          options={leagues}
          placeholder="Seleccione una liga..."
          className="select"
        />
        {uuidLeague && (
          <Select
            onChange={onClubChangeHandler}
            options={clubs}
            placeholder="Seleccione un club..."
            className="select"
          />
        )}
        {uuidClub && (
          <>
            <div className="file">
              <div className="img-input">
                <div
                  onDragOver={dragOver}
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={fileDrop}
                  className={!selectedFile ? "drop-container" : ""}
                  onClick={fileInputClicked}
                >
                  
                    <div className="container-selected-partners-img">
                      <img height={65} width={65} src={dragImage} />
                      <p style={{ color: "#626262" }}>
                        Arrastrá o agregá un archivo
                      </p>
                    </div>
                
                </div>
                <p style={{ color: "#626262" }}>
                  El archivo csv no deben pesar mas de 30MB
                </p>
              </div>
              <input
                ref={fileInputRef}
                className="file-input"
                type="file"
                onChange={filesSelected}
              />

              <button className="btn-upload-partners" onClick={onFileUpload}>
                Subir
              </button>
            </div>
          </>
        )}

        {APIResponse && <p className="api__response">{APIResponse}</p>}
      </fieldset>
    </>
  );
};

export default connect(null, {
  setTitle,
})(Partners);
