export const setRefreshToken = (token) =>
  localStorage.setItem("tokenRefresh", token);
export const getRefreshToken = () => localStorage.getItem("tokenRefresh");
export const setToken = (token) => localStorage.setItem("access-Token", token);
export const getToken = () => localStorage.getItem("access-Token");
export const setClub = (token) => localStorage.setItem("club", token);
export const getClub = () => localStorage.getItem("club");
export const setRole = (role) => localStorage.setItem("role", role);
export const getRole = () => localStorage.getItem("role");

export const clearLoggedUser = async (history) => {
  localStorage.removeItem("tokenRefresh");
  localStorage.removeItem("access-Token");
  localStorage.removeItem("club");
  history.push("/login");
};

export const getClubIdFromStorage = () => localStorage.getItem("club");

const REGEX_TOKEN = /auth|public/;
export const isRejectedEndpoints = (url) => REGEX_TOKEN.test(url || "");

const REGEX_CLUB = /users|decisions|/;
export const isUserEndpoints = (url) => REGEX_CLUB.test(url || "");
