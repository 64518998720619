import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";

import { FirebaseConfig } from "../config/keys";
firebase.initializeApp(FirebaseConfig);

export const firestore = firebase.firestore();
export const authRef = firebase.auth();

// let prueba = null;
// firestore
//   .collection("ReporteResiduos")
//   .where("Fecha", ">=", "08-07-2020")
//   .get()
//   .then((data) => {
//     if (data) {
//       data.docs.map((doc) => {
//         console.log(doc.data());
//       });
//     } else {
//       console.log("no encontro data");
//     }
//   });
// console.log("prueba query", prueba);
