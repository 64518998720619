import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Button from "../../ui/button/button";

const FormValidation = (props) => {
  const [buttonState, setButtonState] = useState(props.buttonText);

  useEffect(() => {
    if (props.loading) {
      setButtonState("LOADING");
    } else if (props.success) {
      setButtonState("SUCCESS");
    } else if (props.error) {
      setButtonState("ERROR");
      setTimeout(() => {
        setButtonState(props.buttonText);
      }, 1000);
    } else {
      setButtonState(props.buttonText);
    }
    // eslint-disable-next-line
  }, [props.loading, props.success, props.error]);
  return (
    <Formik
      validateOnChange={false}
      onSubmit={(values) => {
        props.submit(values);
      }}
      validationSchema={props.validation}
      initialValues={props.values}
    >
      {({ values, errors, handleChange, handleSubmit, setFieldError }) => {
        return (
          <form
            style={props.modal ? modalStyle : null}
            onSubmit={(...args) => handleSubmit(...args, setFieldError)}
            className="form-container"
          >
            {props.render({ values, errors, handleChange })}
            <Button type="submit" event={buttonState} />
          </form>
        );
      }}
    </Formik>
  );
};

const modalStyle = {
  padding: "0rem 2rem",
};

export default FormValidation;
