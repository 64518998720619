import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { addAvatar } from "src/services/AvatarService";
import "./styles.css";
import ModalError from "src/ui/modalError/modalError";
import Button from "src/ui/button/button";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import ReactPaginate from "react-paginate";
import { useAlert } from "react-alert";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const AvatarPage = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showModalError, setShowModalError] = useState(false);
  const [uuidClub, setUuidClub] = useState();
  const [uuidLeague, setUuidLeague] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [leagues, setLeagues] = useState([]);
  const [clubs, setClubs] = useState([]);
  const alert = useAlert();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  const onFileChange = (event) => {
    if (selectedFiles.length < 6) {
      setDirty();
      const imageURL = URL?.createObjectURL(event.target.files[0]);
      const image = { file: event.target.files[0], imageURL };
      setSelectedFiles([...selectedFiles, image]);
    }
  };

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  useEffect(() => {
    setSubmitted(false);
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    //setClubs(data.data.items.filter((it) => it.isActive));
    setClubs(data.data.items);
  };

  useEffect(() => {
    props.setTitle("Avatars");
  }, []);

  const onDeleteImage = (index, e) => {
    e.preventDefault();
    const result = selectedFiles.filter((_, indexItem) => indexItem !== index);
    setSelectedFiles(result);
  };

  const uploadAvatar = async (e) => {
    setSubmitted(true);
    if (!validateRequired()) {
      setShowModalError(true);
    }
    e.preventDefault();
    try {
      const promises = selectedFiles.map(async (element) => {
        const file = element.file;
        var formData = new FormData();
        formData.append("image", file);
        formData.append("leagueUuid", uuidLeague);
        formData.append("clubUuid", uuidClub);
        return await addAvatar(formData);
      });
      await Promise.all(promises);
      setSelectedFiles([]);
    } catch (error) {
      setShowModalError(true);
    }
  };

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (!uuidClub && uuidLeague !== "generic") return false;
    if (!selectedFiles) return false;
    return true;
  };

  return (
    <>
      <div className="container-create-avatars">
        <Button
          event="Modificar Avatars"
          action={() => props.history.push("/main/updateAvatar")}
          isBig
        />
      </div>
      <form>
        <fieldset className="fieldset-avatar">
          <select
            style={{
              width: 300,
              backgroundColor: "#303030",
              borderRadius: 4,
              color: "white",
            }}
            className="select"
            value={uuidLeague}
            onChange={({ target: { value } }) => {
              setUuidLeague(value);
              setDirty();
            }}
            required
          >
            <option name={""} value={""}>
              Selecciona un liga...
            </option>
            <option name={""} value={"generic"}>
              Avatar genérico.
            </option>
            {leagues?.map((item) => (
              <option name={item.uuid} value={item.uuid}>
                {item.name}
              </option>
            ))}
          </select>

          <div>
            {submitted && !uuidLeague && (
              <small className="p-invalid transformY-20">
                Debe seleccionar una liga.
              </small>
            )}
          </div>
          {uuidLeague && uuidLeague !== "generic" && (
            <>
              <select
                style={{
                  width: 300,
                  backgroundColor: "#303030",
                  borderRadius: 4,
                  color: "white",
                }}
                className="select"
                onChange={({ target: { value } }) => {
                  setUuidClub(value);
                  setDirty();
                }}
                value={uuidClub}
              >
                <option name={""} value={""}>
                  Elige un club...
                </option>

                {clubs?.map((item) => (
                  <option name={item.uuid} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div>
                {submitted && !uuidClub && uuidLeague !== "generic" && (
                  <small className="p-invalid">El club es obligatorio.</small>
                )}
              </div>
            </>
          )}
          <div className="container-avatar">
            <p style={{ color: "#626262" }}>
              Las imágenes no deben pesar mas de 5MB
            </p>

            <input
              className="input-add-avatar"
              type="file"
              onChange={onFileChange}
              required
            />
            {submitted && selectedFiles.length === 0 && (
              <small className="p-invalid">Debe seleccionar una imagen.</small>
            )}
            <div className="container-files">
              {selectedFiles?.map((item, index) => (
                <div style={{ marginRight: 10 }} key={index}>
                  <button
                    className="delete-avatar"
                    onClick={(e) => {
                      setDirty();
                      onDeleteImage(index, e);
                    }}
                  >
                    X
                  </button>
                  <img src={item.imageURL} className="image" />
                </div>
              ))}
            </div>
            <button
              className="button-upload"
              onClick={async (e) => {
                await uploadAvatar(e);
                setPristine();
                alert.show("Avatar subido con éxito", { type: "success" });
                props.history.push("/main/updateAvatar");
                //props.history.goBack();
              }}
            >
              SUBIR
            </button>
          </div>
        </fieldset>
      </form>
      {showModalError && (
        <ModalError
          title="ERROR AGREGANDO AVATAR/S"
          text={"agregar avatar/s"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
      {Prompt}
    </>
  );
};

export default connect(null, {
  setTitle,
})(AvatarPage);
