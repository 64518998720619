import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "./userForm.css";
import Input from "../../ui/input/input";
import Button from "../../ui/button/button";
import {
  editUser,
  createUser,
  downgradeUser,
  upgradeUser,
} from "src/services/UserServices";
import { getClubsAdmin } from "src/services/ClubService";
import { getAvatarsByClub } from "src/services/AvatarService";
import { useAlert } from "react-alert";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import Modal from "src/ui/modal/modal";
const emailPattern = /^([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/gi;

const UserForm = ({
  user,
  setOpenModal,
  onSubmitForm,
  setDirty,
  setPristine,
}) => {
  const [error, setError] = useState("");
  const [clubs, setClubs] = useState(null);
  const [avatars, setAvatars] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const isNew = typeof user === "undefined";
  const [loading, setLoading] = useState(false);
  const [statusMessageConfirm, setStatusMessageConfirm] = useState("");
  const [statusMessageConfirmVisible, setStatusMessageConfirmVisible] =
    useState(false);
  const [unSuscribe, setUnSuscribe] = useState(false);
  const [changeAvatar, setChangeAvatar] = useState(false);

  const status =
    isNew || user?.status !== "PENDING"
      ? [{ status: "NOT_SUBSCRIBED" }, { status: "SUBSCRIBED" }]
      : [
          { status: "NOT_SUBSCRIBED" },
          { status: "SUBSCRIBED" },
          { status: "PENDING" },
        ];

  const subscriptionpendingstatus = [
    { status: "" },
    { status: "SUBSCRIPTION_PENDING" },
    { status: "UNSUBSCRIPTION_PENDING" },
  ];

  const roles = [
    { status: "FREE" },
    { status: "DIGITAL_PARTNER" },
    { status: "MANAGING_PARTNER" },
  ];

  const alert = useAlert();
  const canEditStatus = !isNew && user?.role !== "FREE";
  const hasMembership = user?.membershipNumber;
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    setValue,
    getValues,
    resetField,
  } = useForm({
    defaultValues: {
      name: user?.name || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      dni: user?.dni || null,
      membershipNumber: user?.membershipNumber || "",
      status: user?.status,
      subscriptionpendingstatus: user?.subscriptionpendingstatus,
      phone: user?.phone,
      club: "",
      avatar: user?.avatar,
      role: user?.userClub[0]?.role,
    },
  });

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items.filter((item) => item.isActive);
    setClubs(filtered);
  };

  const getAllAvatars = async () => {
    let clubUuid = null;

    if (isNew) {
      const _clubUuid = getValues("club");
      if (_clubUuid && _clubUuid != "") clubUuid = _clubUuid;
    } else {
      clubUuid = user?.userClub[0]?.clubUuid;
    }

    const response = await getAvatarsByClub(clubUuid);
    setAvatars(response.data);
    if (isNew) {
      setAvatar(response.data[0]);
    } else {
      setAvatar(user?.avatar);
    }
  };

  const onSelectClub = () => {
    //setDirty();
    //setAvatar(null);
  };

  useEffect(() => {
    getAllClubs();
    getAllAvatars();
  }, []);

  const handleFormSubmit = async (data) => {
    setLoading(true);

    data.avatar = avatar.uuid;
    const editData = { ...data };

    const create = {
      ...data,
      membershipNumber: data?.membershipNumber || null,
      dni: data?.dni || null,
      avatar: avatar.uuid,
      phone: data.phone,
    };

    try {
      if (isNew) await createUser(create);
      else await editUser(user.id, editData, user.club);
      setPristine();
      setLoading(false);
      onSubmitForm();
      setOpenModal(false);
      alert.show(`Usuario ${isNew ? "creado" : "editado"} con exito`, {
        type: "success",
      });
    } catch (e) {
      setLoading(false);
      setError(e.response.data.message);
    }
  };

  const onChangeAvatar = (uuid, url) => {
    setChangeAvatar(false);
    setAvatar({ uuid, url });
    setDirty();
  };

  const cancelStatusMessageConfirm = (e) => {
    setValue("status", user?.status);
    setStatusMessageConfirmVisible(false);
  };

  const buttonState = loading
    ? "LOADING"
    : `${isNew ? "Crear" : "Editar"} usuario`;

  if (!clubs) return null;

  return (
    <section className="wrapper">
      <form
        className="form-edit-user"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <div className="subheader-container">
          <h4>{isNew ? "Nuevo" : "Editar"} usuario</h4>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            alignItems: "flex-start",
          }}
        >
          {!changeAvatar && isNew && (
            <div className="user-select">
              <h4>Club</h4>
              <select
                {...register("club", {
                  required: true,
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                className="custom-select"
                // onChange={onSelectClub()}
              >
                {/* {!hasClub && <option>Selecciona un club</option>} */}
                {clubs.map((item) => (
                  <option key={item.uuid} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!changeAvatar && !isNew && (
            <>
              <h4>Club</h4>
              {user?.userClub[0].club.name}
            </>
          )}

          {!changeAvatar && (
            <>
              <h4 style={{ marginTop: 10 }}>Nombre</h4>
              <Input
                name="name"
                {...register("name", {
                  required: true,
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                action={() => {}}
                type="text"
                placeholder="Nombre"
                isForm
                maxLength="100"
              />

              <h4>Apellido</h4>
              <Input
                name="lastName"
                isForm
                {...register("lastName", {
                  required: true,
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="text"
                placeholder="Apellido"
                maxLength="100"
              />
              <h4>Email</h4>
              <Input
                name="email"
                isForm
                {...register("email", {
                  required: true,
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="email"
                placeholder="Email"
                maxLength="250"
              />
            </>
          )}

          {!changeAvatar && isNew && (
            <>
              <h4>Contraseña</h4>
              <Input
                name="password"
                isForm
                {...register("password", {
                  required: true,
                  minLength: 6,
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="text"
                placeholder="Contraseña"
              />
            </>
          )}
          {!changeAvatar && (
            <>
              <h4>Teléfono</h4>
              <Input
                name="phone"
                isForm
                {...register("phone", {
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="text"
                placeholder="Teléfono"
                maxLength="130"
              />
              <h4>Documento de identidad</h4>
              <Input
                name="dni"
                isForm
                {...register("dni", {
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="text"
                placeholder="Documento de identidad"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxLength="20"
                // min="1"
                // max="99999999999999999999"
              />
              <h4>Número de Socio</h4>
              <Input
                name="membershipNumber"
                isForm
                {...register("membershipNumber", {
                  onChange: (e) => {
                    setDirty();
                  },
                })}
                type="text"
                placeholder="Numero de socio"
                maxLength="200"
              />

              <div className="user-select">
                <h4>Rol</h4>
                <select
                  {...register("role", {
                    onChange: (e) => {
                      setDirty();
                    },
                  })}
                  className="custom-select"
                >
                  {roles.map((item) => (
                    <option key={item.length}>{item?.status}</option>
                  ))}
                </select>
              </div>
              <div>
                <h4>Avatar</h4>
                <img
                  src={avatar.url}
                  style={{
                    width: "20%",
                    height: "20%",
                    objectFit: "cover",
                    objectPosition: "top",
                    overflow: "hidden",
                  }}
                  onClick={() => {
                    getAllAvatars();
                    setChangeAvatar(true);
                  }}
                ></img>
              </div>
              <div
                style={{ alignSelf: "center", cursor: "pointer" }}
                onClick={() => {
                  getAllAvatars();
                  setChangeAvatar(true);
                }}
              >
                Cambiar avatar
              </div>
            </>
          )}

          {changeAvatar && avatars && (
            <div className="user-select">
              <h4>Seleccione un Avatar</h4>
              <ul
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "10px",
                  flexWrap: "wrap",
                  listStyle: "none",
                  width: "100%",
                  margin: 0,
                }}
              >
                {avatars?.map((item) => (
                  <li
                    style={{
                      border: " 2px solid #252525",
                      width: "20%",
                      borderRadius: "10px",
                    }}
                  >
                    <img
                      src={item.url}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                        overflow: "hidden",
                      }}
                      onClick={() => onChangeAvatar(item.uuid, item.url)}
                    ></img>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <p className="error-message-suscription">{error}</p>
        </div>
        {!changeAvatar && (
          <Button event={buttonState} type={"submit"} loading={loading} />
        )}
      </form>

      {changeAvatar && (
        <Button event="Cancelar" action={() => setChangeAvatar(false)}></Button>
      )}

      {statusMessageConfirmVisible && (
        <Modal closeModal={() => cancelStatusMessageConfirm()} key="modal">
          <ConfirmModal
            content={statusMessageConfirm}
            title={"Cambio de Estado"}
            mainButtonText={"Aceptar"}
            onCancel={() => cancelStatusMessageConfirm()}
            onAccept={() => setStatusMessageConfirmVisible(false)}
          />
        </Modal>
      )}
    </section>
  );
};

export default UserForm;
