export default function (baseApi) {
  return {
    INTERVALS: {
      LOGOUT_TIME: 3600
    },
    API_URL: `${baseApi}`,
    IS_PRODUCTION: true,
    IS_DEVELOPMENT: false
  };
}
