import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../../store/actions/generalActions";
import GoBack from "../../../components/goBack/goBack";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import "./index.css";
import ModalError from "src/ui/modalError/modalError";
import "react-datepicker/dist/react-datepicker.css";
import { useAlert } from "react-alert";
import { createClubPost, editClubPost } from "src/services/CommunityService";
import useUnsavedChangesWarning from "../../../hooks/useUnsavedChangesWarning";

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];

const CreateProject = (props) => {
  const community = props?.location?.state?.community;
  const fromEdit = !!community;

  const [uuid, setUuid] = useState(fromEdit ? community.uuid : "");
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState("");
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState("");
  const [updateImage, setUpdateImage] = useState(false);
  const [description, setDescription] = useState(
    fromEdit ? community.description : ""
  );
  // const [cost, setCost] = useState(fromEdit ? community.cost : "");
  // const [amount, setAmount] = useState(fromEdit ? community.amount : "");
  // const [startDate, setStartDate] = useState(
  //   fromEdit ? new Date(community.startDate) : new Date()
  // );
  // const [finishDate, setFinishDate] = useState(
  //   fromEdit ? new Date(community.finishDate) : new Date()
  // );
  // const [deliveryDate, setDeliveryDate] = useState(
  //   fromEdit ? new Date(community.deliveryDate) : new Date()
  // );
  const [selectedImages, setSelectedImages] = useState([]);
  const [fileBanner, setFileBanner] = useState(
    fromEdit
      ? {
          file: null,
          imageURL:
            community.files && community.files.length > 0
              ? community.files[0].link
              : null,
        }
      : null
  );
  // const [activeValue, setActiveValue] = useState(null);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const fileInputRef = useRef();
  const alert = useAlert();

  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} Post`);
  }, []);

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  useEffect(() => {
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const onChangeDescription = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    const imageURL = URL.createObjectURL(files);
    const image = { file: files, imageURL };
    setSelectedImages([...selectedImages, image]);
  };

  const onFileBannerChange = (event) => {
    setDirty();
    const imageURL = URL.createObjectURL(event.target.files[0]);
    const image = { file: event.target.files[0], imageURL };
    setFileBanner(image);
    setUpdateImage(true);
  };

  const onDeleteImage = (index, e) => {
    setDirty();
    e.preventDefault();
    setFileBanner(null);
    setUpdateImage(true);
  };

  const onCreatePost = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("description", description);
      fileBanner && formData.append("file", fileBanner.file);
      await createClubPost(uuidClub, formData);
      alert.show("Post creado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/community");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const onEditPost = async () => {
    setError("");
    try {
      var formData = new FormData();
      formData.append("description", description);
      formData.append("updateFile", updateImage ? "SI" : "NO");
      fileBanner && formData.append("file", fileBanner.file);
      await editClubPost(uuid, formData);
      alert.show("Post editado con exito", { type: "success" });
      setPristine();
      props.history.push("/main/community");
    } catch (error) {
      setError(error?.response?.data?.message);
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    if (fromEdit) {
      onEditPost();
    } else onCreatePost();
  };

  return (
    <>
      <>
        <div className="contain-create">
          <form>
            {!fromEdit && (
              <>
                {" "}
                <div className="contain-league">
                  <select
                    style={{
                      width: 300,
                      backgroundColor: "#303030",
                      borderRadius: 4,
                      color: "white",
                    }}
                    className="select"
                    onChange={({ target: { value } }) => {
                      setUuidLeague(value);
                      setDirty();
                    }}
                  >
                    <option name={""} value={""}>
                      Elige una liga...
                    </option>

                    {leagues?.map((item) => (
                      <option name={item.uuid} value={item.uuid}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  <select
                    style={{
                      width: 300,
                      backgroundColor: "#303030",
                      borderRadius: 4,
                      color: "white",
                    }}
                    className="select"
                    onChange={({ target: { value } }) => {
                      setUuidClub(value);
                      setDirty();
                    }}
                  >
                    <option name={""} value={""}>
                      Elige un club...
                    </option>

                    {clubs?.map((item) => (
                      <option name={item.uuid} value={item.uuid}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>{" "}
              </>
            )}

            <h2 className="title-banner">Imagen o Video</h2>
            <div className="container-banner">
              <div className="container-files">
                {fileBanner && (
                  <div style={{ marginRight: 10 }} key={0}>
                    <button
                      className="delete-banner"
                      onClick={(e) => {
                        onDeleteImage(0, e);
                      }}
                    >
                      X
                    </button>
                    <img src={fileBanner.imageURL} className="image" />
                  </div>
                )}
              </div>
              <input
                className="form-control"
                type="file"
                onChange={onFileBannerChange}
              />
            </div>

            <hr />

            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />

            <div>
              <div className="container-text-area-decision">
                <textarea
                  placeholder="Descripción Experiencia"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDescription}
                  value={description}
                  maxLength="1000"
                />
              </div>
            </div>
          </form>
        </div>
        <div className="footer">
          <button
            disabled={disabledSaveButton}
            className="btn-create-club"
            onClick={(e) => {
              handleClick(e);
            }}
          >
            GUARDAR
          </button>
        </div>
        {showModalError && (
          <ModalError
            title="ERROR CREANDO POST"
            text={`crear el post: ${error}`}
            closeModal={(e) => {
              setShowModalError(e);
              setDisabledSaveButton(false);
            }}
            key="modal"
          />
        )}
        {Prompt}
      </>
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateProject);
