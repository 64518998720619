import React from "react";
import Button from "../../ui/button/button";
import "./confirmModal.css";

const ConfirmModal = ({title, modalMessage, modalState, content, mainButtonText, onCancel, onAccept}) => {
	return (
		<section className='wrapper confirm-modal'>
			<div className="subheader-container">
				<h4>{title}</h4>
			</div>
			{modalMessage && (
				<p className={`modal-state ${modalState}`}>
					{modalMessage}
				</p>
			)}
			<div>
				{content}
			</div>
			<div className='button-actions'>
				<Button
					event={`Cancelar`}
					type={'button'}
					action={() => onCancel && onCancel()}
				/>
				<Button
					event={mainButtonText}
					variant={"error"}
					type={'button'}
					action={() => onAccept && onAccept()}
				/>
			</div>
		</section>
	);
};

export default ConfirmModal;
