import {
  getRefreshToken,
  getToken,
  isRejectedEndpoints,
  setToken,
} from "../services/utils";
import axios from "axios";
import environment from "environment";
import { store } from "src/store/store";
import { logout } from "src/services/AuthService";
import { setTooltips } from "src/store/actions/generalActions";

export const BASE_URL = environment.API_URL;

console.log("base", BASE_URL);
export const api = axios.create({
  baseURL: BASE_URL,
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      console.log(error);
      localStorage.removeItem("persist:auth");
      window.location.replace("/login");
      store.dispatch(logout());
      store.dispatch(setTooltips(false));
    } else {
      return Promise.reject(error);
    }
  }
);

api.interceptors.request.use(
  async (config) => {
    // const isUserEndpoint = isUserEndpoints(config.url);
    // const clubUuid = await getClubIdFromStorage();
    const isRejectedTokenEndpoint = isRejectedEndpoints(config.url);
    const token = getToken();
    if (token && !isRejectedTokenEndpoint) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    // if (token && isUserEndpoint && clubUuid !== null) {
    //   config.headers["club"] = clubUuid;
    // }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
