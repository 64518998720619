import React, { useState } from "react";
import "./error.css";
import sprite from "../../assets/icons/iconsprite.svg";

const ModalError = (props) => {
  const [modalClose, setmodalClose] = useState(false);

  const closeModal = (e) => {
    e.preventDefault();
    setmodalClose(true);
    setTimeout(() => {
      props.closeModal(false);
    }, 600);
  };

  return (
    <>
      <div
        onClick={(e) => closeModal(e)}
        className={
          modalClose ? "modal-container container-out" : "modal-container"
        }
      ></div>
      <div className="modal-wrapper">
        <div className={modalClose ? "modal-content out" : "modal-content"}>
          <div className="modal-close">
            <span onClick={(e) => closeModal(e)}>
              <svg className="close">
                <use href={sprite + "#close"}></use>
              </svg>
            </span>
          </div>
          <div className="container-children">
            <h4>{props.title}</h4>
            <p>Error: {props.text}.</p>
            <button
              style={{ padding: 4, marginTop: 10 }}
              onClick={(e) => closeModal(e)}
              type="button"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalError;
