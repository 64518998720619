import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getClubsAdminByLeague,
  getLeaguesAdmin,
} from "src/services/ClubService";
import { postNewDecision, updateDecision } from "src/services/DecisionServices";
import ModalError from "src/ui/modalError/modalError";
import dragImage from "../../assets/images/dragImage.png";
import { setTitle, setUuidDecision } from "../../store/actions/generalActions";
import "./createDecision.css";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const DAY_IN_MILISECONDS = 172800000;

const optionsEdit = [
  { value: true, label: "Activo" },
  { value: false, label: "No Activo" },
];
const optionsHideResult = [
  { value: true, label: "Ocultar resultado hasta finalización" },
  { value: false, label: "Mostrar siempre el resultado" },
];

const CreateDecision = (props) => {
  const decision = props?.location?.state?.decision;
  const fromEdit = !!decision;
  const [errorDate, setErrorDate] = useState(false);
  const [startDate, setStartDate] = useState(
    fromEdit ? new Date(decision.startDate) : new Date()
  );
  const [finishDate, setFinishDate] = useState(
    fromEdit ? new Date(decision.finishDate) : new Date()
  );
  const [selectedFile, setSelectedFile] = useState(
    fromEdit ? { imageURL: decision.image } : null
  );
  const [title, setTitle] = useState(fromEdit ? decision.title : "");
  const [description, setDescription] = useState(
    fromEdit ? decision.description : ""
  );
  const [leagues, setLeagues] = useState([]);
  const [uuidLeague, setUuidLeague] = useState(
    fromEdit ? decision.club.league.uuid : null
  );
  const [clubs, setClubs] = useState([]);
  const [uuidClub, setUuidClub] = useState(
    fromEdit ? decision.club.uuid : null
  );
  const [activeValue, setActiveValue] = useState(
    fromEdit ? decision.isActive : null
  );
  const [hideResultOnActive, setHideResultOnActive] = useState(
    fromEdit ? decision.hideResultOnActive : false
  );

  const [showModalError, setShowModalError] = useState(false);
  const fileInputRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const alert = useAlert();
  const rdPickerRef = useRef();
  const rdPickerRef2 = useRef();
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  console.log(rdPickerRef);
  console.log(decision, fromEdit, title, description);

  useEffect(() => {
    setSubmitted(false);
    setErrorDate(false);
    setErrorModalText("");
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} Decisión`);
  }, []);

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }
    if (!selectedFile?.imageURL) return false;
    return true;
  };

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const onChangeDesc = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const getAllLeagues = async () => {
    const data = await getLeaguesAdmin();
    setLeagues(data.data.items.filter((it) => it.isActive));
  };

  const getClubs = async (uuidLeague) => {
    const data = await getClubsAdminByLeague(uuidLeague);
    console.log(data.data.items);
    setClubs(data.data.items.filter((it) => it.isActive));
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFile(image);
    }
  };

  const editDecision = async () => {
    try {
      const formData = new FormData();
      formData.append("club", uuidClub);
      formData.append("title", title);
      formData.append("description", description);
      formData.append("isActive", activeValue);
      startDate !== new Date(decision.startDate) &&
        formData.append("startDate", startDate);
      finishDate !== new Date(decision.finishDate) &&
        formData.append("finishDate", finishDate);
      selectedFile?.file && formData.append("file", selectedFile.file);
      formData.append("hideResultOnActive", hideResultOnActive);
      await updateDecision(decision.uuid, formData);
      alert.show("Decisión editada con éxito", { type: "success" });
      setPristine();
      history.push("/main/decisions");
    } catch (error) {
      alert.show("Error al editar decisión", { type: "error" });
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const uploadDecision = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("startDate", startDate);
      formData.append("finishDate", finishDate);
      formData.append("club", uuidClub);
      formData.append("file", selectedFile.file);
      formData.append("isActive", activeValue);
      formData.append("hideResultOnActive", hideResultOnActive);
      const response = await postNewDecision(formData, uuidClub);
      setTitle("");
      setDescription("");
      setStartDate(new Date());
      setFinishDate(new Date());
      setSelectedFile(null);
      setErrorDate(false);
      setPristine();
      history.push({
        pathname: "/main/option",
        state: { decision: response.data },
      });
    } catch (error) {
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const onContinue = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);

    if (!validateRequired()) {
      setErrorModalText("Existen campos requeridos sin dato");
      setShowModalError(true);
      return;
    }

    if (finishDate - startDate < DAY_IN_MILISECONDS) {
      setErrorModalText("Error en las fechas");
      setErrorDate(true);
      setShowModalError(true);
      return;
    }

    if (fromEdit) editDecision();
    else uploadDecision();
  };

  useEffect(() => {
    getAllLeagues();
  }, []);

  useEffect(() => {
    getClubs(uuidLeague);
  }, [uuidLeague]);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFile(image);
    }
  };

  return (
    <>
      <div className="contain-create">
        <form>
          <div className="container-crate-decisión">
            <>
              {" "}
              <select
                // className="select-league-decision"
                style={{
                  width: 300,
                  backgroundColor: "#303030",
                  borderRadius: 4,
                  color: "white",
                }}
                className="select"
                value={uuidLeague}
                onChange={({ target: { value } }) => {
                  setUuidLeague(value);
                  setDirty();
                }}
                required
              >
                <option name={""} value={""}>
                  Elige una liga...
                </option>

                {leagues?.map((item) => (
                  <option name={item.uuid} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div>
                {submitted && !uuidLeague && (
                  <small className="p-invalid transformY-20">
                    Seleccionar la liga es obligatorio.
                  </small>
                )}
              </div>
              <select
                style={{
                  width: 300,
                  backgroundColor: "#303030",
                  borderRadius: 4,
                  color: "white",
                }}
                className="select"
                value={uuidClub}
                onChange={({ target: { value } }) => {
                  setUuidClub(value);
                  setDirty();
                }}
                required
              >
                <option name={""} value={""}>
                  Elige un club...
                </option>

                {clubs?.map((item) => (
                  <option name={item.uuid} value={item.uuid}>
                    {item.name}
                  </option>
                ))}
              </select>
              <div>
                {submitted && !uuidClub && (
                  <small className="p-invalid transformY-20">
                    Seleccionar el club es obligatorio.
                  </small>
                )}
              </div>
            </>

            <select
              // className="select-league-decision"
              style={{
                width: 300,
                backgroundColor: "#303030",
                borderRadius: 4,
                color: "white",
              }}
              className="select"
              value={activeValue}
              onChange={({ target: { value } }) => {
                setActiveValue(value);
                setDirty();
              }}
              required
            >
              {!fromEdit && (
                <option name={""} value={""}>
                  Selecciona un estado...
                </option>
              )}

              {optionsEdit.map((item) => (
                <option name={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>

            <select
              // className="select-league-decision"
              style={{
                width: 300,
                backgroundColor: "#303030",
                borderRadius: 4,
                color: "white",
              }}
              className="select"
              value={hideResultOnActive}
              onChange={({ target: { value } }) => {
                setHideResultOnActive(value);
                setDirty();
              }}
              required
            >
              {/* {!fromEdit && (
                <option name={""} value={""}>
                  Determine si los resultados se verán aunque no se haya
                  finalizado la decisión...
                </option>
              )} */}

              {optionsHideResult.map((item) => (
                <option name={item.label} value={item.value}>
                  {item.label}
                </option>
              ))}
            </select>
            {/* <div>
              {submitted && !activeValue && (
                <small className="p-invalid transformY-20">
                  El estado es obligatorio.
                </small>
              )}
            </div> */}

            <div className="div-title">
              <div className="container-input-decision">
                <input
                  onChange={onChangeTitle}
                  value={title}
                  name="title"
                  type="text"
                  className="input-title"
                  placeholder="Agregá el título de la decisión"
                  required
                  maxLength={255}
                />
              </div>
              <div>
                {submitted && !title && (
                  <small className="p-invalid">El título es obligatorio.</small>
                )}
              </div>
            </div>

            <div>
              <div className="container-text-area-decision">
                <textarea
                  value={description}
                  placeholder="Agregá una descripción"
                  className="text-desc"
                  type="textarea"
                  line="5"
                  onChange={onChangeDesc}
                  required
                  maxLength={255}
                />
              </div>
            </div>
            <div>
              {submitted && !description && (
                <small className="p-invalid">
                  La descripción es obligatoria.
                </small>
              )}
            </div>
            <div className="img-input">
              <div
                onDragOver={dragOver}
                onDragEnter={dragEnter}
                onDragLeave={dragLeave}
                onDrop={fileDrop}
                className={!selectedFile ? "drop-container" : ""}
                onClick={fileInputClicked}
              >
                {!selectedFile ? (
                  <div className="container-selected-decision-img">
                    <img height={65} width={65} src={dragImage} />
                    <p style={{ color: "#626262" }}>
                      Arrastrá o agregá una imagen
                    </p>
                  </div>
                ) : (
                  <img src={selectedFile.imageURL} height={200} width={200} />
                )}
              </div>
              <p style={{ color: "#626262" }}>
                Las imágenes no deben pesar mas de 5MB
              </p>
            </div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple
              onChange={filesSelected}
            />
            <div>
              {submitted && !selectedFile?.imageURL && (
                <small className="p-invalid">
                  La imagen no puede estar vacía.
                </small>
              )}
            </div>
            <div className="container-date-picker">
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de inicio</h4>
                <DatePicker
                  style={{ backgroundColor: "red", color: "green" }}
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                  ref={rdPickerRef}
                  required
                />
                <div>
                  {submitted && !startDate && (
                    <small className="p-invalid">
                      La fecha inicio es obligatoria.
                    </small>
                  )}
                </div>
              </div>
              <div className="date-picker">
                <h4 className="h4-text-decision">Fecha de cierre</h4>
                <DatePicker
                  selected={finishDate}
                  onChange={(date) => {
                    setFinishDate(date);
                    setDirty();
                  }}
                  dateFormat="dd/MM/yyyy"
                  ref={rdPickerRef2}
                  required
                />
                {errorDate && (
                  <p style={{ color: "red" }}>
                    La fecha de cierre debe ser{" "}
                    {DAY_IN_MILISECONDS / (1000 * 60 * 60 * 24)} días mayor que
                    la fecha inicio. Seleccionar una fecha válida
                  </p>
                )}
                <div>
                  {submitted && !finishDate && (
                    <small className="p-invalid">
                      La fecha cierre es obligatoria.
                    </small>
                  )}
                </div>
              </div>
            </div>

            <button
              disabled={disabledSaveButton}
              className="create-decision-btn"
              onClick={onContinue}
            >
              CONTINUAR
            </button>
          </div>
        </form>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR CREANDO DECISIÓN"
          text={"crear esta desición. " + errorModalText}
          closeModal={(e) => {
            setDisabledSaveButton(false);
            setErrorModalText("");
            setShowModalError(e);
          }}
          key="modal"
        />
      )}
      {Prompt}
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateDecision);
