import React, { Component } from "react";

import "./select.css";

class SelectBox extends Component {
  state = {
    items: this.props.items || [],
    showItems: false,
  };
  UNSAFE_componentWillMount = () => {
    document.addEventListener("mousedown", this.handleClick, false);
  };
  componentWillUnmount = () => {
    document.removeEventListener("mousedown", this.handleClick, false);
  };
  handleClick = (e) => {
    if (this.node.contains(e.target)) return;
    this.handleClickOutside();
  };
  handleClickOutside = () => {
    this.setState({
      showItems: false,
    });
  };
  dropDown = () => {
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };
  shouldComponentUpdate = (nextProps, nextState) => {
    if (
      this.state.showItems !== nextState.showItems ||
      this.props.selectedItem !== nextProps.selectedItem ||
      this.props.items !== nextProps.items
    ) {
      return true;
    }
    return false;
  };
  selectItem = (item) => {
    this.props.id
      ? this.props.newItemSelected(this.props.id, item.value)
      : this.props.newItemSelected(item.value);
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };

  selectItemEmpty = () => {
    this.props.newItemSelectedEmpty(this.props.keyName, null);
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };

  render() {
    let selectedItem = this.props.selectedItem
      ? this.props.selectedItem
      : this.props.nameSelect || "";
    let before = this.props.before ? "before" : "";
    let emptyFilterLabel = this.props.emptyFilterLabel
      ? this.props.emptyFilterLabel
      : "";
    let items = this.props.items;
    return (
      <>
        <div
          ref={(node) => (this.node = node)}
          className="select-box--box"
          style={{ width: this.props.width || 180 }}>
          <div className="select-box--container">
            {this.props.showSelectedItem && before ? (
              <span
                className={`status status--${selectedItem.toLowerCase()}`}></span>
            ) : null}

            <div
              className={`select-box--selected-item ${
                this.props.showSelectedItem ? before : ""
              }`}>
              {this.props.showSelectedItem
                ? selectedItem.toLowerCase()
                : this.props.nameSelect}
            </div>
            <div className="select-box--arrow" onClick={this.dropDown}>
              <span
                className={`${
                  this.state.showItems
                    ? "select-box--arrow-up"
                    : "select-box--arrow-down"
                }`}
              />
            </div>
            <div
              className="select-box--items"
              style={{ display: this.state.showItems ? "block" : "none" }}>
              {this.props.emptyFilterLabel &&
              selectedItem !== this.props.nameSelect ? (
                <div onClick={() => this.selectItemEmpty()}>
                  {emptyFilterLabel}
                </div>
              ) : null}
              {items.map((item) => (
                <div
                  key={item.id}
                  onClick={() => this.selectItem(item)}
                  className={`${before} ${
                    selectedItem.toLowerCase() === item.value.toLowerCase()
                      ? "selected"
                      : ""
                  }`}>
                  {before ? (
                    <span
                      className={`status status--${item.value.toLowerCase()}`}></span>
                  ) : null}
                  {item.value ? item.value.toLowerCase() : ""}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SelectBox;
