import { api } from "../config/api";

const OPTION = "decisions/option";

export const createNewOption = (formData) => api.post(OPTION, formData);

export const optionVote = (data) => api.post(`decisions/vote`, data);

export const deleteOption = (uuid) => api.delete(`decisions/option/${uuid}`);

export const updateOption = (formData, uuid) =>
  api.put(`decisions/option/${uuid}`, formData);

export const getOptions = (uuid) => api.get(`decisions/admin/${uuid}`);
