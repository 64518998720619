import React from "react";

const backdrop = (props) => {
  return (
    <div
      style={{
        position: "absolute",
        width: "100%",
        zIndex: 998,
        height: "100vh",
        background: "#000",
        opacity: "0.64",
      }}
      onClick={() => props.action()}></div>
  );
};

export default backdrop;
