import React, { useState, useEffect } from "react";
import "./winnersForm.css";
import Table from "src/components/table/Table";
import { useAlert } from "react-alert";
import "./table.css";
import { getWinners } from "src/services/RaffleService";
import { postNewDecision } from "src/services/DecisionServices";
const emailPattern = /^([\w.\-_]+)?\w+@[\w-_]+(\.\w+){1,}$/gi;

const WinnersForm = ({ raffleUuid }) => {
  const [tableData, setTableData] = useState([]);
  const [haveWinners, setHaveWinners] = useState(false);

  const fieldAndHeaders = {
    POSICION: "position",
    EMAIL: "email",
  };

  const alert = useAlert();

  const refresh = async () => {
    const response = await getWinners(raffleUuid);
    if (response.data && response.data.length > 0) {
      setHaveWinners(true);
      const data = response.data.map((w) => {
        return {
          position: w.position,
          email: w.user.email,
        };
      });
      setTableData(data);
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <section className="wrapper">
      {!haveWinners && (
        <h3 style={{ marginBottom: "10px" }}>No hay ganadores</h3>
      )}
      {haveWinners && (
        <div className="content">
          <h3 style={{ marginBottom: "10px" }}>Ganadores</h3>
          <Table
            fieldAndHeaders={fieldAndHeaders}
            items={tableData}
            type={"LIST"}
          />
        </div>
      )}
    </section>
  );
};

export default WinnersForm;
