import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import { setError, openMenu } from "../../store/actions/generalActions";
import { getUserName } from "../../store/actions/registerActions";
import { Switch, Route, Redirect } from "react-router";

import Aside from "../../components/layout/aside/aside";
import Main from "../../components/layout/main/main";
import BackDrop from "../../ui/backdrop/backdrop";

import Home from "../home/home";
import TestPage from "../testPage/testPage";
import Users from "../users";
import AvatarPage from "../avatarPage/avatarPage";
import UpdateAvatar from "../updateAvatar/updateAvatar";
import CreateClub from "../createClub/createClub";
import EditClub from "../editClub/editClub";
import { ReactComponent as OpenMenuSvg } from "../../assets/icons/open-menu.svg";
import Clubs from "../clubs";
import Decisions from "../decisions/decisions";
import createDecision from "../createDecision/createDecision";
import Leagues from "../leagues/leagues";
import Notification from "../notifications/notifications";
import CreateNotification from "../notifications/create/index";
import createLeagues from "../createLeagues/createLeagues";
import Partners from "../partners";
import PartnersCreate from "../partnersCreate";
import Projects from "../projects";
import CreateProject from "../projects/create";
import EditProject from "../projects/create";
import Investment from "../investment/investment";
import CreateOptions from "../createOptions/createOptions";
import Option from "../option/option";
import OptionsList from "../optionsList/optionsList";
import Experience from "../experiences";
import CreateExperience from "../experiences/create";
import EditExperience from "../experiences/create";
import Community from "../community";
import CreateCommunity from "../community/create";
import EditCommunity from "../community/create";
import Raffle from "../raffles";
import CreateRaffle from "../raffles/create";
import EditRaffle from "../raffles/create";
import Payment from "../payments";
import Categories from "../categories";
import EditCategories from "../categories/edit";

const Layout = (props) => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  let url = props.match.url;

  useEffect(() => {
    document.body.classList.remove("fondo");
    props.getUserName();
  });

  props.history.listen((location, action) => {
    props.setError(false);
  });

  const handleOpenMenu = () => {
    props.openMenu();
  };

  useEffect(() => {
    const token = localStorage.getItem("access-Token");
    if (!token) props.history.push("/login");
  }, []);

  if (isMobile) {
    return (
      <React.Fragment>
        {props.menuOpen ? (
          <>
            <Aside responsive={isMobile} />
            <BackDrop action={handleOpenMenu} />
          </>
        ) : null}
        <Main responsive={isMobile}>
          <Switch>
            <Route exact path={url} component={Home} />
            <Route exact path={url + "/test"} component={TestPage} />
          </Switch>
          <div
            className="openMenu"
            style={{
              position: "absolute",
              right: "5%",
              top: "7%",
              zIndex: "99",
            }}
            onClick={() => handleOpenMenu()}
          >
            <OpenMenuSvg />
          </div>
        </Main>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Aside responsive={isMobile} showMenu={true} />
        <Main responsive={isMobile}>
          <Switch>
            <Route exact path={url + "/users"} component={Users} />
            <Route exact path={url + "/avatars"} component={AvatarPage} />
            <Route
              exact
              path={url + "/updateAvatar"}
              component={UpdateAvatar}
            />
            <Route exact path={url + "/createClub"} component={CreateClub} />
            <Route exact path={url + "/editClub"} component={EditClub} />
            <Route exact path={url + "/clubs"} component={Clubs} />
            <Route
              exact
              path={url + "/createDecisions"}
              component={createDecision}
            />
            <Route exact path={url + "/leagues"} component={Leagues} />
            <Route
              exact
              path={url + "/notifications"}
              component={Notification}
            />
            <Route
              exact
              path={url + "/notifications/create"}
              component={CreateNotification}
            />
            <Route exact path={url + "/decisions"} component={Decisions} />
            <Route
              exact
              path={url + "/createLeague"}
              component={createLeagues}
            />
            <Route exact path={url + "/partners"} component={Partners} />
            <Route
              exact
              path={url + "/partnersCreate"}
              component={PartnersCreate}
            />
            <Route
              exact
              path={url + "/createOptions"}
              component={CreateOptions}
            />
            <Route exact path={url + "/option"} component={Option} />
            <Route exact path={url + "/optionList"} component={OptionsList} />
            <Route exact path={url + "/projects"} component={Projects} />
            <Route
              exact
              path={url + "/projects/create"}
              component={CreateProject}
            />
            <Route
              exact
              path={url + "/projects/edit"}
              component={EditProject}
            />
            <Route
              exact
              path={url + "/projects/investment"}
              component={Investment}
            />
            <Route exact path={url + "/experiences"} component={Experience} />
            <Route
              exact
              path={url + "/experiences/create"}
              component={CreateExperience}
            />
            <Route
              exact
              path={url + "/experiences/edit"}
              component={EditExperience}
            />

            <Route exact path={url + "/community"} component={Community} />
            <Route
              exact
              path={url + "/community/create"}
              component={CreateCommunity}
            />
            <Route
              exact
              path={url + "/community/edit"}
              component={EditCommunity}
            />
            <Route exact path={url + "/payments"} component={Payment} />

            <Route exact path={url + "/raffles"} component={Raffle} />
            <Route
              exact
              path={url + "/raffles/create"}
              component={CreateRaffle}
            />
            <Route exact path={url + "/raffles/edit"} component={EditRaffle} />

            <Route exact path={url + "/categories"} component={Categories} />
            {/* <Route
              exact
              path={url + "/categories/create"}
              component={CreateCategories}
            /> */}
            <Route
              exact
              path={url + "/categories/edit"}
              component={EditCategories}
            />

            <Redirect from="/" to={url + "/users"} />
          </Switch>
        </Main>
      </React.Fragment>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    menuOpen: state.general.menuOpen,
  };
};

export default connect(mapStateToProps, {
  setError,
  openMenu,
  getUserName,
})(Layout);
