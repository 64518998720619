import { api } from "../config/api";

export const getInvestment = async (
  entity,
  uuid,
  email,
  orderBy,
  limit,
  page
) => {
  return api.get(`wallet/investment/`, {
    params: {
      entity,
      uuid,
      email,
      orderBy,
      limit,
      page,
    },
  });
};
