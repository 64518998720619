import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setTitle } from "../../store/actions/generalActions";
import { editCurrentLeague, getLeaguesAdmin } from "src/services/ClubService";
import { useDispatch, useSelector } from "react-redux";
import { setALlLeagues } from "src/store/actions/dataActions";
import "./tableLeagues.css";
import ModalError from "src/ui/modalError/modalError";
import lupa from "../../assets/images/lupa.png";

const fieldAndHeaders = {
  TÍTULO: "name",
  LOGO: "logo",
  STATUS: "status",
};

const Leagues = (props) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const [filterLeague, setFilterLeague] = useState("");
  const [showModalError, setShowModalError] = useState(false);

  useEffect(() => {
    props.setTitle("Ligas");
  }, []);

  const onSearchClub = () => {
    const result = data?.leaguesData?.filter((item) => {
      let nameLeague = item.name.toUpperCase();
      let filter = filterLeague.toUpperCase();
      return nameLeague === filter;
    });
    dispatch(setALlLeagues(result));
  };

  const filterByLeague = (e) => {
    e.preventDefault();
    setFilterLeague(e.target.value);
  };

  const getAllLeagues = async () => {
    const leagues = await getLeaguesAdmin();
    const storeData = leagues.data.items;
    dispatch(setALlLeagues(storeData));
  };

  const onRefresh = (e) => {
    e.preventDefault();
    getAllLeagues();
  };

  useEffect(() => {
    getAllLeagues();
  }, []);

  const onEditClub = async (e, item) => {
    e.preventDefault();
    const boolean = item.isActive ? false : true;
    const name = item.name;
    try {
      await editCurrentLeague({ isActive: boolean, name }, item.uuid);
      getAllLeagues();
    } catch (error) {
      setShowModalError(true);
    }
  };

  const dataToMap = data?.leaguesData?.sort((a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
  });

  return (
    <>
      <div className="content-leagues">
        <div className="content-leagues-create">
          <button
            className="btn-create-league"
            onClick={() => {
              props.history.push("/main/createLeague");
            }}
          >
            CREAR NUEVA LIGA
          </button>
        </div>
        <div className="btn-leagues-search">
          <input
            className="search-league"
            type="text"
            onChange={(e) => {
              filterByLeague(e);
            }}
            name="club"
            placeholder="Buscar liga"
          />
          <button
            className="btn-search-league"
            onClick={() => {
              onSearchClub();
            }}
          >
            <img src={lupa} />
          </button>
          <button
            onClick={(e) => {
              onRefresh(e);
            }}
            className="btn-clean-filters"
          >
            Limpiar filtros
          </button>
        </div>
      </div>
      <div className="content">
        <table className="table">
          <thead>
            <tr>
              {Object.keys(fieldAndHeaders).map((header, index) => {
                return <td key={"header" + index}>{header}</td>;
              })}
            </tr>
          </thead>

          <tbody>
            {dataToMap?.map((item) => (
              <tr key={item.id}>
                <td style={{ borderWidth: 0 }} className="td-leagues">
                  {item.name}
                </td>
                <td style={{ borderWidth: 0 }} className="td-leagues">
                  <img src={item.logo} className="img-leagues" />
                </td>
                <td style={{ borderWidth: 0 }} className="td-leagues">
                  <button
                    onClick={(e) => {
                      onEditClub(e, item);
                    }}
                    className="button-create"
                  >
                    {item.isActive ? "Habilitado" : "Deshabilitado"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showModalError && (
        <ModalError
          title="ERROR STATUS LIGA"
          text={"activar/descactivar liga"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default connect(null, {
  setTitle,
})(Leagues);
