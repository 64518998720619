import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { setTitle } from "../../store/actions/generalActions";
import { useHistory } from "react-router-dom";
import ModalError from "src/ui/modalError/modalError";
import Modal from "../../ui/modal/modal";
import ConfirmModal from "src/components/ConfirmModal/ConfirmModal";
import lupa from "../../assets/images/lupa.png";
import editar from "../../assets/images/editar.png";
import "./table.css";

import { getAllProjects, edit } from "src/services/ProjectService";
import { setProjects } from "src/store/actions/projectsActions";
import Button from "src/ui/button/button";
import SelectUI from "src/ui/selectUI";
import { getClubsAdmin } from "src/services/ClubService";
import Table from "src/components/table/Table";
import ReactPaginate from "react-paginate";
import {
  getAllExperiences,
  deleteExperience,
} from "src/services/ExperiencesService";
import { getAllRaffles, deleteRaffle } from "src/services/RaffleService";
import { formatDate, formatDateTime } from "src/utils/formatDate";
import WinnersForm from "../../components/WinnersForm/winnersForm";

const fieldAndHeaders = {
  CLUB: "club",
  TITULO: "title",
  //COSTO: "cost",
  FECHA: "raffleDate",
  PREMIOS: "count",
  "MAX PARTICIPANTES": "maxParticipant",
  PARTICIPANTES: "participants",
  FINALIZADO: "finalized",
};

const optionsActive = [
  { value: "true", label: "Activo" },
  { value: "false", label: "No Activo" },
];
const optionsFinalized = [
  { value: "true", label: "Finalizado" },
  { value: "false", label: "No Finalizado" },
];

const tableEnableButtons = {
  edit: true,
  del: true,
  action2: true,
};

const Projects = (props) => {
  const dispatch = useDispatch();
  const [projects, setProjects] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [clubFilter, setClubFilter] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const [finalizedFilter, setFinalizedFilter] = useState(null);
  const [clubs, setClubs] = useState(null);
  const [page, setPage] = useState(1);
  const [idToDelete, setIdToDelete] = useState("");
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [winnersModal, setWinnersModal] = useState(false);
  const [searchByTitle, setSearchByTitle] = useState("");
  const [raffleUuid, setRaffleUuid] = useState(null);

  const searchRef = useRef();
  const alert = useAlert();

  const getAllClubs = async () => {
    const response = await getClubsAdmin();
    const filtered = response.data.items
      .filter((item) => item.isActive)
      .map((item) => ({ label: item.name, value: item.uuid }));
    setClubs(filtered);
  };

  useEffect(() => {
    dispatch(setTitle("Sorteos"));
    getAllClubs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshList = async () => {
    try {
      const data = await getAllRaffles(
        clubFilter,
        20,
        page,
        searchByTitle,
        finalizedFilter
      );
      setProjects(data.data);
      setTableData(
        data?.data?.items?.map((item) => ({
          isActive: item.isActive ? "Si" : "No",
          finalized: item.finalized ? "Si" : "No",
          amount: item.amount,
          title: item.title,
          id: item.uuid,
          club: item.club.name,
          description: item.description,
          cost: item.cost,
          count: item.count,
          raffleDate: formatDateTime(item.raffleDate),
          image: item.image,
          participants: item.participants,
          maxParticipant: item.maxParticipant,
        }))
      );
    } catch (error) {}
  };

  useEffect(() => {
    refreshList();
  }, [page, clubFilter, activeFilter, finalizedFilter, searchByTitle]);

  const handleDelete = async () => {
    try {
      await deleteRaffle(idToDelete);
      alert.show("Sorteo borrado con éxito", { type: "success" });
      const result = tableData.filter((item) => item.id !== idToDelete);
      setTableData(result);
      setConfirmModalOpen(false);
    } catch (error) {
      setShowModalError(true);
    }
  };

  const onFinalizedChange = (e) => {
    setFinalizedFilter(e.target.value);
  };

  const onActiveChange = (e) => {
    setActiveFilter(e.target.value);
  };

  const onClubChange = (e) => {
    setClubFilter(e.target.value);
  };

  const handlePage = (args) => {
    setPage(args.selected + 1);
  };

  const handleActionClick = (id, option, item) => {
    if (option === "edit") {
      const raffle = projects.items.find((it) => it.uuid === item.id);
      props.history.push({
        pathname: "/main/raffles/edit",
        state: { raffle },
      });
    }

    if (option == "del") {
      setIdToDelete(id);
      setConfirmModalOpen(true);
    }

    if (option === "action2") {
      setRaffleUuid(id);
      setWinnersModal(true);
    }
  };

  const onCloseModal = () => {
    setWinnersModal(false);
    refreshList();
  };

  const disableWinners = (raffle) => {
    return raffle.finalized === "No";
  };

  return (
    <>
      <div className="container-create-users">
        <div className="users-filters-buttons">
          <Button
            event="CREAR SORTEO"
            action={() => props.history.push("/main/raffles/create")}
            secondary
          />
        </div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setSearchByTitle(searchRef.current.value);
          }}
        >
          <div className="user-filters-item">
            <label>Filtrar por titulo</label>
            <div className="filter-input-button">
              <input
                id="user-filter-input"
                placeholder="filtro por titulo"
                className="user-filter-input"
                ref={searchRef}
              />
              <div className="buttons-filter" style={{ maxHeight: "36px" }}>
                <Button event="buscar" secondary value="search" />
                <button
                  className="button"
                  onClick={() => {
                    document.getElementById("user-filter-input").value = "";
                  }}
                  style={{ marginLeft: "10px" }}
                >
                  limpiar
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className="users-filters">
          <div className="user-filters-item">
            <label>Filtrar Club</label>
            <SelectUI
              options={clubs}
              isLoading={!clubs}
              onChange={onClubChange}
            />
          </div>
          {/* <div className="user-filters-item">
            <label>Filtrar Activo</label>
            <SelectUI options={optionsActive} onChange={onActiveChange} />
          </div> */}
          <div className="user-filters-item">
            <label>Filtrar Finalizado</label>
            <SelectUI options={optionsFinalized} onChange={onFinalizedChange} />
          </div>
        </div>
      </div>
      <div className="content">
        <Table
          fieldAndHeaders={fieldAndHeaders}
          setPage={() => {}}
          totalItems={projects?.meta?.totalItems}
          items={tableData}
          pages={projects?.meta?.totalPage}
          currentPage={projects?.meta?.currentPage}
          currentItems={projects?.meta?.itemsPerPage}
          triggerRow={handleActionClick}
          enableButtons={tableEnableButtons}
          disabledAction2={disableWinners}
        />
        <div className="footer">
          <p className="total">
            Total de sorteos: {projects?.meta?.totalItems}
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={projects?.meta?.totalPage}
            previousLabel="<"
            renderOnZeroPageCount={null}
            activeLinkClassName="active"
          />
        </div>
      </div>
      {confirmModalOpen && (
        <Modal customOnClose={setConfirmModalOpen} key="modal">
          <ConfirmModal
            content={"Estas seguro que deseas borrar el sorteo?"}
            title={"Borrar Sorteo"}
            mainButtonText={"Borrar"}
            onCancel={() => setConfirmModalOpen(false)}
            onAccept={handleDelete}
          />
        </Modal>
      )}
      {showModalError && (
        <ModalError
          title="ERROR EDITANDO SORTEO"
          text={"Editar el estado de la sorteo"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
      {winnersModal && (
        <Modal customOnClose={() => onCloseModal()} key="modal">
          <WinnersForm
            raffleUuid={raffleUuid}
            // setOpenModal={setUserModal}
            // onSubmitForm={refreshList}
          />
        </Modal>
      )}
    </>
  );
};

export default Projects;
