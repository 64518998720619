import { useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  createNewOption,
  updateOption,
  optionVote,
} from "src/services/OptionsService";
import ModalError from "src/ui/modalError/modalError";
import dragImage from "../../assets/images/dragImage.png";
import { setTitle } from "../../store/actions/generalActions";
import "./option.css";
import useUnsavedChangesWarning from "../../hooks/useUnsavedChangesWarning";

const Option = (props) => {
  const option = props?.location?.state?.option;
  const decision = props?.location?.state?.decision;
  const fromEdit = !!option;

  const [title, setTitle] = useState(fromEdit ? option.title : "");
  const [description, setDescription] = useState(
    fromEdit ? option.description : ""
  );
  const [selectedFile, setSelectedFile] = useState(
    fromEdit ? { imageURL: option.image } : null
  );
  const [selectedFileBanner, setSelectedFileBanner] = useState(
    fromEdit ? { imageURL: option.imageBanner } : null
  );
  const [votes, setVotes] = useState(0);
  const [votesAct, setVotesAct] = useState(fromEdit ? option.votes : 0);

  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const fileInputRef = useRef();
  const fileInputReftwo = useRef();
  //const { uuidDecision } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const alert = useAlert();
  const history = useHistory();
  const [Prompt, setDirty, setPristine] = useUnsavedChangesWarning();

  useEffect(() => {
    setSubmitted(false);
    console.log("option:", option);
    props.setTitle(`${fromEdit ? "Editar" : "Agregar"} opción`);
  }, []);

  const validateRequired = () => {
    for (let k = 0; k < document.querySelectorAll("[Required]").length; k++) {
      if (!document.querySelectorAll("[Required]")[k].value) return false;
    }

    if (!selectedFile) return false;
    if (!selectedFileBanner) return false;

    return true;
  };

  const onChangeTitle = (e) => {
    setDirty();
    setTitle(e.target.value);
  };

  const onChangeDesc = (e) => {
    setDirty();
    setDescription(e.target.value);
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const fileInputBannerClicked = () => {
    fileInputReftwo.current.click();
  };

  const filesSelected = () => {
    const files = fileInputRef.current.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFile(image);
    }
  };

  const filesSelectedBanner = () => {
    const files = fileInputReftwo.current.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFileBanner(image);
    }
  };

  const onChangeVotes = (e) => {
    setDirty();
    if (e.target.value > 99999) return false;
    setVotes(e.target.value);
  };

  const editOpcion = async () => {
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("decision", decision.uuid);
      formData.append("description", description);
      formData.append("file", selectedFile?.file);
      formData.append("fileBanner", selectedFileBanner?.file);
      formData.append("votes", votes ? votes : 0);

      await updateOption(formData, option.uuid);

      if (votes > 0) {
        const formDataOptions = {
          decisionUuid: decision.uuid,
          optionUuid: option.uuid,
          count: +votes,
        };
        await optionVote(formDataOptions);
      }

      alert.show("Opción modificada con éxito", { type: "success" });
      setPristine();
      props.history.push({
        pathname: "/main/optionList",
        state: { decision },
      });
    } catch (error) {
      alert.show("Error al editar opción", { type: "error" });
    } finally {
      setDisabledSaveButton(false);
    }
  };
  const uploadOpcion = async () => {
    try {
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("decision", decision.uuid);
      selectedFile?.file && formData.append("file", selectedFile.file);
      selectedFileBanner.file &&
        formData.append("fileBanner", selectedFileBanner.file);
      //formData.append("votes",  votes);

      await createNewOption(formData);

      alert.show("Opción creada con exito", { type: "success" });

      setPristine();
      props.history.push({
        pathname: "/main/optionList",
        state: { decision },
      });
    } catch (error) {
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const uploadOpcionAndCreateNew = async () => {
    try {
      var formData = new FormData();
      formData.append("title", title);
      formData.append("description", description);
      formData.append("decision", decision.uuid);
      selectedFile?.file && formData.append("file", selectedFile.file);
      selectedFileBanner.file &&
        formData.append("fileBanner", selectedFileBanner.file);
      //formData.append("votes",  votes);

      await createNewOption(formData);

      alert.show("Opción creada con exito", { type: "success" });
      setPristine();
      // props.history.push({
      //   pathname: "/main/option",
      //   state: {
      //     decisionUuid: { uuid: props?.location?.state?.decisionUuid },
      //   },
      // });
      setDescription("");
      setTitle("");
      setSelectedFileBanner(null);
      setSelectedFile(null);
      setSubmitted(false);
    } catch (error) {
      setShowModalError(true);
    } finally {
      setDisabledSaveButton(false);
    }
  };

  const onSaveHandler = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos sin dato");
      setShowModalError(true);
      return;
    }

    if (fromEdit) editOpcion();
    else uploadOpcion();
  };

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const fileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    if (files) {
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFile(image);
    }
  };

  const fileDropForBanner = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    if (files) {
      setDirty();
      const imageURL = URL.createObjectURL(files);
      const image = { file: files, imageURL };
      setSelectedFileBanner(image);
    }
  };

  const onSaveAndCreateNewHandler = (e) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    setSubmitted(true);
    if (!validateRequired()) {
      setError("Existen campos requeridos sin dato");
      setShowModalError(true);
      return;
    }
    uploadOpcionAndCreateNew();
  };

  return (
    <>
      <form>
        <div className="container-crate-decisión">
          <div className="div-title">
            <div className="container-input-decision">
              <input
                onChange={onChangeTitle}
                name="title"
                type="text"
                className="input-title"
                placeholder="Agregá el título de la opción"
                value={title}
                maxLength={255}
              />
            </div>
            <div>
              {submitted && !title && (
                <small className="p-invalid">El título es obligatorio.</small>
              )}
            </div>
          </div>
          <div>
            <div className="container-text-area-decision">
              <textarea
                placeholder="Agregá una descripción"
                className="text-desc"
                type="textarea"
                line="5"
                onChange={onChangeDesc}
                value={description}
                maxLength={255}
              />
            </div>
          </div>
          <div className="img-input">
            <p style={{ color: "white" }}>imagen</p>
            <div
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDrop}
              className={!selectedFile ? "drop-container" : ""}
              onClick={fileInputClicked}
            >
              {!selectedFile ? (
                <div className="container-selected-decision-img">
                  <img height={65} width={65} src={dragImage} />
                  <p style={{ color: "#626262" }}>
                    Arrastrá o agregá una imagen
                  </p>
                </div>
              ) : (
                <img src={selectedFile.imageURL} className="image" />
              )}
            </div>
            <p style={{ color: "#626262" }}>
              Las imágenes no deben pesar mas de 5MB y medir 135x145
            </p>
          </div>
          <div>
            {submitted && !selectedFile && (
              <small className="p-invalid">La imágen es obligatoria.</small>
            )}
          </div>
          <div className="img-input">
            <p style={{ color: "white" }}>imagen banner</p>
            <div
              onDragOver={dragOver}
              onDragEnter={dragEnter}
              onDragLeave={dragLeave}
              onDrop={fileDropForBanner}
              className={!selectedFileBanner ? "drop-container" : ""}
              onClick={fileInputBannerClicked}
            >
              {!selectedFileBanner ? (
                <div className="container-selected-decision-img">
                  <img height={65} width={65} src={dragImage} />
                  <p style={{ color: "#626262" }}>
                    Arrastrá o agregá una imagen
                  </p>
                </div>
              ) : (
                <img src={selectedFileBanner.imageURL} className="image" />
              )}
            </div>
            <p style={{ color: "#626262" }}>
              EL banner no debe pesar mas de 5MB y medir 299x148
            </p>
          </div>
          <div>
            {submitted && !selectedFileBanner && (
              <small className="p-invalid">
                La imágen banner es obligatoria.
              </small>
            )}
          </div>
          <input
            ref={fileInputReftwo}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelectedBanner}
          />

          <input
            ref={fileInputRef}
            className="file-input"
            type="file"
            multiple
            onChange={filesSelected}
          />

          {fromEdit && (
            <div className="img-input">
              <div className="container-input-decision">
                <input
                  onChange={onChangeVotes}
                  name="votes"
                  type="number"
                  className="input-title"
                  placeholder="votes"
                  value={votes}
                />
              </div>
              <p style={{ color: "#626262" }}>
                Ingrese votos para agregar a esta opción. Votos actuales{" "}
                {votesAct}.
              </p>
            </div>
          )}
          <div className="container-buttons">
            <button
              className="save-option-btn"
              disabled={disabledSaveButton}
              onClick={(e) => onSaveHandler(e)}
              style={{ width: "50%" }}
            >
              GUARDAR OPCION
            </button>
            {!fromEdit && (
              <button
                className="save-option-btn"
                disabled={disabledSaveButton}
                onClick={(e) => onSaveAndCreateNewHandler(e)}
                style={{ width: "50%" }}
              >
                GUARDAR OPCION Y CREAR NUEVA
              </button>
            )}
          </div>
        </div>
      </form>
      {showModalError && (
        <ModalError
          title="ERROR CREANDO OPCION"
          text={"crear desición: " + error}
          closeModal={(e) => {
            setShowModalError(e);
            setDisabledSaveButton(false);
          }}
          key="modal"
        />
      )}
      {Prompt}
    </>
  );
};

export default connect(null, {
  setTitle,
})(Option);
