import { useEffect, useState } from "react";
import { setTitle } from "../../store/actions/generalActions";
import ModalError from "src/ui/modalError/modalError";
import { connect, useDispatch, useSelector } from "react-redux";
import "./createOptions.css";
import { filterOption, cleanOptions } from "src/store/actions/dataActions";
import { createNewOption } from "src/services/OptionsService";
import { useHistory } from "react-router-dom";
import Button from "src/ui/button/button";

const CreateOptions = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModalError, setShowModalError] = useState(false);
  const { optionList } = useSelector((state) => state.data);

  useEffect(() => {
    props.setTitle("Opciones");
  }, []);

  const onDeleteImage = (index, e) => {
    e.preventDefault();
    const result = optionList.filter((_, indexItem) => indexItem !== index);
    dispatch(filterOption(result));
  };

  const uploadOptions = async (e) => {
    e.preventDefault();
    try {
      const promises = optionList.map(async (item) => {
        var formData = new FormData();
        formData.append("title", item.title);
        formData.append("decision", item.decision);
        formData.append("description", item.description);
        formData.append("file", item.file);
        formData.append("fileBanner", item.selectedFileBanner);
        return await createNewOption(formData);
      });
      await Promise.all(promises);
      history.push("/main/decisions");
      dispatch(cleanOptions());
    } catch (error) {
      setShowModalError(true);
    }
  };

  console.log(optionList)

  return (
    <>
      <Button
        action={() => {
          props.history.push("/main/option");
        }}
        className="btn-modify-avatar"
        event="Cargar nueva opción"
       />
      <form>
        <fieldset className="fieldset-avatar">
          <div className="container-avatar">
            <div className="container-files">
              {optionList ? (
                optionList.map((item, index) => (
                  <div className="options-card" style={{ marginRight: 10 }} key={index}>
                    <button
                      className="delete-avatar"
                      onClick={(e) => {
                        onDeleteImage(index, e);
                      }}
                    >
                      X
                    </button>
                    <img
                      src={URL.createObjectURL(item.file)}
                      height={200}
                      width={200}
                    />
                    <div>
                    <p style={{ color: "white" }}>Título: {item.title}</p>
                    <p style={{ color: "white" }}>Descripción: {item.title}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ color: "white" }}>no hay opciones cargadas</p>
              )}
            </div>
            <div className="container-option-btn">
              <button
                className="button-upload-option"
                disabled={!optionList.length}
                onClick={(e) => {
                  uploadOptions(e);
                }}
              >
                SUBIR OPCIONES
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      {showModalError && (
        <ModalError
          title="ERROR AGREGANDO OPCION"
          text={"agregar opcion/s"}
          closeModal={setShowModalError}
          key="modal"
        />
      )}
    </>
  );
};

export default connect(null, {
  setTitle,
})(CreateOptions);
